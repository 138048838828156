import styled from 'styled-components';
import Heading from 'components/atoms/Heading/Heading';
import Obraz from 'components/atoms/Obraz/Obraz';
import Row from 'react-bootstrap/Row';
import { Form, Field } from 'formik';
import EdytujFirme from 'components/organisms/EdytujFirme/EdytujFirme';

export const Flex = styled(Row)`
  display: flex;
  justify-content: center;
`;

export const Section = styled.section`
  margin-top: 5rem;
  margin-bottom: 1rem;
`;

export const H2 = styled(Heading)`
  text-align: center;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fSize.h2};
  margin-bottom: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
`;

export const Summary = styled.div`
  border-width: 4px;
  border-radius: 6px;
  border-style: solid;
  width: 100%;
  padding: 2.6rem 3.2rem 3rem;
  margin-bottom: 3rem;
  background-color: ${({ theme }) => theme.white};
  &.inactive {
    border-color: #707070;
    .rowRazem {
      &:before {
        background: #707070;
      }
    }
  }
`;

export const StyledEdytujFirme = styled(EdytujFirme)`
  margin-bottom: 6rem;
`;

export const GruszkaPierwsza = styled(Obraz)`
  position: absolute;
  left: -10%;
  bottom: 0;
  width: 40vw;
  z-index: -1;
`;

export const GruszkaDruga = styled(Obraz)`
  position: absolute;
  right: 0;
  bottom: -15%;
  width: 30vw;
  z-index: -1;
`;

export const Formularz = styled(Form)`
  display: flex;
  flex-direction: column;
`;
export const KontoWrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 10rem;
`;

export const Input = styled(Field)`
  border-radius: 6px;
  border: 1px solid black;
  padding: 1rem 2rem;
  ${({ type }) => (type === 'checkbox' ? 'position: absolute; left: 0; top: .3em;' : '')};
  &:focus {
    outline: none;
    box-shadow: 0 2px 6px -2px black;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.black};
  }
  &::-moz-placeholder {
    color: ${({ theme }) => theme.black};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.black};
  }
  &:-moz-placeholder {
    color: ${({ theme }) => theme.black};
  }
`;

export const Alert = styled.div`
  color: red;
  height: 1em;
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
`;

export const Label = styled.label`
  margin-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  padding-left: ${({ row }) => (row ? '2rem' : '0')};
  span {
    padding-top: 0;
    padding-left: 2rem;
  }
`;
