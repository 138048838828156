import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import RowTitleBox from './RowTitleBox';
import SumaBox from './SumaBox';

const ZamowieniaSuma = ({ title, podsumowanieDania, dania }) => {
  return (
    <Row>
      <RowTitleBox title={title} />
      {podsumowanieDania.map((item, index) => (
        <SumaBox
          key={item.id}
          {...item}
          dostepnaIlosc={dania[index] ? dania[index].dostepnaIlosc : 0}
        />
      ))}
    </Row>
  );
};

ZamowieniaSuma.propTypes = {
  title: PropTypes.string,
  podsumowanieDania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      dostepnaIlosc: PropTypes.number,
    }),
  ),
};

ZamowieniaSuma.defaultProps = {
  title: 'Suma',
  podsumowanieDania: [],
  dania: [],
};

export default ZamowieniaSuma;
