import React, { createContext, useState } from 'react';

export const DriverContext = createContext({});

// eslint-disable-next-line react/prop-types
const DriverContextProvider = ({ children }) => {
  const [whichPage, setWhichPage] = useState(1);
  return (
    <DriverContext.Provider value={{ whichPage, setWhichPage }}>{children}</DriverContext.Provider>
  );
};

export default DriverContextProvider;
