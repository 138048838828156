import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import Obraz from 'components/atoms/Obraz/Obraz';
import card from 'assets/svg/karta.svg';
import ZamowienieDetails from 'components/molecules/ZamowienieDetails/ZamowienieDetails';

const Wrrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & + & {
    margin-top: 2rem;
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Summary = styled(Button)`
  background-color: transparent;
  padding: 1rem 2rem;
  flex-grow: 1;
  border-width: 2px;
  background: rgba(255, 255, 255, 0.85);
  @media (max-width: 991px) {
    font-size: 1.5rem;
    padding: 1rem;
    order: 1;
  }
`;

const Aside = styled.div`
  width: ${({ method }) => (method ? 'auto' : '15%')};
  min-width: ${({ method }) => (method ? '0' : '15rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 1.8rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
    width: ${({ method }) => (method ? 'auto' : '50%')};
    order: ${({ method }) => (method ? 1 : 0)};
    justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
    padding: 0.5rem;
  }
`;

const ZamowienieLista = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const ListaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

// jeszcze dojdą dania jako tablice
const HistoriaItem = ({
  id,
  dataZlozenia,
  formaPlatnosci,
  statusPlatnosci,
  wartosc,
  dataRealizacji,
  dania,
}) => (
  <Wrrapper>
    <Aside>{dataZlozenia}</Aside>
    <Summary status={statusPlatnosci} dataRealizacji={dataRealizacji}>
      <ListaWrapper>
        <ZamowienieLista>
          {dania.map(item => (
            <ZamowienieDetails
              id={id}
              key={item.id}
              cena={item.cena}
              ilosc={item.ilosc}
              nazwa={item.nazwa ? item.nazwa : 'brak nazwy'}
            />
          ))}
        </ZamowienieLista>
        <Aside method={1}>
          {/* tymczasowo */}
          <Obraz src={card} alt={formaPlatnosci} width={24} />
        </Aside>
      </ListaWrapper>
    </Summary>
    <Aside right={1} suma>
      {wartosc} zł
    </Aside>
  </Wrrapper>
);

HistoriaItem.propTypes = {
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nazwa: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  dataZlozenia: PropTypes.string.isRequired,
  formaPlatnosci: PropTypes.string.isRequired,
  statusPlatnosci: PropTypes.string.isRequired,
  wartosc: PropTypes.number.isRequired,
  dataRealizacji: PropTypes.string.isRequired,
};

export default HistoriaItem;
