import axios from 'axios';
import {
  fetchArchiwumEtykietError,
  fetchArchiwumEtykietPending,
  fetchArchiwumEtykietSuccess,
} from 'actions/archiwumActions';

export const pobierzArchiwumEtykietAction = () => dispatch => {
  dispatch(fetchArchiwumEtykietPending());
  return axios
    .get('/api/kuchnia/archiwum/etykiety', {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(fetchArchiwumEtykietSuccess(res.data));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchArchiwumEtykietError(error));
    });
};
