import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  align-items: ${({ centerH }) => (centerH ? 'center' : 'flex-start')};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1200px;
    left: -1200px;
    z-index: -1;
    background: transparent;
    transition: all 0.3s ease;
    border: 1px solid transparent;
  }
  &:hover {
    &:before {
      background: rgba(0, 0, 0, 0.1);
      border-color: #0b0b0b;
    }
  }
`;

const UzytkownikAdmin = ({ index, imie, nazwisko, telefon, email, login, status }) => (
  <>
    <Cell center>{index + 1}.</Cell>
    <Cell>{imie}</Cell>
    <Cell>{nazwisko}</Cell>
    <Cell>{telefon || 'brak'}</Cell>
    <Cell>{email}</Cell>
    <Cell>{login}</Cell>
    <Cell>{status ? 'aktywny' : 'nieaktywny'}</Cell>
  </>
);

UzytkownikAdmin.propTypes = {
  index: PropTypes.number,
  imie: PropTypes.string,
  nazwisko: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  telefon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  email: PropTypes.string,
  login: PropTypes.string,
  status: PropTypes.number,
};

UzytkownikAdmin.defaultProps = {
  imie: '',
  nazwisko: '',
  telefon: '',
  email: '',
  login: '',
  index: 0,
  status: 0,
};

export default UzytkownikAdmin;
