import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  border: 1px solid #aebdc2;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.6);
  line-height: 1em;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & + div {
      border-left: 1px solid #aebdc2;
    }
  }
`;

const StatsBox = ({ historia, id }) => (
  <Col data-id={id}>
    <Wrapper>
      <div>{historia.last}</div>
      <div>{historia.avg}</div>
    </Wrapper>
  </Col>
);

StatsBox.propTypes = {
  historia: PropTypes.shape({
    last: PropTypes.number,
    avg: PropTypes.number,
  }),
  id: PropTypes.number.isRequired,
};

// Do wywalenia po ogarnięciu stanu
StatsBox.defaultProps = {
  historia: {
    last: 0,
    avg: 0,
  },
};

export default StatsBox;
