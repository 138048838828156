import axios from 'axios';
import { fetchDaniaPending, fetchDaniaDoMenuSuccess, fetchDaniaError } from 'actions/daniaActions';

export const fetchDania = id => dispatch => {
  dispatch(fetchDaniaPending());

  if (id) {
    return axios
      .get(`/api/dania/menu-dnia/${id}`)
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchDaniaDoMenuSuccess(res.data.data));
        return res.data.data;
      })
      .catch(error => {
        dispatch(fetchDaniaError(error));
      });
  }
  return axios
    .get('/api/dania/menu-dnia')
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(fetchDaniaDoMenuSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(fetchDaniaError(error));
    });
};

export default fetchDania;
