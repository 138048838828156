import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GlobalStyle from 'theme/GlobalStyle';
import PageContext from 'context';

import { connect } from 'react-redux';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

class MainTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { pageType: 'notes' };
  }

  render() {
    const { children } = this.props;
    const { pageType } = this.state;

    return (
      <div>
        <PageContext.Provider value={pageType}>
          <GlobalStyle />
          <>
            <ReactNotification />
            {children}
          </>
        </PageContext.Provider>
      </div>
    );
  }
}

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

function mapStateToProps(/* state */) {
  return {};
}

export default connect(mapStateToProps)(MainTemplate);

/* export default MainTemplate; */
