import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from 'theme/kierowcaTheme';
import Header from 'components/molecules/HeaderKierowca/Header';
import Footer from 'components/molecules/FooterKierowca/Footer';
import TymczasowyHeader from 'components/molecules/TymczasowyHeader/TymczasowyHeader';

const Body = styled.div`
  margin-top: 6rem;
  margin-bottom: 8rem;
`;

const Main = styled.div`
  min-height: calc(100vh - 13rem);
`;

const KierowcaTemplate = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <TymczasowyHeader />
      <Main>
        {/* widok do ustawienia jako zmienna zależna od wisoku */}
        <Header />
        <Body>{children}</Body>
        <Footer />
      </Main>
    </ThemeProvider>
  );
};

KierowcaTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default KierowcaTemplate;
