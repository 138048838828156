import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
// import dania from 'datasource/obiady';
import styled, { css } from 'styled-components';
import RowTitleBox from './RowTitleBox';
import IloscBox from './IloscBox';

const Wrapper = styled(Row)`
  ${({ first }) =>
    first &&
    css`
      position: relative;
      height: 7rem;
    `};
  ${({ active }) =>
    !active
      ? css`
          height: 100%;
          opacity: 0.5;
        `
      : css`
          height: 50%;
          opacity: 1;
        `};
  margin-bottom: 0.6rem;
  cursor: pointer;
`;

class ZamowieniaRow extends React.Component {
  state = {};

  handleClick() {
    const { rowKey, setActiveRow } = this.props;
    setActiveRow(rowKey);
  }

  render() {
    const { active, title, first, dania, zamowione, menuId } = this.props;

    return (
      <Wrapper first={first} active={active ? 1 : 0}>
        <RowTitleBox
          title={title}
          handleClick={() => {
            this.handleClick();
          }}
          active={active ? 1 : 0}
        />
        {dania.map(item => (
          <IloscBox
            key={item.danieId}
            id={item.danieId}
            godzinaWyjazdu={title}
            zatwierdzone={zamowione[String(item.id)].zatwierdzone}
            etykiety={zamowione[String(item.id)].etykiety}
            doAkceptacji={zamowione[String(item.id)].doAkceptacji}
            menuId={menuId}
          />
        ))}
      </Wrapper>
    );
  }
}

ZamowieniaRow.propTypes = {
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  rowKey: PropTypes.string,
  setActiveRow: PropTypes.func,
  zamowione: PropTypes.shape({}),
  title: PropTypes.string,
  first: PropTypes.string,
  active: PropTypes.bool.isRequired,
  menuId: PropTypes.number.isRequired,
};

ZamowieniaRow.defaultProps = {
  title: '',
  first: 'wrapper',
  zamowione: {},
  dania: [],
  rowKey: '',
  setActiveRow: null,
};

export default ZamowieniaRow;
