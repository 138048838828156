import React from 'react';
import { NavLink } from 'react-router-dom';
import zamowienia from 'assets/svg/zamowienia.svg';
import historia from 'assets/svg/historia.svg';
import konto from 'assets/svg/konto.svg';
import styled from 'styled-components';

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  li + li {
    margin-left: 1.2rem;
  }
`;

const NavLinkStyled = styled(NavLink)`
  font-size: 1.6rem;
  line-height: 1.2em;
  color: #0b0b0b;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 50%;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #23bf39;
  }
  &.${props => props.activeClassName} {
    background: #23bf39;
  }
`;

const NawigacjaKlient = () => (
  <nav>
    <Ul>
      <li>
        <NavLinkStyled exact to="/zamow-posilek">
          <img src={zamowienia} alt="Zamów posiłek" title="Zamów posiłek" />
        </NavLinkStyled>
      </li>
      <li>
        <NavLinkStyled to="/moje-konto">
          <img src={konto} alt="Zamów posiłek" title="Moje konto" />
        </NavLinkStyled>
      </li>
      <li>
        <NavLinkStyled to="/historia-zamowien">
          <img src={historia} alt="Zamów posiłek" title="Historia zamówień" />
        </NavLinkStyled>
      </li>
    </Ul>
  </nav>
);

NavLinkStyled.defaultProps = {
  activeClassName: 'active',
};

export default NawigacjaKlient;
