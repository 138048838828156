import {
  PRZYPISZ_ZAMOWIENIE_PENDING,
  PRZYPISZ_ZAMOWIENIE_ERROR,
  PRZYPISZ_ZAMOWIENIE_SUCCESS,
  POBIERZ_ZAMOWIENIA_KIEROWCA_SUCCESS,
} from 'actions/zamowieniaActions';

const initialState = {
  pending: false,
  error: null,
  zamowienia: [],
  kierowcy: [],
};

const adminKierowcaReducer = (state = initialState, action) => {
  // console.log(state);
  // let zamowienia = null;
  switch (action.type) {
    case PRZYPISZ_ZAMOWIENIE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PRZYPISZ_ZAMOWIENIE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PRZYPISZ_ZAMOWIENIE_SUCCESS:
      return {
        ...state,
        pending: false,
        zamowienia: action.zamowienia,
      };
    case POBIERZ_ZAMOWIENIA_KIEROWCA_SUCCESS:
      return {
        ...state,
        pending: false,
        kierowcy: action.kierowcy,
      };

    default:
      return state;
  }
};

export default adminKierowcaReducer;
