import { store } from 'react-notifications-component';

export function addAlert(params) {
  // types: 'danger', 'success', 'info', 'warning', 'default'
  const {
    title = '',
    message = 'alert nie został poprawnie zdefiniowany',
    type = 'warning',
    insert = 'top',
    container = 'top-right',
    duration = 5000,
  } = params;
  store.addNotification({
    title,
    message,
    type,
    insert,
    container,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration,
      onScreen: true,
      pauseOnHover: true,
    },
  });
}
