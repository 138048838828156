import React from 'react';
// import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from 'components/atoms/Logo/Logo';
import NawigacjaAdmin from 'components/molecules/NawigacjaAdmin/NawigacjaAdmin';
import styled from 'styled-components';
import Logout from 'components/molecules/Logout/Logout';

const LogoAdmin = styled(Logo)`
  width: 5.4rem;
  height: 5.75rem;
`;

const StyledHeader = styled.header`
  height: 8rem;
  padding: 0 3rem;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 10;
`;

const StyledRow = styled(Row)`
  align-items: center;
  display: flex;
`;

const Header = () => (
  <StyledHeader>
    <Container fluid>
      <StyledRow>
        <Col sm={2}>
          <LogoAdmin
            path="/" // window.location.pathname
          />
        </Col>
        <Col sm={9}>
          <NawigacjaAdmin />
        </Col>
        <Col sm={1}>
          <Logout />
        </Col>
      </StyledRow>
    </Container>
  </StyledHeader>
);

export default Header;
