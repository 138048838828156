import React from 'react';
import styled from 'styled-components';
import Logout from 'components/molecules/Logout/Logout';

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 6rem;
  z-index: 5;
  background-color: #fff;
`;

const GoBack = styled.div`
  padding: 0 3rem;
`;

const Arrow = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
`;

const LogoutWrapper = styled.div`
  margin-right: 3rem;
`;

class HeaderKierowca extends React.Component {
  goBack = () => {
    window.history.back();
  };

  render() {
    return (
      <Header>
        <GoBack onClick={this.goBack}>
          <Arrow />
        </GoBack>

        <LogoutWrapper>
          <Logout />
        </LogoutWrapper>
      </Header>
    );
  }
}

export default HeaderKierowca;
