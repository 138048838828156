import { combineReducers } from 'redux';

import zamowieniaReducer from 'reducers/zamowieniaReducer';
import daniaReducer from 'reducers/daniaReducer';
import userReducer from 'reducers/userReducer';
import filterReducer from 'reducers/filterReducer';
import kuchniaReducer from 'reducers/kuchniaReducer';
import adminKierowcaReducer from 'reducers/adminKierowcaReducer';
import archiwumReducer from 'reducers/archiwumReducer';
import kierowcaReducer from 'reducers/kierowcaReducer';
import usersReducer from 'reducers/usersReducer';

const rootReducer = combineReducers({
  daniaReducer,
  zamowieniaReducer,
  userReducer,
  filterReducer,
  kuchniaReducer,
  adminKierowcaReducer,
  archiwumReducer,
  kierowcaReducer,
  usersReducer,
});

export default rootReducer;
