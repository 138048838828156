/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { DropTarget } from 'react-dnd';
import './DriverPanel.scss';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import zupa from 'assets/svg/zupa.svg';
import salatka from 'assets/svg/salatka.svg';
import danie from 'assets/svg/daniednia.svg';
import NapojeZimne from 'assets/svg/napoje_zimne.svg';
import Desser from 'assets/svg/Desser2.svg';

// import zamowieniaReducer from 'reducers/zamowieniaReducer';
// eslint-disable-next-line no-unused-vars
import { przypiszZamowienie } from 'services/admin/kierowcyService';

export const ItemTypes = {
  BOX: 'box',
};

const style = {
  height: 'auto',
  minHeight: '22rem',
  width: '100%',
  // marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'black',
  padding: '1rem',
  // textAlign: 'center',
  fontSize: '1.5rem',
  lineHeight: 'normal',
  float: 'left',
  borderRadius: '10px',
  border: '2px solid black',
};

const SortableItem = sortableElement(({ children, className }) => (
  <div className={className}>{children}</div>
));

const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);

// eslint-disable-next-line no-unused-vars
const Dustbin = ({
  canDrop,
  isOver,
  // allowedDropEffect,
  connectDropTarget,
  usunZamClick,
  zapiszKolejnosc,
  driver,
  zamowienia,
  iloscDan,

  // wyjazd,
}) => {
  // const [danenDrag, setDaneDrag] = useState([]);

  // useEffect(() => {
  //   przypiszZamowienie().then(pobraneFirmy => {
  //     setDaneDrag(pobraneFirmy);
  //   });
  // }, []);

  const c = 0;
  const isActive = canDrop && isOver;
  let backgroundColor = 'transparent';
  if (isActive) {
    backgroundColor = 'rgba(245, 232, 223, 0.3)';
  } else if (canDrop) {
    backgroundColor = 'transparent';
  }

  return connectDropTarget(
    <div style={{ ...style, backgroundColor }}>
      {isActive}
      <SortableContainer
        onSortEnd={({ oldIndex, newIndex }) => {
          const NewZamowienia = arrayMove(zamowienia, oldIndex, newIndex);
          zapiszKolejnosc(NewZamowienia);
        }}
      >
        {zamowienia.map((z, index) => (
          <SortableItem
            key={`${z.firmaId}_${z.oddzialId}`}
            className="zamowienie-dust"
            index={z.kolejnosc ? z.kolejnosc : index}
          >
            <p className="nazwa">
              {z.nazwa} {z.oddzial && <>({z.oddzial})</>}
            </p>
            <div className="wartosc-dust">
              {/* <p>{z.wartosc} zł</p> */}
              <p>{z.ilosc} zam.</p>
              <div
                className="close-container"
                onClick={() => {
                  usunZamClick({
                    firmaId: z.firmaId,
                    godzinaWyjazduId: z.godzinaWyjazduId,
                    kierowcaId: z.kierowcaId,
                  });
                }}
                aria-hidden="true"
              >
                <div className="leftright" />
                <div className="rightleft" />
              </div>
            </div>
            <div className="ikonki">
              <div
                style={{
                  display: z.dania
                    .map(ele => ele.kategoria === 'zupa' && ele.ilosc)
                    .every(el => el === false)
                    ? 'none'
                    : 'flex',
                }}
              >
                <p>{z.dania.map(ele => ele.kategoria === 'zupa' && ele.ilosc)}</p>{' '}
                <img className="iko" src={zupa} alt="zupa" />{' '}
              </div>
              <div
                style={{
                  display: z.dania
                    .map(ele => ele.kategoria === 'danie' && ele.ilosc)
                    .every(el => el === false)
                    ? 'none'
                    : 'flex',
                }}
              >
                <p>{z.dania.map(ele => ele.kategoria === 'danie' && ele.ilosc)}</p>{' '}
                <img className="iko" src={danie} alt="danie" />
              </div>
              <div
                style={{
                  display: z.dania
                    .map(ele => ele.kategoria === 'salatka' && ele.ilosc)
                    .every(el => el === false)
                    ? 'none'
                    : 'flex',
                }}
              >
                <p>{z.dania.map(ele => ele.kategoria === 'salatka' && ele.ilosc)}</p>{' '}
                <img className="iko" src={salatka} alt="salatka" />{' '}
              </div>

              <div
                style={{
                  display: z.dania
                    .map(ele => ele.kategoria === 'deser' && ele.ilosc)
                    .every(el => el === false)
                    ? 'none'
                    : 'flex',
                }}
              >
                <p> {z.dania.map(ele => ele.kategoria === 'deser' && ele.ilosc)}</p>{' '}
                <img className="iko" src={Desser} alt="Desser" />
              </div>
              <div
                style={{
                  display: z.dania
                    .map(ele => ele.kategoria === 'smoothie' && ele.ilosc)
                    .every(el => el === false)
                    ? 'none'
                    : 'flex',
                }}
              >
                <p> {z.dania.map(ele => ele.kategoria === 'smoothie' && ele.ilosc)}</p>{' '}
                <img className="ikoSmoo" src={NapojeZimne} alt="napoje zimne" />
              </div>
            </div>
          </SortableItem>
        ))}
      </SortableContainer>
    </div>,
  );
};

export default DropTarget(
  ItemTypes.BOX,
  {
    drop: ({ allowedDropEffect, driver, wyjazd }) => ({
      name: `${allowedDropEffect} Dustbin`,
      driver,
      wyjazd,
      allowedDropEffect,
    }),
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin);
