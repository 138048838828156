import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'views/Root/Root';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import * as serviceWorker from './serviceWorker';
import DriverContextProvider from './driverContext';

ReactDOM.render(
  <DriverContextProvider>
    <DndProvider backend={HTML5Backend}>
      <Root />
    </DndProvider>
  </DriverContextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
