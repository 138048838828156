import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Obraz from 'components/atoms/Obraz/Obraz';
import Button from 'components/atoms/Button/Button';
import Paragraph from 'components/atoms/Paragraph/Paragraph';

import { wyczyscZamowienieAction } from 'services/wyslijZamowienie';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  z-index: -100;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  &.pokazuj {
    opacity: 1;
    z-index: 1;
  }
`;

const P = styled(Paragraph)`
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.1em;
  margin-top: 3rem;
  margin-bottom: 2em;
`;

class PaymentConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { wyczyscZamowienie } = this.props;
    wyczyscZamowienie();
  }

  render() {
    const { src, alt, children, klik } = this.props;

    return (
      <Wrapper className={`${klik ? 'pokazuj' : ''}`}>
        <Obraz src={src} alt={alt} />
        <P>{children}</P>
        <Button active onClick={e => this.handleClick(e)}>
          SUPER!
        </Button>
      </Wrapper>
    );
  }
}

PaymentConfirmation.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  children: PropTypes.string.isRequired,
  klik: PropTypes.bool.isRequired,
  wyczyscZamowienie: PropTypes.func,
};

PaymentConfirmation.defaultProps = {
  alt: '',
  wyczyscZamowienie: null,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      wyczyscZamowienie: wyczyscZamowienieAction,
    },
    dispatch,
  );

export default connect(
  // mapStateToProps,
  null,
  mapDispatchToProps,
)(PaymentConfirmation);
