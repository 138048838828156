import React from 'react';
import PropTypes from 'prop-types';
// import Heading from 'components/atoms/Heading/Heading';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
// import UwagiLista from 'components/molecules/UwagiLista/UwagiLista';

const StyledCol = styled(Row)`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: row;
  padding-bottom: 8px;
  padding: 0;
  margin-bottom: 1rem;
  justify-content: flex-end;

  opacity: 1;
`;

const StyledLi = styled.div`
  border-top: 1px solid #aebdc2;
  text-align: center;
  line-height: 1;
  padding: 15px 0 5px 0;
`;

const StyledUl = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const Uwagi = ({ active, children, dania }) => {
  return (
    <StyledCol active={active ? 1 : 0}>
      <StyledUl>
        <StyledLi>Uwagi</StyledLi>
      </StyledUl>
      {dania.map((item, index) => {
        let items = [];

        if (children[index]) {
          const stringChildren = `${children[index]}`;
          items = stringChildren.split(' | ');
        } else {
          items = [''];
        }

        return (
          <StyledUl key={index}>
            {items.map((product, itemIndex) => (
              <StyledLi key={itemIndex}>{product}</StyledLi>
            ))}
          </StyledUl>
        );
      })}
    </StyledCol>
  );
};

Uwagi.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  dania: PropTypes.arrayOf(PropTypes.any),
};

Uwagi.defaultProps = {
  children: [],
  dania: [],
};

const mapStateToProps = state => {
  return {
    dania: state.kuchniaReducer.dania,
  };
};

export default connect(mapStateToProps)(Uwagi);
