export const FETCH_ARCHIWUM_ETYKIET_PENDING = 'FETCH_ARCHIWUM_ETYKIET_PENDING';
export const FETCH_ARCHIWUM_ETYKIET_SUCCESS = 'FETCH_ARCHIWUM_ETYKIET_SUCCESS';
export const FETCH_ARCHIWUM_ETYKIET_ERROR = 'FETCH_ARCHIWUM_ETYKIET_ERROR';

export function fetchArchiwumEtykietPending() {
  return {
    type: FETCH_ARCHIWUM_ETYKIET_PENDING,
  };
}

export function fetchArchiwumEtykietSuccess(data) {
  return {
    type: FETCH_ARCHIWUM_ETYKIET_SUCCESS,
    data,
  };
}

export function fetchArchiwumEtykietError(error) {
  return {
    type: FETCH_ARCHIWUM_ETYKIET_ERROR,
    error,
  };
}
