import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    // right: -1200px;
    left: -1200px;
    z-index: -1;
    background: transparent;
    transition: all 0.3s ease;
    border: 1px solid transparent;
  }
  &:hover {
    &:before {
      background: rgba(0, 0, 0, 0.1);
      border-color: #0b0b0b;
    }
  }
`;

const KontrahenciAdmin = ({
  index,
  nazwa,
  ulica,
  kodPocztowy,
  miasto,
  godzinyWyjazdu,
  NIP,
  kierowca,
}) => {
  return (
    <>
      <Cell center>{index + 1}.</Cell>
      <Cell>{nazwa}</Cell>
      <Cell>{ulica}</Cell>
      <Cell>{kodPocztowy}</Cell>
      <Cell>{miasto}</Cell>
      <Cell>{NIP}</Cell>
      <Cell>
        {godzinyWyjazdu.map((item, gwIndex) => (
          <span key={`gw_${item.id}`}>
            {gwIndex > 0 && <span>&nbsp;/&nbsp;</span>}
            <span>{item.godzinaWyjazdu}</span>
          </span>
        ))}
      </Cell>

      <Cell>{kierowca}</Cell>
    </>
  );
};

KontrahenciAdmin.propTypes = {
  index: PropTypes.number.isRequired,
  nazwa: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ulica: PropTypes.string,
  kodPocztowy: PropTypes.string,
  miasto: PropTypes.string,
  godzinyWyjazdu: PropTypes.arrayOf(PropTypes.object),
  NIP: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  kierowca: PropTypes.string,
};

KontrahenciAdmin.defaultProps = {
  nazwa: '',
  ulica: '',
  kodPocztowy: '',
  miasto: '',
  godzinyWyjazdu: [],
  NIP: 0,
  kierowca: '',
};

export default KontrahenciAdmin;
