import React from 'react';
import { Formik } from 'formik';
import {
  register as registerAction,
  checkUser as checkUserAction,
  logOut,
} from 'services/userServices';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import NawigacjaLogin from 'components/molecules/NawigacjaLogin/NawigacjaLogin';
import gruszka1 from 'assets/images/gruszka2.png';
import gruszka2 from 'assets/images/gruszka3.png';
import gruszka3 from 'assets/images/gruszka4a.png';
import PropTypes from 'prop-types';
import { theme } from 'theme/loginTheme';
import { ThemeProvider } from 'styled-components'; //  styled,
import * as Yup from 'yup';

import {
  StyledLogo,
  H,
  Formularz,
  Input,
  GruszkaPierwsza,
  GruszkaDruga,
  GruszkaTrzecia,
  Wrapper,
  //  Logout,
  StyledButton,
  Alert,
} from './style';

const RejestracjaSchema = Yup.object().shape({
  imie: Yup.string().required('Pole jest wymagane!'),
  nazwisko: Yup.string().required('Pole jest wymagane!'),
  email: Yup.string()
    .email()
    .required('Pole jest wymagane!'),
  telefon: Yup.string().required('Pole jest wymagane!'),
  firma: Yup.string().required('Pole jest wymagane!'),
  adres: Yup.string().required('Pole jest wymagane!'),
  kod_pocztowy: Yup.string().required('Pole jest wymagane!'),
  miejscowosc: Yup.string().required('Pole jest wymagane!'),
  password: Yup.string()
    .min(5, 'Hasło jest za krótkie!')
    .max(50, 'Hasło jest za długie!')
    .required('Pole jest wymagane!'),
  password2: Yup.string()
    .oneOf([Yup.ref('password'), null], 'podane hasła muszą być takie same!')
    .required('pole wymagane!'),
});

class Rejestracja extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        imie: '',
        nazwisko: '',
        email: '',
        telefon: '',
        firma: '',
        adres: '',
        kod_pocztowy: '',
        miejscowosc: '',
        password: '',
        password2: '',
      },
    };
  }

  componentDidMount() {
    const { checkUser } = this.props;

    checkUser();
  }

  handleChange = () => {
    const { isRemember } = this.state;
    this.setState({
      isRemember: !isRemember,
    });
  };

  logMeOut() {
    const { checkUser } = this.props;
    logOut().then(() => {
      checkUser();
    });
  }

  render() {
    const { data } = this.state;
    const { user } = this.props;
    const { register } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <GruszkaPierwsza src={gruszka1} alt="" />
          <GruszkaDruga src={gruszka2} alt="" />
          <GruszkaTrzecia src={gruszka3} alt="" />
          <div>
            <StyledLogo path="/login" />
            {!user.userId && (
              <>
                <H rank={1}>Zarejestruj się</H>
                <div className="loginWrapper__formularz">
                  <Formik
                    enableReinitialize
                    initialValues={data}
                    onSubmit={values => {
                      register(values).then(res => {
                        if (res && res.message === 'OK') {
                          return <Redirect to="/login" />;
                        }
                        return '';
                      });
                    }}
                    validationSchema={RejestracjaSchema}
                  >
                    {({ errors, touched, handleSubmit }) => (
                      <Formularz>
                        <Input name="imie" type="text" placeholder="Imię" autoComplete="imie" />
                        <Alert>
                          {errors.imie && touched.imie ? <span>{errors.imie}</span> : null}
                        </Alert>
                        <Input
                          name="nazwisko"
                          type="text"
                          placeholder="Nazwisko"
                          autoComplete="nazwisko"
                        />
                        <Alert>
                          {errors.nazwisko && touched.nazwisko ? (
                            <span>{errors.nazwisko}</span>
                          ) : null}
                        </Alert>
                        <Input
                          name="telefon"
                          type="text"
                          placeholder="Telefon"
                          autoComplete="telefon"
                        />
                        <Alert>
                          {errors.telefon && touched.telefon ? <span>{errors.telefon}</span> : null}
                        </Alert>
                        <Input
                          name="email"
                          type="text"
                          placeholder="Adres e-mail"
                          autoComplete="email"
                        />
                        <Alert>
                          {errors.email && touched.email ? <span>{errors.email}</span> : null}
                        </Alert>
                        <Input
                          name="firma"
                          type="text"
                          placeholder="Nazwa firmy"
                          autoComplete="firma"
                        />
                        <Alert>
                          {errors.firma && touched.firma ? <span>{errors.firma}</span> : null}
                        </Alert>
                        <Input
                          name="adres"
                          type="text"
                          placeholder="Adres dostawy"
                          autoComplete="adres"
                        />
                        <Alert>
                          {errors.adres && touched.adres ? <span>{errors.adres}</span> : null}
                        </Alert>
                        <Input
                          name="kod_pocztowy"
                          type="text"
                          placeholder="Kod pocztowy"
                          autoComplete="kod_pocztowy"
                        />
                        <Alert>
                          {errors.kod_pocztowy && touched.kod_pocztowy ? (
                            <span>{errors.kod_pocztowy}</span>
                          ) : null}
                        </Alert>
                        <Input
                          name="miejscowosc"
                          type="text"
                          placeholder="Miejscowość"
                          autoComplete="miejscowosc"
                        />
                        <Alert>
                          {errors.miejscowosc && touched.miejscowosc ? (
                            <span>{errors.miejscowosc}</span>
                          ) : null}
                        </Alert>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Twoje hasło"
                          autoComplete="password"
                        />
                        <Alert>
                          {errors.password && touched.password ? (
                            <span>{errors.password}</span>
                          ) : null}
                        </Alert>
                        <Input name="password2" type="password" placeholder="Powtórz hasło" />
                        <Alert>
                          {errors.password2 && touched.password2 ? (
                            <span>{errors.password2}</span>
                          ) : null}
                        </Alert>
                        <StyledButton type="submit" onClick={handleSubmit}>
                          Zarejestruj się
                        </StyledButton>
                        <p>
                          Masz już konto? &nbsp; <NavLink to="/login">zaloguj się</NavLink>
                        </p>
                      </Formularz>
                    )}
                  </Formik>
                </div>
              </>
            )}
            {user && user.userId && (
              <>
                <Redirect to="/login" />
                {/* <H rank={1}>Wybierz</H>
                {user.widoki && Object.keys(user.widoki).length > 1 && (
                  <NawigacjaLogin
                    key={Object.keys(user.widoki)[0]}
                    widoki={user.widoki}
                    uprawnienia={user.uprawnienia}
                  />
                )}
                {user.widoki && Object.keys(user.widoki).length === 1 && (
                  <Redirect to={`/${user.widoki[Object.keys(user.widoki)[0]]}`} />
                )}
                <Logout
                  type="submit"
                  onClick={() => {
                    this.logMeOut();
                  }}
                >
                  Wyloguj się
                </Logout> */}
              </>
            )}
          </div>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

Rejestracja.propTypes = {
  register: PropTypes.func.isRequired,
  checkUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userId: PropTypes.number,
    uprawnienia: PropTypes.shape({}),
    widoki: PropTypes.shape({}),
  }),
};

Rejestracja.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.userReducer.user,
});
const mapDispatchToProps = dispatch => ({
  register: values => dispatch(registerAction(values)),
  checkUser: () => dispatch(checkUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rejestracja);
