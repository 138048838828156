import React from 'react';
import { Redirect } from 'react-router-dom';
import NawigacjaUser from 'components/molecules/NawigacjaUser/NawigacjaUser';
import { checkUser as checkUserAction } from 'services/userServices';
import PropTypes from 'prop-types';
import Logo from 'components/atoms/Logo/Logo';
import { connect } from 'react-redux';

class TymczasowyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { wczytane: false };
  }

  componentDidMount() {
    const { checkUser, user } = this.props;
    if (Object.entries(user).length === 0 && user.constructor === Object) {
      checkUser().then(() => this.setState({ wczytane: true }));
    } else {
      this.setState({ wczytane: true });
    }
  }

  render() {
    const { user } = this.props;
    const { wczytane } = this.state;
    if (wczytane && user && user.widoki && Object.keys(user.widoki).length === 1) {
      return false;
    }
    return (
      <>
        {wczytane ? (
          <>
            {user && user.uprawnienia ? (
              <div id="tymczasowyHeader">
                <NawigacjaUser widoki={user.widoki} uprawnienia={user.uprawnienia} />
              </div>
            ) : (
              <Redirect to="/login" />
            )}
          </>
        ) : (
          <div className="preloader">
            <div className="preloader__container">
              <Logo />
            </div>
          </div>
        )}
      </>
    );
  }
}

TymczasowyHeader.propTypes = {
  checkUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userId: PropTypes.number,
    uprawnienia: PropTypes.shape({}),
    widoki: PropTypes.shape({}),
  }),
};

TymczasowyHeader.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.userReducer.user,
});

const mapDispatchToProps = dispatch => ({
  checkUser: () => dispatch(checkUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TymczasowyHeader);
