import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from 'theme/adminTheme';
import Header from 'components/organisms/HeaderAdmin/Header';
import TymczasowyHeader from 'components/molecules/TymczasowyHeader/TymczasowyHeader';

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AdminTemplate = ({ children }) => (
  <ThemeProvider theme={theme}>
    <TymczasowyHeader />
    <AdminContainer>
      <Header />
      {children}
    </AdminContainer>
  </ThemeProvider>
);

AdminTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default AdminTemplate;
