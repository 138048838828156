export const FETCH_KIEROWCA_ZAMOWIENIA_PENDING = 'FETCH_KIEROWCA_ZAMOWIENIA_PENDING';
export const FETCH_KIEROWCA_ZAMOWIENIA_SUCCESS = 'FETCH_KIEROWCA_ZAMOWIENIA_SUCCESS';
export const FETCH_KIEROWCA_ZAMOWIENIA_ERROR = 'FETCH_KIEROWCA_ZAMOWIENIA_ERROR';
export const FETCH_KIEROWCA_GODZINY_PENDING = 'FETCH_KIEROWCA_GODZINY_PENDING';
export const FETCH_KIEROWCA_GODZINY_SUCCESS = 'FETCH_KIEROWCA_GODZINY_SUCCESS';
export const FETCH_KIEROWCA_GODZINY_ERROR = 'FETCH_KIEROWCA_GODZINY_ERROR';
export const FETCH_KIEROWCA_FIRMY_PENDING = 'FETCH_KIEROWCA_FIRMY_PENDING';
export const FETCH_KIEROWCA_FIRMY_SUCCESS = 'FETCH_KIEROWCA_FIRMY_SUCCESS';
export const FETCH_KIEROWCA_FIRMY_ERROR = 'FETCH_KIEROWCA_FIRMY_ERROR';
export const FETCH_KIEROWCA_STATUS_PENDING = 'FETCH_KIEROWCA_STATUS_PENDING';
export const FETCH_KIEROWCA_STATUS_SUCCESS = 'FETCH_KIEROWCA_STATUS_SUCCESS';
export const FETCH_KIEROWCA_STATUS_ERROR = 'FETCH_KIEROWCA_STATUS_ERROR';

export function fetchKierowcaZamowieniaPending() {
  return {
    type: FETCH_KIEROWCA_ZAMOWIENIA_PENDING,
  };
}

export function fetchKierowcaZamowieniaSuccess(data) {
  return {
    type: FETCH_KIEROWCA_ZAMOWIENIA_SUCCESS,
    data,
  };
}

export function fetchKierowcaZamowieniaError(error) {
  return {
    type: FETCH_KIEROWCA_ZAMOWIENIA_ERROR,
    error,
  };
}

export function fetchKierowcaGodzinyPending() {
  return {
    type: FETCH_KIEROWCA_GODZINY_PENDING,
  };
}

export function fetchKierowcaGodzinySuccess(data) {
  return {
    type: FETCH_KIEROWCA_GODZINY_SUCCESS,
    data,
  };
}

export function fetchKierowcaGodzinyError(error) {
  return {
    type: FETCH_KIEROWCA_GODZINY_ERROR,
    error,
  };
}

export function fetchKierowcaFirmyPending() {
  return {
    type: FETCH_KIEROWCA_FIRMY_PENDING,
  };
}

export function fetchKierowcaFirmySuccess(data) {
  return {
    type: FETCH_KIEROWCA_FIRMY_SUCCESS,
    data,
  };
}

export function fetchKierowcaFirmyError(error) {
  return {
    type: FETCH_KIEROWCA_FIRMY_ERROR,
    error,
  };
}

export function fetchKierowcaStatusPending() {
  return {
    type: FETCH_KIEROWCA_STATUS_PENDING,
  };
}

export function fetchKierowcaStatusSuccess(data) {
  return {
    type: FETCH_KIEROWCA_STATUS_SUCCESS,
    data,
  };
}

export function fetchKierowcaStatusError(error) {
  return {
    type: FETCH_KIEROWCA_STATUS_ERROR,
    error,
  };
}
