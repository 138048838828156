export const routes = {
  home: '/',
  login: '/login',
  rejestracja: '/zarejestruj-sie',
  admin: '/admin/:type',
  adminContractor: '/admin/:type/:contractor',
  zamowienia: '/zamowienia',
  drivers: '/driver',
  currentDriver: '/driver/:id',
  kontrahenci: '/kontrahenci',
  kontrahent: '/kontrahent/:id',
  uzytkownikFirmy: '/kontrahent/:kontrahent/uzytkownik/:id',
  dania: '/dania',
  menu: '/menu',
  users: '/users',
  user: '/users/:id',
  klient: '/zamow-posilek',
  konto: '/moje-konto',
  historia: '/historia-zamowien',
  kuchnia: '/kuchnia',
  kuchniaLokal: '/kuchnia-lokal',
  kierowca: '/kierowca',
  kierowcaGodzina: '/kierowca/godzina/:id',
  kierowcaKontrahent: '/kierowca/godzina/:godzina/kontrahent/:id',
  kierowcaKontrahentOddzial: '/kierowca/godzina/:godzina/kontrahent/:id/:oddzial',
  // listaKontrahentow: '/kontrahenci-lista',
  wyjazdy: '/wyjazdy',
};
