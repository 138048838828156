import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import LogoImage from 'assets/svg/logo.svg';
import styled from 'styled-components';

const Styledlogo = styled(NavLink)`
  display: block;
  width: 16.4rem;
  height: 17.5rem;
  background-image: url(${LogoImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  @media (max-width: 991px) {
    width: 12rem;
    height: 14rem;
    margin-top: 2rem;
  }
`;

const Logo = ({ path, className }) => <Styledlogo className={className} to={path} />;

Logo.propTypes = {
  path: PropTypes.string,
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: 'heading',
  path: '/',
};

export default Logo;
