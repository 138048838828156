import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-tabs';
import { addUser } from 'services/admin/usersServices';
import Col from 'react-bootstrap/Col';
import { pobierzListeFirmAction } from 'services/admin/firmyServices';
import Form from 'react-bootstrap/Form';
import Button from 'components/atoms/Button/Button';
import { addAlert } from 'actions/alertActions';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import styled from 'styled-components';
import 'react-tabs/style/react-tabs.css';
import PropTypes from 'prop-types';
import { Wrapper } from 'views/Admin/style';
// import { useHistory } from 'react-router-dom';

const AdminWrapper = styled(Wrapper)`
  min-height: 100vh;
  flex-direction: column;
  padding-top: 100px;
  align-items: flex-start;
`;

const StyledWrapper = styled.div`
  margin-top: 4rem;
  width: 20vw;
  min-width: 500px;
`;

const StyledTitle = styled.h2`
  font-size: 4rem;
`;

const StyledControl = styled(Form.Control)`
  width: 100%;
`;

const StyledRow = styled(Form.Row)`
  justify-content: flex-end;
`;

const InputCheckbox = styled.input`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const schema = Yup.object({
  imie: Yup.string().required('imię jest wymagane'),
  nazwisko: Yup.string().required('naziwsko jest wymagane'),
  password: Yup.string()
    .required('hasło jest wymagane')
    .min(5, 'hasło musi się składać z minimum 5 znaków'),
  login: Yup.string().required('login jest wymagane'),
  email: Yup.string()
    .email('proszę podać prawidłowy adres e-mail')
    .required('e-mail jest wymagane'),
  telefon: Yup.string(),
  faktura: Yup.bool(),
  status: Yup.bool(),
  permissions: Yup.array().required('uprawnienia są wymagane'),
  contractor: Yup.string(),
});

const permissionOptions = [
  { value: 'klient', label: 'Klient' },
  { value: 'kierowca', label: 'Kierowca' },
  { value: 'kuchnia', label: 'Kuchnia' },
  { value: 'lokal', label: 'Lokal' },
  { value: 'admin', label: 'Admin' },
  { value: 'admin_firma', label: 'Admin Firma' },
  { value: 'super_user', label: 'Super User' },
];

const Users = () => {
  const [options, setOptions] = useState(null);

  const fetchContractors = () => {
    pobierzListeFirmAction().then(res => {
      let data = [];

      data = res.map(item => ({
        id: item.id,
        value: item.nazwa,
        label: item.nazwa,
      }));

      data.unshift({ id: 0, value: 'Brak Firmy', label: 'Brak Firmy' });

      setOptions(data);
    });
  };
  useEffect(() => {
    fetchContractors();
  }, []);

  return (
    <>
      <Tabs defaultIndex={0}>
        <AdminWrapper>
          <StyledTitle>Dodaj Użytkownika</StyledTitle>
          <StyledWrapper>
            <Formik
              validationSchema={schema}
              enableReinitialize
              initialValues={{
                imie: '',
                nazwisko: '',
                email: '',
                login: '',
                telefon: '',
                faktura: 0,
                status: 0,
                permissions: [],
                password: '',
                contractor: '',
              }}
              onSubmit={(values, { resetForm }) => {
                addUser(values).then(data => {
                  if (Number.isInteger(data)) {
                    addAlert({
                      title: 'Dodano użytkownika',
                      message: `Dodano użytkownika`,
                      type: 'success',
                    });
                    resetForm();
                  } else {
                    addAlert({
                      title: 'Nie dodano użytkownika',
                      message: `Login lub email zajęty`,
                      type: 'danger',
                    });
                  }
                });
              }}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <Form onSubmit={e => handleSubmit(e)}>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="imie">
                      <Form.Label>Imię</Form.Label>
                      <StyledControl
                        size="lg"
                        name="imie"
                        value={values.imie}
                        onChange={handleChange}
                        type="text"
                        autoComplete="imie"
                      />
                      <ErrorMessage name="imie">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="nazwisko">
                      <Form.Label>Nazwisko</Form.Label>
                      <StyledControl
                        size="lg"
                        name="nazwisko"
                        value={values.nazwisko}
                        onChange={handleChange}
                        type="text"
                        autoComplete="nazwisko"
                      />
                      <ErrorMessage name="nazwisko">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <StyledControl
                        size="lg"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        type="email"
                      />
                      <ErrorMessage name="email">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="login">
                      <Form.Label>Login</Form.Label>
                      <StyledControl
                        size="lg"
                        name="login"
                        value={values.login}
                        onChange={handleChange}
                        type="text"
                        autoComplete="login"
                      />
                      <ErrorMessage name="login">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="telefon">
                      <Form.Label>Telefon</Form.Label>
                      <StyledControl
                        size="lg"
                        name="telefon"
                        value={values.telefon}
                        onChange={handleChange}
                        type="number"
                        autoComplete="telefon"
                      />
                      <ErrorMessage name="telefon">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="permissions">
                      <Form.Label>Uprawnienia</Form.Label>
                      <Select
                        options={permissionOptions}
                        isMulti
                        placeholder="Wybierz"
                        name="permissions"
                        onChange={e =>
                          setFieldValue('permissions', e !== null && e.map(i => i.value))
                        }
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="3" controlId="status">
                      Status
                      <InputCheckbox
                        type="checkbox"
                        name="status"
                        label="Status"
                        onChange={handleChange}
                        value
                        checked={values.status}
                      />
                      <ErrorMessage name="status">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="faktura">
                      Faktura
                      <InputCheckbox
                        type="checkbox"
                        name="faktura"
                        label="Faktura"
                        onChange={handleChange}
                        value
                        checked={values.faktura}
                      />
                      <ErrorMessage name="faktura">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="password">
                      <Form.Label>Hasło</Form.Label>
                      <StyledControl
                        size="lg"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        type="password"
                      />
                      <ErrorMessage name="password">
                        {msg => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="contractor">
                      <Form.Label>Firma</Form.Label>
                      <Select
                        options={options}
                        name="contractor"
                        placeholder="Wybierz"
                        onChange={e => setFieldValue('contractor', e.id)}
                      />
                    </Form.Group>
                  </Form.Row>
                  <StyledRow>
                    <Button small type="submit">
                      Zapisz
                    </Button>
                  </StyledRow>
                </Form>
              )}
            </Formik>
          </StyledWrapper>
        </AdminWrapper>
      </Tabs>
    </>
  );
};

Users.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    nazwa: PropTypes.string,
    imie: PropTypes.string,
    nazwisko: PropTypes.string,
    email: PropTypes.string,
    login: PropTypes.string,
    telefon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    faktura: PropTypes.number,
    status: PropTypes.number,
    permissions: PropTypes.arrayOf(PropTypes.string),
    ostatnieLogowanie: PropTypes.string,
  }),
};

Users.defaultProps = {
  user: {
    id: 0,
    imie: '',
    nazwa: '',
    nazwisko: '',
    email: '',
    login: '',
    telefon: '',
    status: 0,
    faktura: 0,
    permissions: [],
    ostatnieLogowanie: '',
  },
};

export default Users;
