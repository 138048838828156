// import axios from 'axios';

import { ustawDateMenu } from 'actions/filterActions';

// import { addAlert } from 'actions/alertActions';

export const ustawDateMenuAction = dataMenu => dispatch => {
  dispatch(ustawDateMenu(dataMenu));
};
/* dispatch(fetchDaniaPending());
  return axios
    .get('/api/admin/dania/wszystkie')
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(fetchDaniaSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(fetchDaniaError(error));
    }); */
