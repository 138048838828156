import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-tabs';
import AdminTemplate from 'templates/AdminTemplate';
import { fetchUsers as fetchUsersAction } from 'services/admin/usersServices';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { useHistory } from 'react-router-dom';
import CreateUser from 'components/organisms/CreateUser/CreateUser';
import paginationFactory from 'react-bootstrap-table2-paginator';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import 'react-tabs/style/react-tabs.css';
import PropTypes from 'prop-types';
import {
  StyledTabList,
  // StyledTabListA,
  StyledTab,
  StyledTabPanelA,
  // AdminWrapper,
  ArrowContC,
  ArrowA,
} from './style';
import styles from './Users.module.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './Admin.css';

const AdminWrapper = styled.div`
  min-height: 100vh;
  padding-top: 8rem;
  align-items: flex-start;
  display: flex;
  padding-bottom: 5rem;
`;
const StyledButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 13rem 13rem 13rem 13rem;
  gap: 3rem;
  height: 7rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 1365px) {
    padding-left: 3rem;
  }
`;
const pemissionFormatter = cell => {
  try {
    const res = cell.join(', ');

    return res;
  } catch (error) {
    return '';
  }
};

const statusFormatter = cell => (cell ? 'aktywny' : 'nieaktywny');

const fakturaFormatter = cell => (cell ? 'tak' : 'nie');

const columns = [
  {
    dataField: 'id',
    text: 'Id',
    headerStyle: { width: '50px' },
  },
  {
    dataField: 'login',
    // filter: textFilter(),
    sort: true,
    text: 'Login',
    width: '120',
    headerStyle: { width: '300px' },
  },
  {
    dataField: 'imie',
    // filter: textFilter(),
    sort: true,
    text: 'Imię',
  },
  {
    dataField: 'nazwisko',
    // filter: textFilter(),
    sort: true,
    text: 'Nazwisko',
  },
  {
    dataField: 'email',
    // filter: textFilter(),
    sort: true,
    text: 'Email',
    headerStyle: { width: '300px' },
  },
  {
    dataField: 'nazwa',
    // filter: textFilter(),
    sort: true,
    text: 'Nazwa Firmy',
    headerStyle: { width: '150px' },
  },
  {
    dataField: 'telefon',
    // filter: textFilter(),
    sort: true,
    text: 'Telefon',
  },
  {
    dataField: 'permissions',
    // filter: textFilter(),
    sort: true,
    text: 'Uprawnienia',
    formatter: pemissionFormatter,
    headerStyle: { width: '300px' },
  },
  {
    dataField: 'status',
    // filter: textFilter(),
    sort: true,
    formatter: statusFormatter,
    text: 'Status',
  },
  {
    dataField: 'faktura',
    // filter: textFilter(),
    formatter: fakturaFormatter,
    sort: true,
    text: 'Faktura',
    headerStyle: { width: '90px' },
  },
  {
    dataField: 'ostatnieLogowanie',
    // filter: textFilter(),
    sort: true,
    text: 'Ostatnie Logowanie',
    headerStyle: { width: '200px' },
  },
  {
    dataField: 'zablokowanyDo',
    // filter: textFilter(),
    sort: true,
    text: 'Zablokowany Do',
    headerStyle: { width: '200px' },
  },
];

const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = e => {
    e.preventDefault();
    onPageChange(page);
  };
  const activeStyle = {
    border: '1px solid #ccc',
    borderRadius: '2px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
  };
  if (active) {
    activeStyle.backgroundColor = 'black';
    activeStyle.color = 'white';
  } else {
    activeStyle.backgroundColor = 'white';
    activeStyle.color = 'black';
  }
  if (typeof page === 'string') {
    activeStyle.backgroundColor = 'white';
    activeStyle.color = 'black';
  }
  return (
    <li className="page-item" key={page}>
      <button type="button" onClick={handleClick} style={activeStyle}>
        {page}
      </button>
    </li>
  );
};

pageButtonRenderer.propTypes = {
  page: PropTypes.string,
  active: PropTypes.bool,
  onPageChange: PropTypes.func,
};

pageButtonRenderer.defaultProps = {
  page: '',
  active: false,
  onPageChange: null,
};

const paginationOptions = {
  paginationSize: 4,
  sizePerPageList: [
    {
      text: '20',
      value: 20,
    },
    {
      text: '30',
      value: 30,
    },
  ],
  pageButtonRenderer,
};

const Users = ({ fetchUsers, users }) => {
  // ,props
  const [navNone, setNavNone] = useState(false);

  const history = useHistory();
  useEffect(() => {
    fetchUsers();
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/users/${row.id}`);
    },
  };

  // console.log(users);

  return (
    <AdminTemplate>
      <Tabs defaultIndex={0}>
        <AdminWrapper>
          <StyledTabList
            style={{
              transition: '1s',
              left: navNone ? 0 : '-300px',
            }}
          >
            <StyledTab>Użytkownicy</StyledTab>
            <StyledTab>Dodaj Użytkownika</StyledTab>
          </StyledTabList>
          <ArrowContC
            onClick={() => setNavNone(!navNone)}
            style={{
              transition: '1s',
              left: navNone ? '300px' : '0px',
            }}
          >
            <ArrowA
              style={{
                transition: '1s',
                transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
              }}
            />
          </ArrowContC>
          <StyledTabPanelA>
            <StyledButtonWrapper>
              <Button small onClick={() => fetchUsers()}>
                Wszyscy
              </Button>
              <Button small onClick={() => fetchUsers('clients')}>
                Klienci
              </Button>
              <Button small onClick={() => fetchUsers('employees')}>
                Pracownicy
              </Button>
              <Button small onClick={() => fetchUsers('nieprzypisani')}>
                Nieprzypisani
              </Button>
            </StyledButtonWrapper>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={users}
              columns={columns}
              rowEvents={rowEvents}
              striped
              hover
              condensed
              // filter={filterFactory()}
              rowClasses={styles.row}
              classes={styles.table}
              pagination={paginationFactory(paginationOptions)}
            />
          </StyledTabPanelA>
          <StyledTabPanelA>
            <CreateUser />
          </StyledTabPanelA>
        </AdminWrapper>
      </Tabs>
    </AdminTemplate>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  fetchUsers: PropTypes.func,
};

Users.defaultProps = {
  users: [],
  fetchUsers: null,
};

const mapStateToProps = state => ({
  users: state.usersReducer.users,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers: fetchUsersAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Users);
