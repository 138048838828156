import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ZamowienieSingle from 'components/molecules/ZamowienieSingle/ZamowienieSingle';
import { pobierzKierowcaZamowieniaAction } from 'services/kierowcaZamowieniaService';
import { bindActionCreators } from 'redux';
import KierowcaTemplate from 'templates/KierowcaTemplate';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import { PojedynczyKontrahentWrapper, H2 } from './style';

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
const StyledHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PojedynczyKontrahent = ({ klient, pobierzZamowienia }) => {
  const { id, godzina, oddzial } = useParams();

  const fetchOrders = () => {
    pobierzZamowienia(godzina, id, oddzial);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <KierowcaTemplate>
      <StyledHeadWrapper>
        <StyledButton nopadding onClick={() => pobierzZamowienia(godzina, id, oddzial)}>
          Odśwież
        </StyledButton>
      </StyledHeadWrapper>

      <PojedynczyKontrahentWrapper>
        <H2 rank={2}>Zamówienia</H2>
        {klient &&
          klient
            .filter(user => user.received === 0)
            .map(user => (
              <ZamowienieSingle
                received={0}
                key={user.orderId}
                id={user.orderId}
                klient={user.fullName}
                nazwa={user.dishes}
                cena={user.price}
                telefon={user.telefon}
                oddzial={user.oddzial}
                komentarz={user.komentarz}
                oplacone={user.paymentStatus}
                formaPlatnosci={user.paymentType}
                fetchOrders={() => fetchOrders()}
              />
            ))}
      </PojedynczyKontrahentWrapper>
      <PojedynczyKontrahentWrapper>
        <H2 rank={2}>Nieodebrane</H2>

        {klient &&
          klient
            .filter(user => user.received === -1)
            .map(user => (
              <ZamowienieSingle
                received={-1}
                key={user.orderId}
                id={user.orderId}
                klient={user.fullName}
                nazwa={user.dishes}
                telefon={user.telefon}
                cena={user.price}
                oddzial={user.oddzial}
                komentarz={user.komentarz}
                oplacone={user.paymentStatus}
                formaPlatnosci={user.paymentType}
                fetchOrders={() => fetchOrders()}
              />
            ))}
      </PojedynczyKontrahentWrapper>
      <PojedynczyKontrahentWrapper>
        <H2 rank={2}>Odebrane</H2>

        {klient &&
          klient
            .filter(user => user.received === 1)
            .map(user => (
              <ZamowienieSingle
                received={1}
                key={user.orderId}
                id={user.orderId}
                klient={user.fullName}
                telefon={user.telefon}
                nazwa={user.dishes}
                cena={user.price}
                oddzial={user.oddzial}
                komentarz={user.komentarz}
                oplacone={user.paymentStatus}
                formaPlatnosci={user.paymentType}
                fetchOrders={() => fetchOrders()}
              />
            ))}
      </PojedynczyKontrahentWrapper>
    </KierowcaTemplate>
  );
};

PojedynczyKontrahent.propTypes = {
  klient: PropTypes.arrayOf(PropTypes.shape({})),
  pobierzZamowienia: PropTypes.func,
};

PojedynczyKontrahent.defaultProps = {
  klient: [],
  pobierzZamowienia: null,
};

const mapStateToProps = state => ({
  klient: state.kierowcaReducer.zamowienia,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzZamowienia: pobierzKierowcaZamowieniaAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PojedynczyKontrahent);
