export const theme = {
  black: '#0b0b0b',
  white: '#fff',
  active: '#23BF39',
  disabled: 'red',
  valid: '#80CF26',
  green: '#23BF39',
  fSize: {
    h1: '4rem',
    h2: '2.4rem',
    h3: '2rem',
    h4: '1.8rem',
  },
};
