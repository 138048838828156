import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import RowTitleBox from './RowTitleBox';
import StatsBox from './StatsBox';

const Ro = styled(Row)`
  margin-top: 0.6rem;
`;

const ZamowieniaStatystyki = ({ title, dania }) => (
  <Ro>
    <RowTitleBox title={title} />
    {dania.map(item => (
      <StatsBox key={item.id} {...item} />
    ))}
  </Ro>
);

ZamowieniaStatystyki.propTypes = {
  title: PropTypes.string,
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
};

ZamowieniaStatystyki.defaultProps = {
  title: 'OST. / ŚR.',
  dania: [],
};

export default ZamowieniaStatystyki;
