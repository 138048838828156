import axios from 'axios';

import {
  przypiszZamowieniePending,
  przypiszZamowienieSuccess,
  przypiszZamowienieError,
  pobierzZamowieniaKierowcaSuccess,
  usunZamowieniePending,
  usunZamowienieError,
} from 'actions/zamowieniaActions';

import { addAlert } from 'actions/alertActions';

export const pobierzKierowcyZamowienia = () => dispatch =>
  axios
    .get(`/api/admin/zamowienia/firmy/kierowcy`)
    .then(payload => {
      dispatch(pobierzZamowieniaKierowcaSuccess(payload.data));
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy',
        message:
          err.response && err.response.data.error
            ? 'Wystąpił nieznany błąd!'
            : err.response.data.error,
        type: 'danger',
      });
    });

export const pobierzListaKierowca = () => dispatch =>
  axios
    .get(`/api/admin/zamowienia/firmy`)

    .then(payload => {
      // console.log(payload.data.data);
      dispatch(przypiszZamowienieSuccess(payload.data.data));
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy',
        message:
          err.response && err.response.data.error
            ? 'Wystąpił nieznany błąd!'
            : err.response.data.error,
        type: 'danger',
      });
    });

export const usunZamowienieKierowca = data => dispatch => {
  dispatch(usunZamowieniePending());
  return axios
    .post('/api/admin/zamowienia/firmy/usun', { data })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      // console.log(res);
      if (res.data.result === true) {
        dispatch(pobierzKierowcyZamowienia());
        dispatch(pobierzListaKierowca());
        addAlert({ message: `Zamówienie zostało usunięte!`, type: 'success' });
      }
      return res.data.result;
    })
    .catch(error => {
      dispatch(usunZamowienieError(error));
    });
};

export const zapiszKolejnoscKierowca = data => dispatch => {
  dispatch(przypiszZamowieniePending());
  return axios
    .post('/api/admin/zamowienia/firmy/kolejnosc', { data })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      // console.log(res);
      if (res.data.result === true) {
        dispatch(pobierzKierowcyZamowienia());
        dispatch(pobierzListaKierowca());
        addAlert({ message: `Kolejność została zmieniona!`, type: 'success' });
      }
      return res.data.result;
    })
    .catch(error => {
      dispatch(przypiszZamowienieError(error));
    });
};

export const przypiszZamowienie = data => dispatch => {
  dispatch(przypiszZamowieniePending());
  return axios
    .post('/api/admin/zamowienia/przypisz', { data })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      if (res.data.result === true) {
        // console.log(data);

        dispatch(pobierzListaKierowca());
        dispatch(pobierzKierowcyZamowienia());

        // pobierzZamowieniaAction(
        //   `${godzina.getFullYear()}-${godzina.getMonth() + 1}-${godzina.getDate()}`,
        // ),
        addAlert({ message: `Zamówienie zostało przypisane!`, type: 'success' });

        // pobierzZamowienieDrag(data);
      }
      return data;
    })
    .catch(error => {
      dispatch(przypiszZamowienieError(error));
    });
};
