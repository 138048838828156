import React from 'react';
import PropTypes from 'prop-types';
// import etykieta from 'assets/pdf/etykieta.pdf';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';

import { connect } from 'react-redux';
import { zaakceptujIloscAction, pobierzZamowieniaAction } from 'services/kuchniaServices';

const Link = styled.a`
  display: flex;
  text-align: center;
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.green};
  height: 30%;
  max-height: 4rem;
  line-height: 1em;
  align-items: center;
  justify-content: center;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div`
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  &:hover {
    background-color: #6d5cce;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2.6rem;

  font-weight: 700;
  border: 1px solid #aebdc2;
  background-color: #fff;
  position: relative;
  flex-grow: 1;
  height: 50%;
  line-height: 1em;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.6);
`;

const Badge = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border-bottom-left-radius: 6px;
  background-color: #f3de21;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  width: 2.4em;
  height: 2em;
  cursor: pointer;
`;

class IloscBox extends React.Component {
  state = {};

  zaakceptujIlosc() {
    const { id, godzinaWyjazdu, doAkceptacji, dispatch } = this.props;
    dispatch(zaakceptujIloscAction(id, godzinaWyjazdu, doAkceptacji));
  }

  odswiezKuchnie() {
    const { dispatch } = this.props;
    setTimeout(() => {
      dispatch(pobierzZamowieniaAction());
    }, 1000);
  }

  render() {
    const { id, zatwierdzone, etykiety, doAkceptacji, menuId, godzinaWyjazdu } = this.props;

    return (
      <Col>
        <Container data-id={id}>
          <Wrapper>
            {zatwierdzone}
            {doAkceptacji > 0 && (
              <Badge
                onClick={e => {
                  this.zaakceptujIlosc(e);
                }}
              >
                + {doAkceptacji}
              </Badge>
            )}
            {/* <DoAkceptacji /> Na przyszłość może component? */}
          </Wrapper>
          {zatwierdzone > 0 && etykiety - doAkceptacji > 0 && (
            <Link
              href={`/api/kuchnia/etykiety/${menuId}/${godzinaWyjazdu}/${id}`}
              target="_blank"
              aria-label="pobierz etykietę"
              onClick={() => {
                this.odswiezKuchnie();
              }}
            >
              drukuj etykiety
            </Link>
          )}
        </Container>
      </Col>
    );
  }
}

IloscBox.propTypes = {
  id: PropTypes.number.isRequired,
  menuId: PropTypes.number.isRequired,
  godzinaWyjazdu: PropTypes.string.isRequired,
  zatwierdzone: PropTypes.number.isRequired,
  etykiety: PropTypes.number,
  doAkceptacji: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Do wywalenia po ogarnięciu stanu
IloscBox.defaultProps = {
  etykiety: 0,
};

export default connect(null, null)(IloscBox);
