import React from 'react';
import styled from 'styled-components';
import przelew from 'assets/svg/paymentWhite.svg';
import creditCard from 'assets/svg/creditCardWhite.svg';
// import { connect } from 'react-redux';
import cash from 'assets/svg/gotowkaWhite.svg';
import Button from 'components/atoms/Button/Button';
import Heading from 'components/atoms/Heading/Heading';
import Obraz from 'components/atoms/Obraz/Obraz';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import PropTypes from 'prop-types';

const H2 = styled(Heading)`
  text-align: center;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fSize.h2};
  margin-bottom: 0.6em;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
`;

const PaymentMethod = styled(Button)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  padding: 3.5rem 15px;
  transition: all 0.3s ease;
  color: #fff;
  background-color: ${({ disabled, theme }) => (disabled ? theme.disabled : theme.black)};
  &.active {
    background-color: #6d5cce;
    border-color: #6d5cce;
  }
  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? theme.disabled : theme.active)};
    border-color: ${({ disabled, theme }) => (disabled ? theme.disabled : theme.active)};
  }
`;

const P = styled(Paragraph)`
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.1em;
  margin-top: 3rem;
  color: #fff;
`;

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: '',
    };
  }

  handleClick = param => () => {
    const { klik } = this.props;
    klik(param);
    this.setState({ active: param });
  };

  render() {
    const { active } = this.state;
    const { isFaktura, isDisabled } = this.props;

    return (
      <>
        <H2 rank={2}>Wybierz metodę płatności</H2>
        <Wrapper>
          {isFaktura ? (
            <PaymentMethod
              className={`${active === 'faktura' ? ' active' : ''}`}
              onClick={this.handleClick('faktura')}
              disabled={isDisabled}
            >
              <Obraz src={przelew} alt="Faktura" />
              <P>Faktura</P>
            </PaymentMethod>
          ) : (
            <> </>
            // <PaymentMethod
            //   disabled
            //   className={`${active === 'faktura' ? ' active' : ''}`}
            //   onClick={this.handleClick('faktura')}
            // >
            //   <Obraz src={przelew} alt="Faktura" />
            //   <P>Faktura</P>
            // </PaymentMethod>
          )}

          <PaymentMethod
            className={active === 'online' ? ' active' : ''}
            onClick={this.handleClick('online')}
            disabled={isDisabled}
          >
            <Obraz src={creditCard} alt="Karta płatnicza" />
            <P>Online</P>
          </PaymentMethod>
          <PaymentMethod
            className={active === 'gotowka' ? ' active' : ''}
            onClick={this.handleClick('gotowka')}
            disabled={isDisabled}
          >
            <Obraz src={cash} alt="Gotówka" />
            <P>przy odbiorze</P>
          </PaymentMethod>
        </Wrapper>
      </>
    );
  }
}
Payment.propTypes = {
  klik: PropTypes.func,
  isFaktura: PropTypes.number,
  isDisabled: PropTypes.bool,
};

Payment.defaultProps = {
  klik: null,
  isFaktura: 0,
  isDisabled: false,
};

// const mapStateToProps = () => {
//   // return {
//   //   isFaktura: state.userReducer.user.faktura,
//   // };
// };

export default Payment;
