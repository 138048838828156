import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, AddNew } from 'views/Admin/style';
import Obraz from 'components/atoms/Obraz/Obraz';
import whiteTick from 'assets/svg/whiteTick.svg';
import { zapiszHasloAction } from 'services/admin/firmyServices';
import { addAlert } from 'actions/alertActions';
import { useParams } from 'react-router-dom';

const InputDania = styled(Input)`
  border: 1px solid rgba(128, 207, 38, 0.2);
  border-bottom-color: rgba(128, 207, 38, 1);
  width: 300px;
  margin-right: 2rem;
  &:focus {
    outline: none;
    border-color: #80cf26;
    background-color: rgba(128, 207, 38, 0.4);
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  margin-left: 4rem;
  margin-top: 3rem;
`;

const UstawHaslo = () => {
  const [password, setPassword] = useState('');
  const { id } = useParams();

  const zapiszHaslo = () => {
    if (password === '') {
      addAlert({
        title: 'Błąd!',
        message: 'brak hasła',
        type: 'danger',
      });
    } else {
      zapiszHasloAction({ id, password }).then(() => {
        addAlert({ message: `Hasło zapisane`, type: 'success' });
      });
      setPassword('');
    }
  };

  return (
    <StyledWrapper>
      <InputDania
        value={password}
        type="password"
        name="password"
        onChange={e => setPassword(e.target.value)}
        placeholder="Hasło"
      />

      <AddNew onClick={zapiszHaslo}>
        <Obraz src={whiteTick} alt="Dodaj użytkownika" />
      </AddNew>
    </StyledWrapper>
  );
};

export default UstawHaslo;
