import axios from 'axios';
import { addAlert } from 'actions/alertActions';

export const zapiszFirmeAction = firma => {
  return axios
    .post('/api/admin/firmy/zapisz', { firma })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      addAlert({ message: `Firma została zapisana!`, type: 'success' });
      return res.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy zapisz',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const zapiszGodzinyFirmyAction = (wybraneGodziny, firmaId, godzinyDostawy) => {
  return axios
    .post('/api/admin/firmy/godziny/zapisz', { wybraneGodziny, firmaId, godzinyDostawy })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      addAlert({ message: `Godziny firmy zostały zapisane!`, type: 'success' });
      return res.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Godziny firmy zapisz',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const zapiszRabatAction = (firmaId, rabat) => {
  return axios
    .post('/api/admin/firmy/rabat', { firmaId, rabat })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      addAlert({ message: `Zmieniono rabat`, type: 'success' });
      return res.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Godziny firmy zapisz',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzFirmaByIdAction = firmaId => {
  return axios
    .get(`/api/admin/firmy/${firmaId}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzUzytkownicyByFirmaIdAction = firmaId => {
  return axios
    .get(`/api/admin/firmy/uzytkownicy/${firmaId}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy - użytkownicy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzDaneUzytkownikaAction = uzytkownikId => {
  return axios
    .get(`/api/admin/firmy/uzytkownik/${uzytkownikId}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      return res.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy - użytkownicy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzFirmyAction = () => {
  return axios
    .get(`/api/admin/firmy`)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzListaFirmAction = () => {
  return axios
    .get(`/api/admin/lista-firm`)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzListeFirmAction = () => {
  return axios
    .get(`/api/admin/firmy/lista`)
    .then(payload => {
      return payload.data;
    })
    .catch(err => {
      addAlert({
        title: 'Firmy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzGodzinyAction = () => {
  return axios
    .get(`/api/admin/godziny`)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Godziny',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};
export const pobierzKierowcyAction = () => {
  return axios
    .get(`/api/admin/kierowcy`)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Kierowcy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const setFakturaAction = (userId, faktura) => {
  return axios
    .post(`/api/admin/uzytkownik/faktura`, { userId, faktura })
    .then(payload => {
      return payload.data;
    })
    .catch(err => {
      addAlert({
        title: 'Kierowcy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const zapiszUzytkownikaAction = userId => {
  return axios
    .post(`/api/admin/firmy/uzytkownik/zapisz`, userId)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Kierowcy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const usunUzytkownikaAction = userId => {
  return axios
    .get(`/api/admin/firmy/uzytkownik/usun/${userId}`)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Kierowcy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const zapiszHasloAction = user => {
  return axios
    .post(`/api/admin/firmy/uzytkownik/haslo`, { user })
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Kierowcy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const usunKontrahentaAction = req => {
  return axios
    .get(`/api/admin/firmy/usun/${req}`)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Kierowcy',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const fetchContractors = type => {
  return axios
    .get(`/api/admin/control/${type}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data;
    })
    .catch(() => {});
};

export const iloscDan = type => {
  return axios
    .get(`/api/admin/control/${type}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data;
    })
    .catch(() => {});
};
