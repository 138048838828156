import { AUTHENTICATE_SUCCESS, POBIERZ_DANE_SUCCESS } from 'actions/userActions';

const initialState = {
  user: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_SUCCESS:
      // console.log(action);
      return {
        ...state,
        user: action.payload.data,
      };
    case POBIERZ_DANE_SUCCESS:
      // console.log(action);
      return {
        ...state,
        mojeDane: action.dane,
      };

    default:
      return state;
  }
};

export default userReducer;
