import axios from 'axios';
import {
  fetchDaniaKuchniaPending,
  fetchDaniaKuchniaSuccess,
  fetchDaniaKuchniaError,
  pobierzZamowieniaKuchniaSuccess,
} from 'actions/kuchniaActions';

import { addAlert } from 'actions/alertActions';

export const pobierzZamowieniaAction = () => dispatch => {
  // dispatch(fetchDaniaPending());
  return axios
    .get('/api/kuchnia/zamowienia')
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(pobierzZamowieniaKuchniaSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      addAlert({
        title: 'Błąd!',
        message: error ? error.toString() : 'Wystąpił  błąd!',
        type: 'danger',
      });
      // dispatch(fetchDaniaError(error));
    });
};

export const pobierzDaniaDoMenuAction = () => dispatch => {
  dispatch(fetchDaniaKuchniaPending());
  return axios
    .get(`/api/kuchnia/menu`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(fetchDaniaKuchniaSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      addAlert({
        title: 'Błąd!',
        message: error ? error.toString() : 'Wystąpił  błąd!',
        type: 'danger',
      });
      dispatch(fetchDaniaKuchniaError(error));
    });
};

export const zaakceptujIloscAction = (danieId, godzinaWyjazdu, ilosc) => dispatch => {
  // dispatch(fetchDaniaKuchniaPending());
  return axios
    .post(`/api/kuchnia/zaakceptujIlosc`, { danieId, godzinaWyjazdu, ilosc })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(pobierzZamowieniaAction());
      addAlert({
        title: 'Kuchnia',
        message: `Przyjęto do realizacji`,
        type: 'success',
      });
      return res.data.data;
    })
    .catch(error => {
      addAlert({
        title: 'Błąd!',
        message: error ? error.toString() : 'Wystąpił nieznany błąd!',
        type: 'danger',
      });
      //  dispatch(fetchDaniaKuchniaError(error));
    });
};
