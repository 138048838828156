import React from 'react';
import PropTypes from 'prop-types';
import {
  pobierzFirmaByIdAction,
  pobierzDaneUzytkownikaAction,
  zapiszUzytkownikaAction,
} from 'services/admin/firmyServices';
import AdminTemplate from 'templates/AdminTemplate';
import { addAlert } from 'actions/alertActions';
import SidebarFirmy from 'components/organisms/SidebarFirmy/SidebarFirmy';
import EdytujUzytkownika from 'components/organisms/EdytujUzytkownika/EdytujUzytkownika';
import Heading from 'components/atoms/Heading/Heading';
import UstawHaslo from 'components/organisms/UstawHaslo/UstawHaslo';
import {
  AdminWrapperA,
  ArrowContC,
  ArrowA,
  Content,
  Container,
  WybranaFirma,
  StyledHeading,
} from 'views/Admin/style';

class AdminUzytkownik extends React.Component {
  constructor(props) {
    super(props);

    this.zapiszUzytkownika = this.zapiszUzytkownika.bind(this);

    this.state = {
      wybranaFirma: {
        nazwa: '',
        ulica: '',
        kodPocztowy: '',
        miasto: '',
        NIP: '',
        status: 0,
        kierowcaId: 0,
      },
      wybraneFirmy: [],
      godziny: null,
      uzytkownik: {
        email: '',
        id: 0,
        imie: '',
        firmaId: 0,
        login: '',
        nazwisko: '',
        telefon: '',
        uprawnienia: '',
        faktura: 0,
      },
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    pobierzFirmaByIdAction(params.kontrahent).then(wybranaFirma => {
      this.setState({ wybranaFirma });
    });
    pobierzDaneUzytkownikaAction(params.id).then(uzytkownik => {
      this.setState({ uzytkownik });
    });
  }

  zapiszUzytkownika(uzytkownik) {
    const {
      match: { params },
    } = this.props;
    zapiszUzytkownikaAction({ uzytkownik }).then(() => {
      addAlert({ message: `Użytkownik zapisany`, type: 'success' });
      pobierzDaneUzytkownikaAction(params.id).then(user => {
        this.setState({ uzytkownik: user });
      });
    });
  }

  render() {
    const { wybranaFirma, wybraneFirmy, godziny, uzytkownik } = this.state;
    const { nazwa } = wybranaFirma;

    return (
      <AdminTemplate>
        <AdminWrapperA>
          <SidebarFirmy firmy={wybraneFirmy} godzinyWyjazdu={godziny} />
          <ArrowContC>
            <ArrowA />
          </ArrowContC>
          <Content>
            {wybranaFirma.id > 0 && (
              <Container>
                <WybranaFirma>{nazwa}</WybranaFirma>
                <StyledHeading>
                  <Heading rank={2}>Użytkownik</Heading>
                </StyledHeading>
                {uzytkownik.id !== 0 ? (
                  <EdytujUzytkownika
                    uzytkownik={uzytkownik}
                    zapiszUzytkownika={this.zapiszUzytkownika}
                    edycja
                  />
                ) : null}

                <StyledHeading>
                  <Heading rank={2}>Zmień hasło</Heading>
                </StyledHeading>
                <UstawHaslo />
              </Container>
            )}
          </Content>
        </AdminWrapperA>
      </AdminTemplate>
    );
  }
}

AdminUzytkownik.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
      kontrahent: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

AdminUzytkownik.defaultProps = {};

export default AdminUzytkownik;
