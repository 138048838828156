import React from 'react';
import styled from 'styled-components';
import lista from 'assets/svg/list.svg';

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7rem;
  z-index: 5;
  background-color: #fff;
`;

class FooterKierowca extends React.Component {
  state = {
    init: 0,
  };

  render() {
    const { init } = this.state;
    return (
      <Footer init={init}>
        {/* Służy do przekazywania prospa filtrującego po wszystkich kontrahentach */}
        <img src={lista} alt="Lista kontrahentów" />
      </Footer>
    );
  }
}

export default FooterKierowca;
