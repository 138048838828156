import React from 'react';
import PropTypes from 'prop-types';
import AdminTemplate from 'templates/AdminTemplate';
import ZamowieniaAdmin from 'components/molecules/ZamowieniaAdmin/ZamowieniaAdmin';
import pobierzZamowieniaAction from 'services/admin/pobierzZamowienia';
import { pobierzGodzinyAction } from 'services/admin/firmyServices';
import Kalendarz from 'components/molecules/Kalendarz/Kalendarz';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
// import godziny from 'datasource/godziny';
// import historia from 'datasource/historia';
import {
  SidebarHeader,
  DropdownWrapper,
  Cell,
  Content,
  Table,
  AdminWrapperA,
  ArrowContC,
  ArrowA,
  StyledTabListA,
} from './style';

const StyledButton = styled(Button)`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.25em;
  padding: 1.2rem 2rem;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease;
  width: 100%;
  text-align: left;

  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.3);
  }
  & + & {
    margin-top: 0.4rem;
  }
`;

class AdminZamowienia extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      navNone: false,
      godziny: [],
      aktualnaGodzina: 0,
      // whichOrder: 0,
    };
    // this.sayHello = this.sayHello.bind(this);
  }

  // state = {
  //   godziny: [],
  //   aktualnaGodzina: 0,
  //   // zamowienia: [], // historia,
  //   // wybraneZamowienia: [], // historia,
  //   navNone: false,
  // };

  componentDidMount() {
    this._isMounted = true;
    const { pobierzZamowienia } = this.props;
    pobierzZamowienia('dzisiaj');

    pobierzGodzinyAction().then(godziny => {
      if (this._isMounted) {
        this.setState({ godziny });
      }
    });
  }
  // Pewnie można zoptymalizować...

  componentDidUpdate(prevProps) {
    const { dataMenu } = this.props;
    if (prevProps.dataMenu !== dataMenu) {
      const { pobierzZamowienia } = this.props;
      pobierzZamowienia(
        `${dataMenu.getFullYear()}-${dataMenu.getMonth() + 1}-${dataMenu.getDate()}`,
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  resetujFiltry = () => {
    // const { zamowienia } = this.props;
    // this.setState({ wybraneZamowienia: zamowienia, wybranaFirma: 'Wszystkie zamówienia' });
  };

  onMenuItemClicked = () => {
    this.setState(prevState => ({ navNone: !prevState.navNone }));
  };

  onHandleState = e => {
    this.setState({ navNone: e });
  };

  filtruj(aktualnaGodzina) {
    this.setState({ aktualnaGodzina });
  }

  // sayHello() {
  //   alert('Hello!');
  // }

  render() {
    const { zamowienia, dataMenu } = this.props;
    const { godziny, aktualnaGodzina, navNone } = this.state;

    // console.log(zamowienia);
    // console.log(whichOrder);
    // console.log(whichClicked);
    let cc = 0;
    return (
      <AdminTemplate>
        <AdminWrapperA>
          <StyledTabListA
            style={{
              transition: '1s',
              left: navNone ? 0 : '-300px',
            }}
            responsive
          >
            <Kalendarz />

            <SidebarHeader onClick={() => this.filtruj(0)}>Wyjazdy</SidebarHeader>
            <DropdownWrapper>
              {godziny.map(item =>
                item.godzinaWyjazdu === aktualnaGodzina ? (
                  <StyledButton
                    active
                    key={item.id}
                    onClick={() => this.filtruj(item.godzinaWyjazdu)}
                  >
                    {item.godzinaWyjazdu}
                  </StyledButton>
                ) : (
                  <StyledButton key={item.id} onClick={() => this.filtruj(item.godzinaWyjazdu)}>
                    {item.godzinaWyjazdu}
                  </StyledButton>
                ),
              )}
            </DropdownWrapper>
          </StyledTabListA>
          <ArrowContC
            onClick={this.onMenuItemClicked}
            style={{
              transition: '1s',
              left: navNone ? '300px' : '0px',
            }}
          >
            <ArrowA
              style={{
                transition: '1s',
                transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
              }}
            />
          </ArrowContC>

          <Content>
            <Table scrolled view="zamowienia">
              <Cell head center>
                L.P.
              </Cell>
              <Cell head>Firma / godzina</Cell>
              <Cell head>Danie główne</Cell>
              <Cell head center>
                Cena
              </Cell>
              <Cell head center style={{ cursor: 'pointer' }}>
                Płatność
              </Cell>
              <Cell head center>
                Odebrano
              </Cell>
              {aktualnaGodzina
                ? zamowienia &&
                  zamowienia
                    .filter(item => item.godzinaWyjazdu === aktualnaGodzina)
                    .map(item => (
                      <ZamowieniaAdmin
                        // eslint-disable-next-line no-plusplus
                        key={item.id && cc++}
                        index={zamowienia.indexOf(item) + 1}
                        {...item}

                        // onClick={() => console.log('Sadasd')}
                      />
                    ))
                : zamowienia &&
                  zamowienia.map(item => (
                    <ZamowieniaAdmin
                      // eslint-disable-next-line no-plusplus
                      key={cc++}
                      index={zamowienia.indexOf(item) + 1}
                      {...item}
                      // onClick={() => console.log('Sadasdxxx')}
                    />
                  ))}
            </Table>
            {zamowienia.length === 0 && (
              <p style={{ textAlign: 'center' }}>W dniu {dataMenu.toString()} nie ma zamówień.</p>
            )}
          </Content>
        </AdminWrapperA>
      </AdminTemplate>
    );
  }
}

AdminZamowienia.propTypes = {
  pobierzZamowienia: PropTypes.func.isRequired,
  dataMenu: PropTypes.instanceOf(Date),
  zamowienia: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  whichClicked: PropTypes.number,
};

AdminZamowienia.defaultProps = {
  dataMenu: new Date(),
  zamowienia: [],
  whichClicked: 0,
};
const mapStateToProps = state => {
  return {
    // error: state.zamowieniaReducer.error,
    dataMenu: state.filterReducer.dataMenu,
    zamowienia: state.zamowieniaReducer.zamowienia,
    // pendingPobierz: state.zamowieniaReducer.pendingWyslij,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzZamowienia: pobierzZamowieniaAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminZamowienia);
