import {
  FETCH_ARCHIWUM_ETYKIET_PENDING,
  FETCH_ARCHIWUM_ETYKIET_ERROR,
  FETCH_ARCHIWUM_ETYKIET_SUCCESS,
} from 'actions/archiwumActions';

const initialState = {
  pending: false,
  error: null,
  etykiety: [],
};

const archiwumReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARCHIWUM_ETYKIET_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ARCHIWUM_ETYKIET_ERROR:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case FETCH_ARCHIWUM_ETYKIET_SUCCESS:
      return {
        ...state,
        pending: false,
        etykiety: action.data,
      };
    default:
      return state;
  }
};

export default archiwumReducer;
