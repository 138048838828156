import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Heading from 'components/atoms/Heading/Heading';
import { useParams } from 'react-router-dom';
import Kalendarz from 'components/molecules/Kalendarz/Kalendarz';
import { connect } from 'react-redux';
import { fetchDriverDetails } from 'services/admin/usersServices';
import {
  przypiszZamowienie,
  pobierzListaKierowca,
  pobierzKierowcyZamowienia,
  usunZamowienieKierowca,
  zapiszKolejnoscKierowca,
  //  pobierzZamowienieDrag,
} from 'services/admin/kierowcyService';
import { bindActionCreators } from 'redux';

import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { DriverContext } from 'driverContext';
import zupa from 'assets/svg/zupa.svg';
import salatka from 'assets/svg/salatka.svg';
import danie from 'assets/svg/daniednia.svg';
import NapojeZimne from 'assets/svg/napoje_zimne.svg';
import Desser from 'assets/svg/Desser2.svg';
import Box from './Box';
import Dustbin from './Dustbin';

import './DriverPanel.scss';

const StyledWrapper = styled.div`
  height: 100vh;
  padding: 10rem 5rem;

  @media screen and (min-width: 1365px) {
    margin-left: 310px;
  }
`;
const StyledCalendar = styled.div`
  width: 100%;
  grid-column: 2;
  grid-row: 1;
`;

const StyledPanel = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
  gap: 2rem;
`;
const StyledTab = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;
const StyledTabContainer = styled.div`
  width: 100%;
  grid-column: ${({ position }) => position || 1};
`;
const StyledRevenues = styled.div`
  width: 100%;
`;
// eslint-disable-next-line no-unused-vars
const DriverPanel = ({
  dataMenu,
  zamKier,
  driverList,
  pobierzKierowcyZamowieniaService,
  pobierzListaKierowcaService,
  usunZamowienieKierowcaService,
  zapiszKolejnoscKierowcaService,
}) => {
  const [driver, setDriver] = useState(null);
  const [prevId, setPrevId] = useState(0);
  const [prevData, setPrevData] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [dragData, setDragData] = useState([]);

  const { id } = useParams();

  const { whichPage } = useContext(DriverContext);

  useEffect(() => {
    if (id !== prevId || (dataMenu && dataMenu !== prevData)) {
      setPrevId(id);
      setPrevData(dataMenu);
      fetchDriverDetails({ id, dataMenu }).then(res => setDriver(res.data));
    }
  });

  useEffect(() => {
    pobierzListaKierowcaService();
  }, []);

  useEffect(() => {
    pobierzKierowcyZamowieniaService();
  }, []);

  let count = 0;

  return (
    <>
      {whichPage === 1 && (
        <>
          <StyledWrapper>
            <div className="wyjazdyBigCont">
              <div className="wyjazdyLeft">
                <div className="firmyCont">
                  {zamKier.length !== 0 &&
                    zamKier.map(el => (
                      <Box
                        // eslint-disable-next-line no-plusplus
                        key={`${el.firmaId}_${count++}`}
                        id={el.firmaId}
                        name={el.firma}
                        firmaId={el.firmaId}
                        oddzialId={el.oddzialId}
                        menuId={el.menuId}
                        godzinaWyjazduId={el.godzinaWyjazduId}
                        oddzialNazwa={el.oddzialNazwa}
                        godzinaWyjazdu={el.godzinaWyjazdu}
                        daniaZupy={el.dania.map(ele => ele.kategoria === 'zupa' && ele.ilosc)}
                        daniaObiad={el.dania.map(ele => ele.kategoria === 'danie' && ele.ilosc)}
                        daniaSmoothie={el.dania.map(
                          ele => ele.kategoria === 'smoothie' && ele.ilosc,
                        )}
                        daniaSalatki={el.dania.map(ele => ele.kategoria === 'salatka' && ele.ilosc)}
                        daniaDesery={el.dania.map(ele => ele.kategoria === 'deser' && ele.ilosc)}
                      />
                    ))}
                </div>
              </div>

              <div className="wyjazdyRight">
                {driverList.map((godziny, index) => (
                  <div key={index}>
                    <div className="wyjazdCont">
                      <h2>{godziny.godzinaWyjadzu}</h2>
                    </div>
                    <div>
                      <div className="rowStyle">
                        {godziny.kierowcy.map(el => (
                          <>
                            <div key={`${index + 1}_${count}`} className="dustbinCont">
                              <h3>{el.kierowca}</h3>
                              <Dustbin
                                allowedDropEffect="any"
                                zamowienia={el.zamowienia}
                                driver={el.kierowcaId}
                                wyjazd={godziny.godzinaWyjadzuId}
                                usunZamClick={usunZamowienieKierowcaService}
                                zapiszKolejnosc={zapiszKolejnoscKierowcaService}
                              />

                              <div className="ikonki">
                                <div style={{ display: 'flex' }}>
                                  <p>
                                    {el.zamowienia
                                      .map(z =>
                                        z.dania
                                          .map(ele =>
                                            ele.kategoria === 'zupa' && ele.ilosc ? ele.ilosc : 0,
                                          )
                                          .reduce((partialSum, a) => partialSum + a, 0),
                                      )
                                      .reduce((partialSum, a) => partialSum + a, 0)}
                                  </p>{' '}
                                  <img className="iko" src={zupa} alt="zupa" />{' '}
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <p>
                                    {el.zamowienia
                                      .map(z =>
                                        z.dania
                                          .map(ele =>
                                            ele.kategoria === 'danie' && ele.ilosc ? ele.ilosc : 0,
                                          )
                                          .reduce((partialSum, a) => partialSum + a, 0),
                                      )
                                      .reduce((partialSum, a) => partialSum + a, 0)}
                                  </p>{' '}
                                  <img className="iko" src={danie} alt="danie" />
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <p>
                                    {el.zamowienia
                                      .map(z =>
                                        z.dania
                                          .map(ele =>
                                            ele.kategoria === 'salatka' && ele.ilosc
                                              ? ele.ilosc
                                              : 0,
                                          )
                                          .reduce((partialSum, a) => partialSum + a, 0),
                                      )
                                      .reduce((partialSum, a) => partialSum + a, 0)}
                                  </p>{' '}
                                  <img className="iko" src={salatka} alt="salatka" />{' '}
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <p>
                                    {el.zamowienia
                                      .map(z =>
                                        z.dania
                                          .map(ele =>
                                            ele.kategoria === 'deser' && ele.ilosc ? ele.ilosc : 0,
                                          )
                                          .reduce((partialSum, a) => partialSum + a, 0),
                                      )
                                      .reduce((partialSum, a) => partialSum + a, 0)}
                                  </p>{' '}
                                  <img className="iko" src={Desser} alt="Deser" />
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <p>
                                    {el.zamowienia
                                      .map(z =>
                                        z.dania
                                          .map(ele =>
                                            ele.kategoria === 'smoothie' && ele.ilosc
                                              ? ele.ilosc
                                              : 0,
                                          )
                                          .reduce((partialSum, a) => partialSum + a, 0),
                                      )
                                      .reduce((partialSum, a) => partialSum + a, 0)}
                                  </p>{' '}
                                  <img className="ikoSmoo" src={NapojeZimne} alt="napoje zimne" />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </StyledWrapper>
        </>
      )}
      {whichPage === 2 && (
        <StyledWrapper>
          {driver ? (
            <>
              <Heading rank={1}>
                {driver.imie} {driver.nazwisko}
              </Heading>
              <StyledPanel>
                <StyledTabContainer>
                  {/* {console.log(driver.godziny)}
                  {driver.godziny.map(
                    godzina =>
                      console.log(godzina) && (
                        <StyledTab>
                          <Heading rank={2}>{godzina.godzinaWyjazdu}</Heading>
                          <StyledRevenues>
                            <Table bordered size="xl">
                              <thead>
                                <tr>
                                  <th>Gotówka</th>
                                  <th>Karta</th>
                                  <th>Faktura</th>
                                  <th>Łącznie</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{godzina.gotowka || '0'} zł</td>
                                  <td>{godzina.karta || '0'} zł</td>
                                  <td>{godzina.faktura || '0'} zł</td>
                                  <td>{godzina.lacznie || '0'} zł</td>
                                </tr>
                              </tbody>
                            </Table>
                          </StyledRevenues>
                        </StyledTab>
                      ),
                  )} */}
                  <StyledTab>
                    <Heading rank={2}>10:30</Heading>
                    <StyledRevenues>
                      <Table bordered size="xl">
                        <thead>
                          <tr>
                            <th>Gotówka</th>
                            <th>Karta</th>
                            <th>Faktura</th>
                            <th>Łącznie</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{driver.godziny[0].gotowka || '0'} zł</td>
                            <td>{driver.godziny[0].karta || '0'} zł</td>
                            <td>{driver.godziny[0].faktura || '0'} zł</td>
                            <td>{driver.godziny[0].lacznie || '0'} zł</td>
                          </tr>
                        </tbody>
                      </Table>
                    </StyledRevenues>
                  </StyledTab>
                  <StyledTab>
                    <Heading rank={2}>11:15</Heading>
                    <StyledRevenues>
                      <Table bordered size="xl">
                        <thead>
                          <tr>
                            <th>Gotówka</th>
                            <th>Karta</th>
                            <th>Faktura</th>
                            <th>Łącznie</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{driver.godziny[1].gotowka || '0'} zł</td>
                            <td>{driver.godziny[1].karta || '0'} zł</td>
                            <td>{driver.godziny[1].faktura || '0'} zł</td>
                            <td>{driver.godziny[1].lacznie || '0'} zł</td>
                          </tr>
                        </tbody>
                      </Table>
                    </StyledRevenues>
                  </StyledTab>
                  <StyledTab>
                    <Heading rank={2}>12:45</Heading>
                    <StyledRevenues>
                      <Table bordered size="xl">
                        <thead>
                          <tr>
                            <th>Gotówka</th>
                            <th>Karta</th>
                            <th>Faktura</th>
                            <th>Łącznie</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{driver.godziny[2].gotowka || '0'} zł</td>
                            <td>{driver.godziny[2].karta || '0'} zł</td>
                            <td>{driver.godziny[2].faktura || '0'} zł</td>
                            <td>{driver.godziny[2].lacznie || '0'} zł</td>
                          </tr>
                        </tbody>
                      </Table>
                    </StyledRevenues>
                  </StyledTab>
                  <StyledTab>
                    <Heading rank={2}>Suma:</Heading>
                    <StyledRevenues>
                      <Table bordered size="xl">
                        <thead>
                          <tr>
                            <th>Gotówka</th>
                            <th>Karta</th>
                            <th>Faktura</th>
                            <th>Łącznie</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {driver.godziny[0].gotowka +
                                driver.godziny[1].gotowka +
                                driver.godziny[2].gotowka || '0'}{' '}
                              zł
                            </td>
                            <td>
                              {driver.godziny[0].karta +
                                driver.godziny[1].karta +
                                driver.godziny[2].karta || '0'}{' '}
                              zł
                            </td>
                            <td>
                              {driver.godziny[0].faktura +
                                driver.godziny[1].faktura +
                                driver.godziny[2].faktura || '0'}{' '}
                              zł
                            </td>
                            <td>
                              {driver.godziny[0].lacznie +
                                driver.godziny[1].lacznie +
                                driver.godziny[2].lacznie || '0'}{' '}
                              zł
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </StyledRevenues>
                  </StyledTab>
                </StyledTabContainer>
                <StyledCalendar>
                  <Kalendarz />
                </StyledCalendar>
              </StyledPanel>
            </>
          ) : (
            <Heading rank={1}>Wybierz kierowcę</Heading>
          )}
        </StyledWrapper>
      )}
    </>
  );
};

DriverPanel.propTypes = {
  dataMenu: PropTypes.string,
  zamKier: PropTypes.arrayOf(PropTypes.shape({})),
  driverList: PropTypes.arrayOf(PropTypes.shape({})),
  pobierzListaKierowcaService: PropTypes.func.isRequired,
  pobierzKierowcyZamowieniaService: PropTypes.func.isRequired,
  usunZamowienieKierowcaService: PropTypes.func.isRequired,
  zapiszKolejnoscKierowcaService: PropTypes.func.isRequired,
};

DriverPanel.defaultProps = {
  dataMenu: null,
  zamKier: [],
  driverList: [],
};

const mapStateToProps = state => ({
  zamKier: state.adminKierowcaReducer.zamowienia,
  driverList: state.adminKierowcaReducer.kierowcy,
  dataMenu: state.filterReducer.dataMenu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzKierowcyZamowieniaService: pobierzKierowcyZamowienia,
      pobierzListaKierowcaService: pobierzListaKierowca,
      przypiszZamowienieService: przypiszZamowienie,

      usunZamowienieKierowcaService: usunZamowienieKierowca,
      zapiszKolejnoscKierowcaService: zapiszKolejnoscKierowca,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DriverPanel);
