import React from 'react';
import PropTypes from 'prop-types';
import DaniaAdminMenu from 'components/molecules/DaniaAdminMenu/DaniaAdminMenu';
import Header from 'components/organisms/HeaderAdmin/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pobierzWszytkieDaniaAction, pobierzDaniaDoMenuAction } from 'services/admin/daniaServices';
import { pobierzZamowieniaAction } from 'services/kuchniaServices';
import {
  dodajDoMenu,
  pobierzDodatkiDanAction,
  pobierzDodatkiDan2Action,
  zapiszKomentarzMenu,
} from 'services/admin/menuServices';
import Kalendarz from 'components/molecules/Kalendarz/Kalendarz';
import Obraz from 'components/atoms/Obraz/Obraz';
import plus from 'assets/svg/plusik.svg';
import 'react-datepicker/dist/react-datepicker.css';
import AdminTemplate from 'templates/AdminTemplate';

import {
  Table,
  AdminWrapperA,
  StyledTabListA,
  ArrowContC,
  ArrowA,
  Content,
  Filtry,
  Cell,
  Input,
  StyledSelect,
  AddNew,
  StyledSelectWrapper,
} from 'views/Admin/style';

class AdminMenu extends React.Component {
  _isMounted = false;

  state = {
    wybraneDanie: 0,
    komentarz: '',
    navNone: false,
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      pobierzWszystkieDania,
      pobierzDaniaDoMenu,
      pobierzDodatkiDan,
      pobierzDodatkiDan2,
      pobierzZamowienia,
    } = this.props;

    pobierzDaniaDoMenu().then(() => {
      pobierzWszystkieDania(true).then(dania => {
        this.updateSelectOptions(dania.dania);
      });
    });
    pobierzDodatkiDan();
    pobierzDodatkiDan2();
    pobierzZamowienia();
  }

  // Pewnie można zoptymalizować...
  componentDidUpdate(prevProps, prevState) {
    const { dania, menu, dataMenu } = this.props;

    if (prevProps.dataMenu !== dataMenu) {
      const { pobierzWszystkieDania, pobierzDaniaDoMenu } = this.props;
      pobierzDaniaDoMenu(
        `${dataMenu.getFullYear()}-${dataMenu.getMonth() + 1}-${dataMenu.getDate()}`,
      ).then(() => {
        pobierzWszystkieDania().then(wszystkieDania => {
          this.updateSelectOptions(wszystkieDania.dania);
        });
      });
    }

    if (prevProps.menu.komentarz !== menu.komentarz) {
      this.updateKomentarz(menu.komentarz);
    }

    if (prevState.menu && prevState.menu.timestamp && prevState.menu.timestamp < menu.timestamp) {
      this.updateSelectOptions(dania);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleBlur() {
    const { menu } = this.props;
    const { komentarz, changed } = this.state;
    if (changed) {
      zapiszKomentarzMenu(komentarz, menu.id);
    }
  }

  handleKomentarz(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      changed: true,
    }));
  }

  onMenuItemClicked = () => {
    this.setState(prevState => ({ navNone: !prevState.navNone }));
  };

  updateSelectOptions(wszystkieDania) {
    const { daniaDoMenu } = this.props;
    const daniaDoMenuOptions = wszystkieDania.map((danie, index) => {
      let dis = false;
      if (daniaDoMenu.length) {
        dis = daniaDoMenu.find(element => element.id === danie.id);
      }
      return {
        label: `${danie.nazwa} (${danie.kodDania})`,
        value: danie.id,
        isDisabled: dis,
        key: index,
      };
    });
    if (this._isMounted) {
      this.setState({ daniaDoMenuOptions });
    }
  }

  dodajDanie() {
    const { wybraneDanie } = this.state;
    const { menu, dodajDoMenuService } = this.props;
    dodajDoMenuService(menu.id, wybraneDanie);
  }

  wybraneDanie(event) {
    this.setState({ wybraneDanie: event.value });
  }

  updateKomentarz(komentarz) {
    this.setState({ komentarz });
  }

  render() {
    const { daniaDoMenuOptions, komentarz, navNone } = this.state;
    const { daniaDoMenu, menu } = this.props;

    return (
      <AdminTemplate>
        <Header />
        <AdminWrapperA>
          <StyledTabListA
            style={{
              transition: '1s',
              left: navNone ? 0 : '-300px',
            }}
            responsive
          >
            <Kalendarz />
          </StyledTabListA>
          <ArrowContC
            onClick={this.onMenuItemClicked}
            style={{
              transition: '1s',
              left: navNone ? '300px' : '0px',
            }}
          >
            <ArrowA
              style={{
                transition: '1s',
                transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
              }}
            />
          </ArrowContC>
          <Content>
            {menu && (
              <Filtry>
                {menu.dzienMenu}, {menu.dataMenu}
                <div>
                  <Input
                    type="text"
                    value={komentarz}
                    name="komentarz"
                    placeholder="Komentarz / komunikat do menu"
                    onChange={e => this.handleKomentarz(e)}
                    onBlur={e => this.handleBlur(e)}
                  />
                </div>
              </Filtry>
            )}
            <StyledSelectWrapper>
              <StyledSelect options={daniaDoMenuOptions} onChange={e => this.wybraneDanie(e)} />

              <AddNew onClick={e => this.dodajDanie(e)}>
                <Obraz src={plus} alt="Dodaj danie" />
              </AddNew>
            </StyledSelectWrapper>
            <Table scrolled view="menu">
              <Cell center head>
                L.P.
              </Cell>
              <Cell head>Rodzaj</Cell>
              <Cell head>Nazwa</Cell>
              <Cell head>Opis</Cell>
              <Cell head>Dodatek Skorbiowy</Cell>
              <Cell head>Dodatek Warzywna</Cell>
              <Cell head>Wege</Cell>

              <Cell center head>
                Kategoria
              </Cell>
              <Cell center head>
                Cena
              </Cell>
              <Cell center head>
                Dostępna ilość
              </Cell>
              <Cell center head>
                Usuń
              </Cell>

              {daniaDoMenu.map(item => (
                <DaniaAdminMenu
                  key={`${item.danieId}_${menu.id}_${daniaDoMenu.indexOf(item) + 1}`}
                  index={daniaDoMenu.indexOf(item) + 1}
                  menuId={menu.id}
                  {...item}
                />
              ))}
            </Table>
          </Content>
        </AdminWrapperA>
      </AdminTemplate>
    );
  }
}

AdminMenu.propTypes = {
  dataMenu: PropTypes.instanceOf(Date),
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  daniaDoMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  dodatkiDan: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  menu: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dataMenu: PropTypes.string.isRequired,
    dzienMenu: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    komentarz: PropTypes.string,
  }),
  // pendingPobierz: PropTypes.bool,
  error: PropTypes.string,
  pobierzDaniaDoMenu: PropTypes.func,
  pobierzWszystkieDania: PropTypes.func,
  dodajDoMenuService: PropTypes.func,
  pobierzDodatkiDan: PropTypes.func,
  pobierzDodatkiDan2: PropTypes.func,
  pobierzZamowienia: PropTypes.func,
};

AdminMenu.defaultProps = {
  dataMenu: new Date(),
  dania: [],
  daniaDoMenu: [],
  dodatkiDan: [],
  menu: {
    id: 0,
    dataMenu: '',
    dzienMenu: '',
    timestamp: 0,
    komentarz: '',
  },
  // pendingPobierz: false,
  error: '',
  pobierzDaniaDoMenu: null,
  pobierzWszystkieDania: null,
  dodajDoMenuService: null,
  pobierzDodatkiDan: null,
  pobierzDodatkiDan2: null,
  pobierzZamowienia: null,
};

const mapStateToProps = state => ({
  // error: state.zamowieniaReducer.error,
  dataMenu: state.filterReducer.dataMenu,
  dania: state.daniaReducer.dania,
  daniaDoMenu: state.daniaReducer.daniaDoMenu,
  menu: state.daniaReducer.menu,
  dodatkiDan: state.daniaReducer.dodatkiDan,
  // pendingPobierz: state.zamowieniaReducer.pendingWyslij,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzDaniaDoMenu: pobierzDaniaDoMenuAction,
      pobierzWszystkieDania: pobierzWszytkieDaniaAction,
      pobierzDodatkiDan: pobierzDodatkiDanAction,
      pobierzDodatkiDan2: pobierzDodatkiDan2Action,
      dodajDoMenuService: dodajDoMenu,
      pobierzZamowienia: pobierzZamowieniaAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
