/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import creditCard from 'assets/svg/credit_card.svg';

import cash from 'assets/svg/cash.svg';
import delete2 from 'assets/svg/delete.svg';
import paymentBlack from 'assets/svg/paymentBlack.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import pobierzZamowieniaAction from 'services/admin/pobierzZamowienia';

import Confirm from 'components/molecules/Confirm/Confirm';
import { usunZamowienie } from 'services/admin/daniaServices';

// import { propTypes } from 'react-bootstrap/esm/Image';
import styles from './ZamowieniaAdmin.module.scss';
// import ZamowienieSingle from '../ZamowienieSingle/ZamowienieSingle';

const ZamowieniaAdmin = ({
  index,
  firma,
  imie,
  nazwisko,
  godzinaWyjazdu,
  status,
  dania,
  wartosc,
  odebrano,
  formaPlatnosci,
  dataMenu,
  // zamowienia,
  usunZamowienieService,
  id,
  klasa = status === 1 ? 'Tak' : 'Nie',
}) => {
  const getPaymentType = () => {
    switch (formaPlatnosci) {
      case 'faktura':
        return <img width="20" src={paymentBlack} alt="" />;
      case 'gotowka':
        return <img width="20" src={cash} alt="" />;
      default:
        return <img width="20" src={creditCard} alt="" />;
    }
  };

  // console.log(id);

  // const [whichClicked, setWhichClicked] = useState();
  const [showConfirm, setShowConfirm] = useState();

  // eslint-disable-next-line consistent-return
  // useEffect(() => {
  //   if (whichClicked === index) {
  //     const timer = setTimeout(() => {
  //       zamowienia.splice(whichClicked - 1, 1);
  //     }, 500);
  //     return () => clearTimeout(timer);
  //   }
  // }, [whichClicked, index, zamowienia]);

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  function handleUsunZamowienie(x) {
    toggleConfirm();
    usunZamowienieService(x, dataMenu);
  }

  return (
    <>
      <div className={styles.cellCenter}>{index}.</div>
      <div className={styles.cell}>
        {firma} ({godzinaWyjazdu}) <br />
        {imie} {nazwisko}
      </div>
      <div className={styles.cell}>
        {dania.map(item => (
          <span key={item.id}>
            {dania.indexOf(item) + 1}. {item.nazwa}
          </span>
        ))}
      </div>
      <div className={styles.cellCenter}>{wartosc} zł</div>
      <div className={styles.cellCenter}>{getPaymentType()}</div>
      <div className={styles.cellCenter}>
        {odebrano ? (
          <div>
            <span className={cx(styles.zrealizowano, styles[klasa])} />
          </div>
        ) : (
          <div>
            <span className={cx(styles.zrealizowano, styles.Nie, styles[klasa])} />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            {/* <img
              src={delete2}
              alt="garbage"
              style={{ width: '2rem', marginTop: '2rem', cursor: 'pointer' }}
              onClick={() => {
                setWhichClicked(index);
              }}
            /> */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className={styles.koszyk}
              src={delete2}
              onClick={() => toggleConfirm()}
              alt="Usuń danie"
              style={{ width: '2rem', cursor: 'pointer', marginTop: '2rem' }}
            />

            <Confirm
              show={showConfirm}
              text="czy na pewno chcesz usunąć zamówienie?"
              toggle={() => toggleConfirm()}
              usun={() => handleUsunZamowienie(id)}
            />

            {/* // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            {/* <button type="button" onClick={() => setWhichClicked('sad')}>
              {' '}
              sadasdsa
            </button> */}
          </div>
        )}
      </div>
    </>
  );
};

ZamowieniaAdmin.propTypes = {
  firma: PropTypes.string,
  id: PropTypes.number.isRequired,
  imie: PropTypes.string,
  nazwisko: PropTypes.string,
  godzinaWyjazdu: PropTypes.string,
  index: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      nazwa: PropTypes.string.isRequired,
    }),
  ).isRequired,
  wartosc: PropTypes.number.isRequired,
  klasa: PropTypes.string,
  odebrano: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  formaPlatnosci: PropTypes.string,
  dataMenu: PropTypes.instanceOf(Date),
  // zamowienia: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.number.isRequired,
  //   }),
  // ),
  usunZamowienieService: PropTypes.func.isRequired,
  // whichClicked: PropTypes.number,
};

ZamowieniaAdmin.defaultProps = {
  firma: 'Firma',
  imie: 'Imię',
  klasa: '',
  nazwisko: 'Nazwisko',
  godzinaWyjazdu: '',
  odebrano: null,
  formaPlatnosci: '',
  dataMenu: new Date(),
  // zamowienia: [],
  // whichClicked: 0,
};

const mapStateToProps = state => {
  return {
    // error: state.zamowieniaReducer.error,
    dataMenu: state.filterReducer.dataMenu,
    zamowienia: state.zamowieniaReducer.zamowienia,
    // pendingPobierz: state.zamowieniaReducer.pendingWyslij,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzZamowienia: pobierzZamowieniaAction,
      usunZamowienieService: usunZamowienie,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ZamowieniaAdmin);
