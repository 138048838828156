export const theme = {
  black: '#0b0b0b',
  active: '#6d5cce',
  disabled: '#707070',
  valid: '#80CF26',
  green: '#23BF39',
  fSize: {
    h1: '6rem',
    h2: '2.4rem',
    h3: '2rem',
  },
};
