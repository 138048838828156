import React from 'react';
import { DragSource } from 'react-dnd';
import zupa from 'assets/svg/zupa.svg';
import danie from 'assets/svg/daniednia.svg';
import salatka from 'assets/svg/salatka.svg';
import NapojeZimne from 'assets/svg/napoje_zimne.svg';
import Desser from 'assets/svg/Desser2.svg';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  przypiszZamowienie,
  pobierzListaKierowca,
  pobierzKierowcyZamowienia,
} from 'services/admin/kierowcyService';

export const ItemTypes = {
  BOX: 'box',
};

const Box = ({
  name,
  oddzialNazwa,
  godzinaWyjazdu,
  isDragging,
  connectDragSource,
  daniaZupy,
  daniaObiad,
  daniaSmoothie,
  daniaDesery,
  daniaSalatki,
}) => {
  const opacity = isDragging ? 0.4 : 1;

  return connectDragSource(
    <div className="firmaBrick" style={{ opacity, cursor: 'grab' }}>
      <h2>{name}</h2>
      <h4>{oddzialNazwa}</h4>
      <h4>{godzinaWyjazdu}</h4>

      <div className="ikonki">
        <div style={{ display: daniaZupy.every(el => el === false) ? 'none' : 'flex' }}>
          <p>{daniaZupy}</p> <img className="iko" src={zupa} alt="zupa" />{' '}
        </div>
        <div style={{ display: daniaObiad.every(el => el === false) ? 'none' : 'flex' }}>
          <p>{daniaObiad}</p> <img className="iko" src={danie} alt="danie" />
        </div>
        <div style={{ display: daniaSalatki.every(el => el === false) ? 'none' : 'flex' }}>
          <p>{daniaSalatki}</p> <img className="iko" src={salatka} alt="salatka" />{' '}
        </div>
        <div style={{ display: daniaDesery.every(el => el === false) ? 'none' : 'flex' }}>
          <p> {daniaDesery}</p> <img className="iko" src={Desser} alt="Deser" />
        </div>
        <div style={{ display: daniaSmoothie.every(el => el === false) ? 'none' : 'flex' }}>
          <p> {daniaSmoothie}</p> <img className="ikoSmoo" src={NapojeZimne} alt="napoje zimne" />
        </div>
      </div>
    </div>,
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzKierowcyZamowieniaService: pobierzKierowcyZamowienia,
      pobierzListaKierowcaService: pobierzListaKierowca,
      przypiszZamowienieService: przypiszZamowienie,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(
  DragSource(
    ItemTypes.BOX,
    {
      beginDrag: props => ({ name: props.name, id: props.id }),
      endDrag(props, monitor) {
        const dropResult = monitor.getDropResult();

        if (dropResult) {
          const isDropAllowed =
            dropResult.allowedDropEffect === 'any' ||
            dropResult.allowedDropEffect === dropResult.dropEffect;

          if (isDropAllowed) {
            props.przypiszZamowienieService({
              zamowienie: {
                firmaId: props.firmaId,
                oddzialId: props.oddzialId,
                menuId: props.menuId,
                godzinaWyjazduId: props.godzinaWyjazduId,
              },

              wyjazd: {
                kierowca: dropResult.driver,
                wyjazd: dropResult.wyjazd,
              },
            });
          }
        }
      },
    },
    (connecte, monitor) => ({
      connectDragSource: connecte.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Box),
);
