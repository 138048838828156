import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme/adminTheme';

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  @media (max-width: ${theme.size.tablet}) {
    overflow: scroll;
  }
`;

const Li = styled.li`
  & + & {
    margin-left: 4rem;
  }
`;

const NavLinkStyled = styled(NavLink)`
  font-size: 1.6rem;
  line-height: 1.2em;
  color: #0b0b0b;
  font-weight: 600;
  padding: 0.4rem 0.7rem;
  display: flex;
  transition: all 0.3s ease;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    content: '';
    background-color: #0b0b0b;
    height: 2px;
    max-width: 0;
    transition: all 0.3s ease;
  }
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #0b0b0b;
    &:before {
      max-width: 100%;
    }
  }
  &.${props => props.activeClassName} {
    &:before {
      max-width: 100%;
    }
  }
`;

const NawigacjaAdmin = () => (
  <nav>
    <Ul>
      <Li>
        <NavLinkStyled exact to="/admin/dzien">
          Pulpit
        </NavLinkStyled>
      </Li>
      <Li>
        <NavLinkStyled to="/zamowienia">Zamówienia</NavLinkStyled>
      </Li>
      <Li>
        <NavLinkStyled to="/kontrahenci">Kontrahenci</NavLinkStyled>
      </Li>
      <Li>
        <NavLinkStyled to="/dania">Dania</NavLinkStyled>
      </Li>
      <Li>
        <NavLinkStyled to="/menu">Menu</NavLinkStyled>
      </Li>
      <Li>
        <NavLinkStyled to="/users">Użytkownicy</NavLinkStyled>
      </Li>
      <Li>
        <NavLinkStyled to="/driver">Kierowcy</NavLinkStyled>
      </Li>
    </Ul>
  </nav>
);

NavLinkStyled.defaultProps = {
  activeClassName: 'active',
};

export default NawigacjaAdmin;
