import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import Heading from 'components/atoms/Heading/Heading';
import P from 'components/atoms/Paragraph/Paragraph';
import Obraz from 'components/atoms/Obraz/Obraz';

import obrazkiDan from 'components/atoms/Obraz/ObrazkiDan';
import obrazkiBadge from 'components/atoms/Obraz/ObrazkiBadge';

const Wrapper = styled(Col)`
  margin-bottom: 16px;
`;

const Ikony = styled.div`
  height: 14rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Badge = styled.div`
  position: absolute;
  right: 16px;
  top: -15px;
  width: 66px;
  z-index: 1;
  img {
    width: 100%;
  }
`;

const Item = styled.div`
  border: 4px solid #0b0b0b;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: ${({ theme }) => theme.white};
  @media screen and (min-width: 1600px) {
    padding: 2.8rem;
  }
`;

const H4 = styled(Heading)`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.1em;
  margin-bottom: 1em;
  text-transform: uppercase;
`;

const ButtonGroup = styled.div`
  margin-top: auto;
  display: flex;
  width: 100%;
`;

class DaniaItem extends React.Component {
  state = {
    counter: 0,
  };

  // clearCount = () => {
  //   this.setState({ counter: 0 });
  // };

  handleClick = e => {
    const st = this.state;
    const pro = this.props;
    if (e.target.value === '+') {
      this.setState({ counter: st.counter + 1 });
      pro.onClickPlus(pro, st.counter);
    } else {
      this.setState({ counter: st.counter - 1 });
      pro.onClickMinus(pro, st.counter);
    }
  };

  render() {
    const { counter } = this.state;
    const {
      typWMenu,
      rodzaj,
      nazwa,
      cena,
      opis,
      danieDniaStatus,
      dodatek,
      dodatek2,
      nazwaKategorii,
    } = this.props;

    return (
      <Wrapper xl={2} lg={3} md={4} sm={6}>
        <Item>
          {obrazkiBadge[typWMenu] && danieDniaStatus > 0 && (
            <Badge>
              <Obraz src={obrazkiBadge[typWMenu]} alt="Rodzaj dania w menu" />
            </Badge>
          )}
          <Ikony>
            <Obraz
              src={obrazkiDan[nazwaKategorii] ? obrazkiDan[nazwaKategorii] : obrazkiDan[rodzaj]}
              alt={nazwa}
              w={obrazkiDan[nazwaKategorii] === obrazkiDan['Napoje Zimne'] ? '55px' : '100px'}
            />
          </Ikony>
          <H4 rank={4}>{nazwa}</H4>
          {dodatek && <p>{dodatek}</p>}
          {dodatek2 && <p>{dodatek2}</p>}
          <P>{opis}</P>
          <ButtonGroup>
            <Button
              nopadding
              width="25%"
              minus
              value="-"
              onClick={this.handleClick}
              hidden={counter === 0}
              active={counter !== 0}
            >
              -
            </Button>
            <Button nopadding grow margin="0 1%" active={counter !== 0}>
              {counter !== 0 ? counter : cena}
              {counter !== 0 ? '' : ' zł'}
            </Button>
            <Button
              active={counter !== 0}
              nopadding
              width="25%"
              plus
              onClick={this.handleClick}
              value="+"
            >
              +
            </Button>
          </ButtonGroup>
        </Item>
      </Wrapper>
    );
  }
}

DaniaItem.propTypes = {
  nazwa: PropTypes.string,
  opis: PropTypes.string,
  dodatek: PropTypes.string,
  dodatek2: PropTypes.string,
  cena: PropTypes.number,
  rodzaj: PropTypes.string,
  typWMenu: PropTypes.string,
  danieDniaStatus: PropTypes.number,
  onClickPlus: PropTypes.func,
  onClickMinus: PropTypes.func,
  nazwaKategorii: PropTypes.string,
};

DaniaItem.defaultProps = {
  nazwa: 'Nazwa dania',
  opis: 'Opis dania',
  cena: 0,
  dodatek: '',
  dodatek2: '',
  rodzaj: '',
  nazwaKategorii: '',
  typWMenu: '',
  danieDniaStatus: 0,
  onClickPlus: {},
  onClickMinus: {},
};

export default DaniaItem;
