import axios from 'axios';
import {
  fetchUsersError,
  fetchUsersPending,
  fetchUsersSuccess,
  fetchUserError,
  fetchUserPending,
  fetchUserSuccess,
  setUserError,
  setUserPending,
  setUserSuccess,
} from 'actions/userActions';

export const fetchUsers = (type = 'all') => dispatch => {
  dispatch(fetchUsersPending());
  return axios
    .get(`/api/admin/users/type/${type}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(fetchUsersSuccess({ users: res.data }));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchUsersError(error));
    });
};

export const fetchUser = id => dispatch => {
  dispatch(fetchUserPending());
  return axios
    .get(`/api/admin/users/${id}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(fetchUserSuccess({ user: res.data }));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchUserError(error));
    });
};

export const fetchDriver = () => {
  return axios
    .get(`/api/admin/driver`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data;
    })
    .catch(() => {});
};

export const fetchDriverDetails = data => {
  return axios
    .post(`/api/admin/driver`, data)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data;
    })
    .catch(() => {});
};

export const setUser = data => dispatch => {
  dispatch(setUserPending());
  return axios
    .post(`/api/admin/user`, data)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(setUserSuccess(res.data));
      return res.data;
    })
    .catch(error => {
      dispatch(setUserError(error));
    });
};

export const addUser = data => {
  return axios
    .post(`/api/admin/user/add`, data)
    .then(res => {
      // console.log(res);
      if (res.error) {
        throw JSON.parse(res.error);
      }
      return res.data;
    })
    .catch(error => {
      return error;
    });
};

export const deleteUser = data => {
  return axios
    .post(`/api/admin/user/delete`, { id: data })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      return res.data;
    })
    .catch(error => {
      return error.error;
    });
};
