import React from 'react';
import { Formik } from 'formik';
import {
  authenticate as authenticateAction,
  checkUser as checkUserAction,
  logOut,
} from 'services/userServices';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NawigacjaLogin from 'components/molecules/NawigacjaLogin/NawigacjaLogin';
import gruszka1 from 'assets/images/gruszka2.png';
import gruszka2 from 'assets/images/gruszka3.png';
import gruszka3 from 'assets/images/gruszka4a.png';
import PropTypes from 'prop-types';
import { theme } from 'theme/loginTheme';
import { ThemeProvider } from 'styled-components'; //  styled,
import * as Yup from 'yup';

import {
  StyledLogo,
  H,
  Formularz,
  Input,
  GruszkaPierwsza,
  GruszkaDruga,
  GruszkaTrzecia,
  Wrapper,
  Logout,
  Submit,
  Alert,
} from './style';

// const StyledLabel = styled.label`
//   display: flex;
//   align-items: center;
//   margin-bottom: 1rem;
// `;

// const StyledCheckbox = styled.input`
//   margin-right: 1rem;
// `;

const LoginSchema = Yup.object().shape({
  login: Yup.string()
    .min(2, 'Login jest za krótki!')
    .max(50, 'Login jest za długi!')
    .required('Pole jest wymagane!'),
  password: Yup.string()
    .min(5, 'Hasło jest za krótkie!')
    .max(50, 'Hasło jest za długie!')
    .required('Pole jest wymagane!'),
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isRemember: false,
      data: { login: '', password: '' },
    };
  }

  componentDidMount() {
    const { checkUser } = this.props;

    checkUser();

    if (localStorage.getItem('login')) {
      const data = JSON.parse(atob(localStorage.getItem('login')));
      this.setState({ data });
    }
  }

  handleChange = () => {
    const { isRemember } = this.state;
    this.setState({
      isRemember: !isRemember,
    });
  };

  handleSubmit = ({ login, password }) => {
    const { isRemember } = this.state;

    const { authenticate } = this.props;
    authenticate(login, password).then(() => {
      if (isRemember) {
        const storage = btoa(JSON.stringify({ login, password }));
        localStorage.setItem('login', storage);
      }
    });
  };

  logMeOut() {
    const { checkUser } = this.props;
    logOut().then(() => {
      checkUser();
    });
  }

  render() {
    const { data } = this.state;
    const { user } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <GruszkaPierwsza src={gruszka1} alt="" />
          <GruszkaDruga src={gruszka2} alt="" />
          <GruszkaTrzecia src={gruszka3} alt="" />
          <div>
            <StyledLogo path="/login" />
            {!user.userId && (
              <>
                <H rank={1}>Zaloguj się</H>
                <div className="loginWrapper__formularz">
                  <Formik
                    enableReinitialize
                    initialValues={{ login: data.login, password: data.password }}
                    onSubmit={this.handleSubmit}
                    validationSchema={LoginSchema}
                  >
                    {({ errors, touched }) => (
                      <Formularz>
                        <Input
                          name="login"
                          type="text"
                          placeholder="Twój login"
                          autoComplete="login"
                        />
                        <Alert>
                          {errors.login && touched.login ? <span>{errors.login}</span> : null}
                        </Alert>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Twoje hasło"
                          autoComplete="password"
                        />
                        <Alert>
                          {errors.password && touched.password ? (
                            <span>{errors.password}</span>
                          ) : null}
                        </Alert>

                        {/* <StyledLabel htmlFor="checkbox">
                          <StyledCheckbox
                            type="checkbox"
                            name="remember"
                            checked={isRemember}
                            onChange={e => this.handleChange(e)}
                            id="checkbox"
                          />
                          Pamiętaj mnie
                        </StyledLabel> */}
                        <Submit type="submit" value="Zaloguj" />
                        <p>
                          Nie masz konta? &nbsp;{' '}
                          <NavLink to="/zarejestruj-sie">zarejestruj się</NavLink>
                        </p>
                      </Formularz>
                    )}
                  </Formik>
                </div>
              </>
            )}
            {user && user.userId && (
              <>
                <H rank={1}>Wybierz</H>
                {Object.keys(user.widoki).length > 1 && (
                  <NawigacjaLogin
                    key={Object.keys(user.widoki)[0]}
                    widoki={user.widoki}
                    uprawnienia={user.uprawnienia}
                  />
                )}
                {Object.keys(user.widoki).length === 1 && (
                  <Redirect to={`/${user.widoki[Object.keys(user.widoki)[0]]}`} />
                )}
                <Logout
                  type="submit"
                  onClick={() => {
                    this.logMeOut();
                  }}
                >
                  Wyloguj się
                </Logout>
              </>
            )}
          </div>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

Login.propTypes = {
  authenticate: PropTypes.func.isRequired,
  checkUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userId: PropTypes.number,
    uprawnienia: PropTypes.shape({}),
    widoki: PropTypes.shape({}),
  }),
};

Login.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.userReducer.user,
});
const mapDispatchToProps = dispatch => ({
  authenticate: (username, password) => dispatch(authenticateAction(username, password)),
  checkUser: () => dispatch(checkUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
