import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/atoms/Heading/Heading';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const StyledHeading = styled(Heading)`
  margin-top: 1em;
`;

const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  border: 4px solid black;
  padding: 1rem;
  outline: none;
  border-radius: 6px;
`;

const KomentarzZamowienie = ({ text, handleChange }) => (
  <Row>
    <Col xs={12}>
      <StyledHeading rank={3}>Komentarz do zamówienia</StyledHeading>
      <Textarea rows="4" value={text} onChange={handleChange} maxLength="255" />
      <small>do 255 znaków</small>
    </Col>
  </Row>
);

KomentarzZamowienie.propTypes = {
  text: PropTypes.string,
  handleChange: PropTypes.func,
};

KomentarzZamowienie.defaultProps = {
  text: '',
  handleChange: null,
};

export default KomentarzZamowienie;
