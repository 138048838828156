import React from 'react';
import PropTypes from 'prop-types';
import { pobierzFirmyAction, pobierzGodzinyAction } from 'services/admin/firmyServices';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminTemplate from 'templates/AdminTemplate';
import EdytujFirme from 'components/organisms/EdytujFirme/EdytujFirme';
import KontrahenciAdmin from 'components/organisms/KontrahenciAdmin/KontrahenciAdmin';
import Heading from 'components/atoms/Heading/Heading';
// import SidebarFirmy from 'components/organisms/SidebarFirmy/SidebarFirmy';

import {
  AdminWrapperA,
  ArrowContC,
  ArrowA,
  Content,
  WybranaFirma,
  Table,
  Cell,
  Container,
  StyledLink,
  DodajEdytuj,
  StyledTabListA,
  SidebarHeader,
  TabLink,
  DropdownWrapper,
  Tab,
  // PojedynczyUzytkownik,
} from 'views/Admin/style';

import Dropdown from 'components/molecules/Dropdown/Dropdown';

class AdminKontrahenci extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      firmy: [],
      godziny: [],
      navNone: false,
    };
    this.pobierzFirmy = this.pobierzFirmy.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.pobierzFirmy();

    this._isMounted = true;
    const { firmy, godziny } = this.props;
    if (firmy.length) {
      this.setState({ firmy });
    } else {
      pobierzFirmyAction().then(pobraneFirmy => {
        if (this._isMounted) {
          this.setState({ firmy: pobraneFirmy });
        }
      });
    }
    if (godziny.length) {
      this.setState({ godziny });
    } else {
      pobierzGodzinyAction().then(pobraneGodziny => {
        if (this._isMounted) {
          this.setState({ godziny: pobraneGodziny });
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onMenuItemClicked = () => {
    this.setState(prevState => ({ navNone: !prevState.navNone }));
  };

  pobierzFirmy() {
    pobierzFirmyAction().then(firmy => {
      if (this._isMounted) {
        this.setState({ firmy });
      }
    });
  }

  render() {
    const { firmy, godziny, navNone } = this.state;

    return (
      <AdminTemplate>
        <AdminWrapperA>
          {firmy.length > 0 && (
            <StyledTabListA
              style={{
                transition: '1s',
                left: navNone ? '0px' : '-300px',
              }}
              responsive
            >
              <SidebarHeader>
                <TabLink to="/kontrahenci">Kontrahenci</TabLink>
              </SidebarHeader>
              <DropdownWrapper>
                {godziny &&
                  firmy.length > 0 &&
                  godziny.map(item => (
                    <Dropdown key={item.id} godzina={item.godzinaWyjazdu}>
                      {firmy &&
                        firmy.map(c => {
                          return c.godzinyWyjazdu.map(currentValue => {
                            if (currentValue.id === item.id) {
                              return (
                                <Tab key={c.id}>
                                  <TabLink to={`/kontrahent/${c.id}`}>{c.nazwa}</TabLink>
                                </Tab>
                              );
                            }
                            return null;
                          });
                        })}
                    </Dropdown>
                  ))}
              </DropdownWrapper>
            </StyledTabListA>
          )}
          <ArrowContC
            onClick={this.onMenuItemClicked}
            style={{
              transition: '1s',
              left: navNone ? '300px' : '0px',
            }}
          >
            <ArrowA
              style={{
                transition: '1s',
                transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
              }}
            />
          </ArrowContC>
          <Content>
            <Container>
              <WybranaFirma>Wszystkie firmy</WybranaFirma>
              <DodajEdytuj>
                <Heading rank={3}>Dodaj firmę:</Heading>
              </DodajEdytuj>
              <EdytujFirme edytuj pobierzFirmy={this.pobierzFirmy} />
              <Table view="kontrahenci" scrolled>
                <>
                  <Cell center head>
                    L.P.
                  </Cell>
                  <Cell head>Nazwa</Cell>
                  <Cell head>Ulica</Cell>
                  <Cell center head>
                    Kod pocztowy
                  </Cell>
                  <Cell head>Miasto</Cell>
                  <Cell head>NIP</Cell>
                  <Cell head>Godziny Wyjazdu</Cell>
                  <Cell />
                </>
                {firmy &&
                  firmy.map((item, indexK) => {
                    return (
                      <StyledLink key={`${item.id}`} to={`/kontrahent/${item.id}`}>
                        <KontrahenciAdmin index={indexK} {...item} />
                      </StyledLink>
                    );
                  })}
              </Table>
            </Container>
          </Content>
        </AdminWrapperA>
      </AdminTemplate>
    );
  }
}

AdminKontrahenci.propTypes = {
  pobierzFirmy: PropTypes.func,
  firmy: PropTypes.arrayOf(PropTypes.object),
  godziny: PropTypes.arrayOf(PropTypes.object),
};

AdminKontrahenci.defaultProps = {
  pobierzFirmy: null,
  firmy: [],
  godziny: [],
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzFirmy: pobierzFirmyAction,
    },
    dispatch,
  );

export default connect(mapDispatchToProps)(AdminKontrahenci);
