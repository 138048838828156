import React from 'react';
import KuchniaZamowienia from 'components/organisms/KuchniaZamowienia/KuchniaZamowienia';
import KuchniaTemplate from 'templates/KuchniaTemplate';

const KuchniaLayout = () => (
  <KuchniaTemplate>
    <KuchniaZamowienia />
  </KuchniaTemplate>
);

export default KuchniaLayout;
