import { USTAW_DATE_MENU } from 'actions/filterActions';

const initialState = {
  pending: false,
  error: null,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case USTAW_DATE_MENU:
      return {
        ...state,
        dataMenu: action.dataMenu,
        pending: true,
      };
    default:
      return state;
  }
};

export default filterReducer;
