import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-tabs';
import AdminTemplate from 'templates/AdminTemplate';
import {
  fetchUser as fetchUserAction,
  setUser as setUserAction,
  deleteUser as deleteUseAction,
} from 'services/admin/usersServices';
import { bindActionCreators } from 'redux';
import Col from 'react-bootstrap/Col';
import Confirm from 'components/molecules/Confirm/Confirm';
import { pobierzListeFirmAction } from 'services/admin/firmyServices';
import { addAlert } from 'actions/alertActions';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'components/atoms/Button/Button';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components';
import 'react-tabs/style/react-tabs.css';
import PropTypes from 'prop-types';
import {
  // StyledTabListA,
  StyledTabList,
  StyledTab,
  StyledTabPanelA,
  // AdminWrapperA,
  ArrowContC,
  ArrowA,
} from './style';

const AdminWrapper = styled.div`
  min-height: 100vh;
  padding-top: 8rem;
  align-items: flex-start;
  display: flex;
  padding-bottom: 5rem;
`;
const InputCheckbox = styled.input`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledTabPanelR = styled(StyledTabPanelA)`
  margin-top: 10rem !important;
  @media (max-width: 1365px) {
    margin-top: 10rem !important;
    padding-left: 10px !important;
  }
`;

const StyledWrapper = styled.div`
  margin-top: 4rem;
  width: 20vw;
  min-width: 500px;
`;

const StyledTitle = styled.h2`
  font-size: 4rem;
`;

const StyledSubtitle = styled.h3``;

const StyledControl = styled(Form.Control)`
  width: 100%;
`;

const RedButton = styled(Button)`
  background: #fff;
  border: 1px solid red;
  color: red;
`;
const StyledRow = styled(Form.Row)`
  justify-content: space-between;
`;
const schema = Yup.object({
  imie: Yup.string().required('imię jest wymagane'),
  nazwisko: Yup.string().required('naziwsko jest wymagane'),
  password: Yup.string().min(5, 'hasło musi się składać z minimum 5 znaków'),
  login: Yup.string().required('login jest wymagane'),
  email: Yup.string()
    .email('proszę podać prawidłowy adres e-mail')
    .required('e-mail jest wymagane'),
  telefon: Yup.string(),
  faktura: Yup.bool(),
  status: Yup.bool(),
  permissions: Yup.array().required('uprawnienia są wymagane'),
  firmaId: Yup.number(),
});

const options = [
  { value: 'klient', label: 'Klient' },
  { value: 'kierowca', label: 'Kierowca' },
  { value: 'kuchnia', label: 'Kuchnia' },
  { value: 'lokal', label: 'Lokal' },
  { value: 'admin_firma', label: 'Admin Firma' },
  { value: 'super_user', label: 'Super User' },
];

function searchLabel(nameKey, myArray) {
  for (let i = 0; i < myArray.length; i += 1) {
    if (myArray[i].value === nameKey) {
      return myArray[i].label;
    }
  }
  return '';
}

const permissionOptions = [
  { value: 'klient', label: 'Klient' },
  { value: 'kierowca', label: 'Kierowca' },
  { value: 'kuchnia', label: 'Kuchnia' },
  { value: 'lokal', label: 'Lokal' },
  { value: 'admin', label: 'Admin' },
  { value: 'admin_firma', label: 'Admin Firma' },
  { value: 'super_user', label: 'Super User' },
];

const Users = ({ fetchUser, user, setUser }) => {
  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const [navNone, setNavNone] = useState(false);

  const [firmaOptions, setFirmaOptions] = useState(null);

  const fetchContractors = () => {
    pobierzListeFirmAction().then(res => {
      let data = [];

      data = res.map(item => ({
        id: item.id,
        value: item.id,
        label: item.nazwa,
      }));

      data.unshift({ id: 0, value: 'Brak Firmy', label: 'Brak Firmy' });

      setFirmaOptions(data);
    });
  };
  useEffect(() => {
    fetchContractors();
  }, []);

  const history = useHistory();
  useEffect(() => {
    fetchUser(id);
  }, []);

  const showModal = e => {
    e.preventDefault();
    setShowConfirm(true);
  };

  const deleteUser = () => {
    deleteUseAction(id).then(() => {
      addAlert({
        message: `Usunięto użytkownika`,
        type: 'success',
      });
      history.push('/users');
    });
  };
  const {
    imie,
    nazwisko,
    nazwa,
    ostatnieLogowanie,
    email,
    login,
    telefon,
    firmaId,
    faktura,
    status,
    permissions,
  } = user;
  return (
    <AdminTemplate>
      <Tabs defaultIndex={0}>
        <AdminWrapper>
          <StyledTabList
            style={{
              transition: '1s',
              left: navNone ? 0 : '-300px',
            }}
          >
            <StyledTab>
              <a href="/users" style={{ textDecoration: 'none', color: 'inherit' }}>
                Użytkownicy
              </a>
            </StyledTab>
          </StyledTabList>
          <ArrowContC
            onClick={() => setNavNone(!navNone)}
            style={{
              transition: '1s',
              left: navNone ? '300px' : '0px',
            }}
          >
            <ArrowA
              style={{
                transition: '1s',
                transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
              }}
            />
          </ArrowContC>
          <StyledTabPanelR>
            <StyledTitle>
              {imie} {nazwisko}
            </StyledTitle>
            <StyledSubtitle>Kontrahent: {nazwa ? `${nazwa}` : 'Brak '}</StyledSubtitle>
            <StyledSubtitle>
              Ostatnie logowanie: {ostatnieLogowanie ? `${ostatnieLogowanie}` : 'Brak '}
            </StyledSubtitle>
            <StyledSubtitle>
              Dane podane przy rejestracji: {user.firma} {user.adres} {user.kod_pocztowy}{' '}
              {user.miejscowosc}
            </StyledSubtitle>

            <StyledWrapper>
              {user.imie && (
                <Formik
                  validationSchema={schema}
                  enableReinitialize
                  initialValues={{
                    imie,
                    nazwisko,
                    email,
                    login,
                    telefon,
                    faktura,
                    status,
                    permissions,
                    firmaId,
                    password: '',
                  }}
                  onSubmit={values => {
                    setUser({ ...values, id: user.id }).then(() => {
                      addAlert({
                        message: `Edytowano użytkownika`,
                        type: 'success',
                      });
                    });
                  }}
                >
                  {({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <Form onSubmit={e => handleSubmit(e)}>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="imie">
                          <Form.Label>Imię</Form.Label>
                          <StyledControl
                            size="lg"
                            name="imie"
                            value={values.imie}
                            onChange={handleChange}
                            type="text"
                            autoComplete="imie"
                          />
                          <ErrorMessage name="imie">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="nazwisko">
                          <Form.Label>Nazwisko</Form.Label>
                          <StyledControl
                            size="lg"
                            name="nazwisko"
                            value={values.nazwisko}
                            onChange={handleChange}
                            type="text"
                            autoComplete="nazwisko"
                          />
                          <ErrorMessage name="nazwisko">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <StyledControl
                            size="lg"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            type="text"
                            autoComplete="email"
                          />
                          <ErrorMessage name="email">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="login">
                          <Form.Label>Login</Form.Label>
                          <StyledControl
                            size="lg"
                            name="login"
                            value={values.login}
                            onChange={handleChange}
                            type="text"
                            autoComplete="login"
                          />
                          <ErrorMessage name="login">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="telefon">
                          <Form.Label>Telefon</Form.Label>
                          <StyledControl
                            size="lg"
                            name="telefon"
                            value={values.telefon}
                            onChange={handleChange}
                            type="text"
                            autoComplete="telefon"
                          />
                          <ErrorMessage name="telefon">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="firmaId">
                          <Form.Label>Firma</Form.Label>
                          <Select
                            options={firmaOptions}
                            name="firmaId"
                            value={{
                              value: values.firmaId,
                              label: searchLabel(values.firmaId, firmaOptions),
                            }}
                            placeholder="Wybierz"
                            onChange={e => setFieldValue('firmaId', e.id)}
                          />
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="permissions">
                          <Form.Label>Uprawnienia</Form.Label>
                          {values.permissions && (
                            <Select
                              options={permissionOptions}
                              isMulti
                              value={values.permissions.map(p => ({
                                value: p,
                                label: searchLabel(p, options),
                              }))}
                              name="permissions"
                              onChange={e =>
                                setFieldValue(
                                  'permissions',
                                  e !== null ? e.map(i => i.value) : e === 'asdas',
                                )
                              }
                            />
                          )}
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="3" controlId="status">
                          Status
                          <InputCheckbox
                            type="checkbox"
                            name="status"
                            label="Status"
                            onChange={handleChange}
                            value
                            checked={values.status}
                          />
                          <ErrorMessage name="status">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="faktura">
                          Faktura
                          <InputCheckbox
                            type="checkbox"
                            name="faktura"
                            label="Faktura"
                            onChange={handleChange}
                            value
                            checked={values.faktura}
                          />
                          <ErrorMessage name="faktura">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="password">
                          <Form.Label>Zmień hasło</Form.Label>
                          <StyledControl
                            size="lg"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            type="password"
                            autoComplete="password"
                          />
                          <ErrorMessage name="password">
                            {msg => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Form.Row>
                      <StyledRow>
                        <RedButton small onClick={e => showModal(e)}>
                          Usuń Użytkownika
                        </RedButton>

                        <Button small type="submit">
                          Zapisz
                        </Button>
                      </StyledRow>
                    </Form>
                  )}
                </Formik>
              )}
            </StyledWrapper>
          </StyledTabPanelR>
        </AdminWrapper>
      </Tabs>
      <Confirm
        show={showConfirm}
        text="Czy na pewno chcesz usunąć użytkownika?"
        toggle={() => setShowConfirm(false)}
        usun={() => deleteUser()}
      />
    </AdminTemplate>
  );
};

Users.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    nazwa: PropTypes.string,
    imie: PropTypes.string,
    nazwisko: PropTypes.string,
    firmaId: PropTypes.number,
    email: PropTypes.string,
    login: PropTypes.string,
    telefon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    faktura: PropTypes.number,
    status: PropTypes.number,
    permissions: PropTypes.arrayOf(PropTypes.string),
    ostatnieLogowanie: PropTypes.string,
    miejscowosc: PropTypes.string,
    kod_pocztowy: PropTypes.string,
    adres: PropTypes.string,
    firma: PropTypes.string,
  }),
  fetchUser: PropTypes.func,
  setUser: PropTypes.func,
};

Users.defaultProps = {
  user: {
    id: 0,
    imie: '',
    nazwa: '',
    nazwisko: '',
    email: '',
    login: '',
    telefon: '',
    firmaId: 0,
    status: 0,
    faktura: 0,
    permissions: [],
    miejscowosc: '',
    kod_pocztowy: '',
    adres: '',
    firma: '',
    ostatnieLogowanie: '',
  },
  fetchUser: null,
  setUser: null,
};

const mapStateToProps = state => ({
  user: state.usersReducer.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUser: fetchUserAction,
      setUser: setUserAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Users);
