import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import Logo from 'components/atoms/Logo/Logo';
import Obraz from 'components/atoms/Obraz/Obraz';
import CheckBox from 'components/atoms/Checkbox/Checkbox';
import Heading from 'components/atoms/Heading/Heading';
import { Form, Field } from 'formik';

export const StyledLogo = styled(Logo)`
  width: 16.4rem;
  height: 17.5rem;
  margin: 0 auto;
`;

export const H = styled(Heading)`
  margin: 5rem 0 4rem;
  color: #000;
  @media (max-width: 991px) {
    margin: 1rem 0;
  }
`;

export const Formularz = styled(Form)`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const Input = styled(Field)`
  border: 4px solid #000;
  border-radius: 6px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.25);
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2em;
  color: #000;
  margin-bottom: 0.4rem;
  outline: none;
  transition: all 0.3s ease;
  &::-webkit-input-placeholder {
    color: #000;
  }
  &::-moz-placeholder {
    color: #000;
  }
  &:-ms-input-placeholder {
    color: #000;
  }
  &:-moz-placeholder {
    color: #000;
  }
  &:hover {
    box-shadow: 0 2px 6px -2px black;
    transform: translatey(-2px);
  }
  && &:-internal-autofill-selected {
    background-color: rgba(255, 255, 255, 0.25) !important;
  }
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

export const GruszkaPierwsza = styled(Obraz)`
  position: absolute;
  left: -10%;
  bottom: 0;
  width: 40vw;
`;

export const GruszkaDruga = styled(Obraz)`
  position: absolute;
  right: 0;
  bottom: -15%;
  width: 30vw;
  z-index: 0;
`;

export const GruszkaTrzecia = styled(Obraz)`
  position: absolute;
  right: -10%;
  top: -10%;
  width: 40vw;
`;

export const Wrapper = styled.div`
  background: #f3de21;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  text-align: center;
`;

export const Logout = styled(Button)`
  background-color: grey;
  border-color: grey;
`;

export const Submit = styled(Input)`
  && {
    background: black;
    color: #fff;
    text-transform: uppercase;
    font-size: 2.6rem;
    font-weight: 700;
  }
`;
export const StyledButton = styled(Button)`
  background: black;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.6rem;
  font-weight: 700;
`;

export const Alert = styled.div`
  color: red;
  height: 1em;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
`;

export const Cbox = styled(CheckBox)`
  margin-bottom: 3.2rem;
  text-align: left;
`;
