import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Heading from 'components/atoms/Heading/Heading';
import styled from 'styled-components';

const Cl = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Heading)`
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const Tooltip = styled.div`
  font-size: 1.2rem;
  line-height: 1.1em;
  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  display: none;
  position: absolute;
  left: 8px;
  top: 0;
  width: calc(100% - 16px);
  z-index: 2;
  padding: 0.5rem 0.5rem;
  border: 1px solid #aebdc2;
  background-color: #fff;
  min-height: 100%;
  border: 1px solid #000;
  p {
    &:first-child {
      font-weight: 700;
    }
  }
`;

const Opis = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  flex-grow: 1;
  padding: 3px;
  margin-bottom: 0.6rem;
  border: 1px solid black;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.6);
  &:hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

function Truncate(el) {
  const iloscZnakow = 30;
  let kropki = '..';
  if (el.length < iloscZnakow - 1) {
    kropki = '';
  }
  return el.substring(0, iloscZnakow) + kropki;
}

const TitleBox = ({ nazwa, opis, dodatek, dodatek2, nazwaKategorii, kodDania }) => (
  <Cl>
    <Wrapper>
      <Title rank={4} type="title">
        {Truncate(nazwa)}
      </Title>
      <Opis>
        <p>
          {nazwaKategorii} {kodDania !== '' && `(${kodDania})`}
        </p>
        <Tooltip>
          <p>{nazwa}</p>
          {dodatek && <p>{dodatek}</p>}
          {dodatek2 && <p>{dodatek2}</p>}
          {opis && <p>{opis}</p>}
        </Tooltip>
      </Opis>
    </Wrapper>
  </Cl>
);

//  <p className={styles.opis}>{Truncate(opis)}</p>

TitleBox.propTypes = {
  nazwa: PropTypes.string,
  dodatek: PropTypes.string,
  dodatek2: PropTypes.string,
  nazwaKategorii: PropTypes.string,
  kodDania: PropTypes.string,
  opis: PropTypes.string,
};

TitleBox.defaultProps = {
  nazwa: 'Nazwa dania',
  dodatek: 'dodatek',
  dodatek2: '',
  nazwaKategorii: 'kategoria',
  kodDania: 'kod',
  opis: 'Opis dania',
};

export default TitleBox;
