import axios from 'axios';
import {
  pobierzZamowieniaPending,
  pobierzZamowieniaSuccess,
  pobierzZamowieniaError,
  // przypiszZamowieniaPending,
  // przypiszZamowieniaSuccess,
  // przypiszZamowieniaError,
} from 'actions/zamowieniaActions';

export const pobierzZamowienia = dataMenu => dispatch => {
  dispatch(pobierzZamowieniaPending());
  return axios
    .get(`/api/admin/zamowienia/${dataMenu}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(pobierzZamowieniaSuccess({ zamowienia: res.data.data, aktualizacja: dataMenu }));
      return res.data.data;
    })
    .catch(error => {
      dispatch(pobierzZamowieniaError(error));
    });
};

export default pobierzZamowienia;
