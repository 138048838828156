import React from 'react';
import Modal from 'react-bootstrap/Modal';

import Heading from '../../atoms/Heading/Heading';
import EtykietyLista from '../../molecules/EtykietyLista/EtykietyLista';
import styles from './EtykietyPopup.module.scss';

const EtykietyPopup = props => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className={styles.close}>
        <Heading rank={2}>Archiwum Etykiet</Heading>
      </Modal.Header>
      <Modal.Body>
        <EtykietyLista />
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  );
};

export default EtykietyPopup;
