export const WYSLIJ_ZAMOWIENIA_PENDING = 'WYSLIJ_ZAMOWIENIA_PENDING';
export const WYSLIJ_ZAMOWIENIA_SUCCESS = 'WYSLIJ_ZAMOWIENIA_SUCCESS';
export const WYSLIJ_ZAMOWIENIA_ERROR = 'WYSLIJ_ZAMOWIENIA_ERROR';
export const POBIERZ_ZAMOWIENIA_PENDING = 'POBIERZ_ZAMOWIENIA_PENDING';
export const POBIERZ_ZAMOWIENIA_SUCCESS = 'POBIERZ_ZAMOWIENIA_SUCCESS';
export const POBIERZ_ZAMOWIENIA_ERROR = 'POBIERZ_ZAMOWIENIA_ERROR';
export const WYCZYSC_ZAMOWIENIE_SUCCESS = 'WYCZYSC_ZAMOWIENIE_SUCCESS';

export const USUN_ZAMOWIENIE_PENDING = 'USUN_ZAMOWIENIE_PENDING';
export const USUN_ZAMOWIENIE_SUCCESS = 'USUN_ZAMOWIENIE_SUCCESS';
export const USUN_ZAMOWIENIE_ERROR = 'USUN_ZAMOWIENIE_ERROR';

export const PRZYPISZ_ZAMOWIENIE_PENDING = 'PRZYPISZ_ZAMOWIENIE_PENDING';
export const PRZYPISZ_ZAMOWIENIE_SUCCESS = 'PRZYPISZ_ZAMOWIENIE_SUCCESS';
export const PRZYPISZ_ZAMOWIENIE_ERROR = 'PRZYPISZ_ZAMOWIENIE_ERROR';

export const FETCH_ZAMOWIENIA_PENDING = 'FETCH_ZAMOWIENIA_PENDING';
export const FETCH_ZAMOWIENIA_SUCCESS = 'FETCH_ZAMOWIENIA_SUCCESS';
export const FETCH_ZAMOWIENIA_ERROR = 'FETCH_ZAMOWIENIA_ERROR';

export const POBIERZ_ZAMOWIENIA_KIEROWCA_SUCCESS = 'POBIERZ_ZAMOWIENIA_KIEROWCA_SUCCESS';

export function pobierzZamowieniaKierowcaSuccess(payload) {
  return {
    type: POBIERZ_ZAMOWIENIA_KIEROWCA_SUCCESS,
    kierowcy: payload,
  };
}

export function wyslijZamowieniePending() {
  return {
    type: WYSLIJ_ZAMOWIENIA_PENDING,
  };
}

export function wyslijZamowienieSuccess(numerZamowienia) {
  return {
    type: WYSLIJ_ZAMOWIENIA_SUCCESS,
    numerZamowienia,
  };
}

export function wyslijZamowienieError(error) {
  return {
    type: WYSLIJ_ZAMOWIENIA_ERROR,
    error,
  };
}

export function fetchZamowieniaPending() {
  return {
    type: FETCH_ZAMOWIENIA_PENDING,
  };
}

export function fetchZamowieniaSuccess(data) {
  return {
    type: FETCH_ZAMOWIENIA_SUCCESS,
    zamowienia: data.zamowienia,
  };
}

export function fetchZamowieniaError(error) {
  return {
    type: FETCH_ZAMOWIENIA_ERROR,
    error,
  };
}

export function przypiszZamowieniePending() {
  return {
    type: PRZYPISZ_ZAMOWIENIE_PENDING,
  };
}

export function przypiszZamowienieSuccess(data) {
  return {
    type: PRZYPISZ_ZAMOWIENIE_SUCCESS,
    zamowienia: data,
  };
}

export function przypiszZamowienieError(error) {
  return {
    type: PRZYPISZ_ZAMOWIENIE_ERROR,
    error,
  };
}

export function usunZamowieniePending() {
  return {
    type: USUN_ZAMOWIENIE_PENDING,
  };
}

export function usunZamowienieSuccess(id) {
  return {
    type: USUN_ZAMOWIENIE_SUCCESS,
    usuniete_id: id,
  };
}

export function usunZamowienieError(error) {
  return {
    type: USUN_ZAMOWIENIE_ERROR,
    error,
  };
}

export function pobierzZamowieniaPending() {
  return {
    type: POBIERZ_ZAMOWIENIA_PENDING,
  };
}

export function pobierzZamowieniaSuccess(data) {
  return {
    type: POBIERZ_ZAMOWIENIA_SUCCESS,
    zamowienia: data.zamowienia,
    aktualizacja: data.aktualizacja,
  };
}

export function pobierzZamowieniaError(error) {
  return {
    type: POBIERZ_ZAMOWIENIA_ERROR,
    error,
  };
}

export function wyczyscZamowienieSuccess() {
  return {
    type: WYCZYSC_ZAMOWIENIE_SUCCESS,
  };
}
