import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button/Button';
import styled from 'styled-components';

const Tab = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  height: auto;
  max-height: ${({ initialHeight }) => initialHeight + 6}px;
  transition: all 0.3s ease;
  padding-top: 2px;
  &.active {
    max-height: ${({ height }) => height + 6}px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.25em;
  padding: 1.2rem 2rem;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease;
  width: 100%;
  text-align: left;
  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.3);
  }
  & + & {
    margin-top: 0.4rem;
  }
`;

const ListaKontrahentow = styled.div`
  height: auto;
  transition: all 0.3s ease;
  overflow: hidden;
`;

class Dropdown extends React.Component {
  state = {
    open: false,
    initialHeight: 60,
    height: 60,
  };

  componentDidMount() {
    this.setState({ initialHeight: this.elementStaly.clientHeight });
    this.setState({ height: this.elementStaly.clientHeight });
  }

  handleClick = e => {
    e.preventDefault();
    const { open } = this.state;
    this.setState(prevState => ({
      open: !prevState.open,
      height: open
        ? prevState.height - this.elementRozwijany.clientHeight
        : prevState.height + this.elementRozwijany.clientHeight,
    }));
  };

  render() {
    const { godzina, children } = this.props;
    const { open, initialHeight, height } = this.state;
    return (
      <Tab height={height} initialHeight={initialHeight} className={open ? 'active' : ''}>
        <StyledButton
          ref={elementStaly => {
            this.elementStaly = elementStaly;
          }}
          active={open}
          onClick={this.handleClick}
        >
          {godzina}
        </StyledButton>
        <ListaKontrahentow
          ref={elementRozwijany => {
            this.elementRozwijany = elementRozwijany;
          }}
        >
          {children}
        </ListaKontrahentow>
      </Tab>
    );
  }
}

Dropdown.propTypes = {
  godzina: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
Dropdown.defaultProps = { children: [] };

export default Dropdown;
