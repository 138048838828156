import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/atoms/Button/Button';
import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
  margin-right: 1rem;
  padding: 0.5rem;
`;

const Confirm = ({ text, show, toggle, usun }) => (
  <Modal show={show} onHide={toggle}>
    <Modal.Body>{text}</Modal.Body>
    <Modal.Footer>
      <StyledButton small onClick={toggle}>
        Anuluj
      </StyledButton>
      <StyledButton small onClick={usun}>
        Tak
      </StyledButton>
    </Modal.Footer>
  </Modal>
);

Confirm.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  usun: PropTypes.func,
};

Confirm.defaultProps = {
  text: '',
  show: false,
  toggle: null,
  usun: null,
};

export default Confirm;
