import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from 'theme/kuchniaTheme';
import TymczasowyHeader from 'components/molecules/TymczasowyHeader/TymczasowyHeader';

const KuchniaContainer = styled.div`
  background-color: rgba(214, 222, 224, 0.5);
  min-height: 100vh;
  padding: 15px;
  overflow: hidden;
`;

const KuchniaTemplate = ({ children }) => (
  <ThemeProvider theme={theme}>
    <TymczasowyHeader />
    <KuchniaContainer>{children}</KuchniaContainer>
  </ThemeProvider>
);

KuchniaTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default KuchniaTemplate;
