import React from 'react';

import PropTypes from 'prop-types';

import KlientTemplate from 'templates/KlientTemplate';

import HistoriaItem from 'components/organisms/HistoriaItem/HistoriaItem';
// import ZamowienieLokalRow from 'components/organisms/LokalZamowienia/ZamowienieLokalRow';
import gruszka1 from 'assets/images/gruszka2.png';
import gruszka2 from 'assets/images/gruszka3.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
// import Obraz from 'components/atoms/Obraz/Obraz';
// import creditCard from 'assets/svg/credit_card.svg';
// import cash from 'assets/svg/cash.svg';
// import invoice from 'assets/svg/przelew.svg';
import { bindActionCreators } from 'redux';

import { checkUser as checkUserAction } from 'services/userServices';

import { connect } from 'react-redux';

import pobierzZamowieniaAction from 'services/pobierzZamowienia';

import { GruszkaPierwsza, GruszkaDruga } from 'views/Klient/style';

const Wrapper = styled.div`
  margin-top: 10rem;
  margin-bottom: 6rem;
`;

// const Aside = styled.div`
//   width: ${({ method }) => (method ? 'auto' : '15%')};
//   min-width: ${({ method }) => (method ? '0' : '15rem')};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: 400;
//   font-size: 1.8rem;
// `;

// const Wrrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin: 0 -8px 8px;
//   padding-bottom: 2px;
//   border-bottom: 2px solid #000;
// `;
class KlientHistoria extends React.Component {
  componentDidMount() {
    const { pobierzZamowienia } = this.props;
    pobierzZamowienia();
  }

  render() {
    const { zamowienia, error } = this.props;

    return (
      <KlientTemplate title="Historia zamówień" subtitle="Sprawdź co zamawiałeś w ostatnim czasie">
        <GruszkaPierwsza src={gruszka1} alt="" />
        <GruszkaDruga className="login_img" src={gruszka2} alt="" />
        <div>{error}</div>
        {zamowienia && (
          <Wrapper>
            <Container>
              <Row>
                <Col xl={{ span: 10, offset: 1 }}>
                  {zamowienia.map(item => (
                    <HistoriaItem key={item.id} {...item} />
                  ))}
                </Col>
              </Row>
            </Container>
          </Wrapper>
        )}
      </KlientTemplate>
    );
  }
}

KlientHistoria.propTypes = {
  zamowienia: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  error: PropTypes.string,
  pobierzZamowienia: PropTypes.func,
  user: PropTypes.shape({
    userId: PropTypes.number,
    firmaId: PropTypes.number,
    uprawnienia: PropTypes.shape({
      super_user: PropTypes.string,
      lokal: PropTypes.string,
    }),
  }),
};

KlientHistoria.defaultProps = {
  zamowienia: [],
  error: '',
  pobierzZamowienia: null,
  user: null,
};

const mapStateToProps = state => {
  return {
    error: state.zamowieniaReducer.error,
    zamowienia: state.zamowieniaReducer.zamowienia,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzZamowienia: pobierzZamowieniaAction,
      checkUser: checkUserAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KlientHistoria);
