import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/klientTheme';
import Header from 'components/organisms/HeaderKlient/Header';
import TymczasowyHeader from 'components/molecules/TymczasowyHeader/TymczasowyHeader';

const KlientTemplate = ({ children, dataMenu, title, subtitle }) => (
  <ThemeProvider theme={theme}>
    <>
      <TymczasowyHeader />
      <Header dataMenu={dataMenu} title={title} subtitle={subtitle} />
      {children}
    </>
  </ThemeProvider>
);

KlientTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  dataMenu: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

KlientTemplate.defaultProps = {
  dataMenu: '',
  title: '',
  subtitle: '',
};

export default KlientTemplate;
