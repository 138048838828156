import React from 'react';
import PropTypes from 'prop-types';
import { addAlert } from 'actions/alertActions';
import {
  pobierzFirmaByIdAction,
  pobierzKierowcyAction,
  zapiszFirmeAction,
} from 'services/admin/firmyServices';
import Obraz from 'components/atoms/Obraz/Obraz';
import plus from 'assets/svg/plusik.svg';
import whiteTick from 'assets/svg/whiteTick.svg';
import styled from 'styled-components';
import { Table, Cell, Input, AddNew } from 'views/Admin/style';

const InputDania = styled(Input)`
  border: 1px solid rgba(128, 207, 38, 0.2);
  border-bottom-color: rgba(128, 207, 38, 1);
  &:focus {
    outline: none;
    border-color: #80cf26;
    background-color: rgba(128, 207, 38, 0.4);
  }
`;

class EdytujFirme extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      firma: {
        id: 0,
        nazwa: '',
        ulica: '',
        kodPocztowy: '',
        miasto: '',
        godzinyWyjazdu: '',
        NIP: '',
        kierowca: '',
        kierowcaId: '',
        faktura: false,
      },
      uzytkownik: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, firma } = this.props;

    if (firma.id > 0) {
      this.setState({ firma });
    }
    if (id !== '') {
      const istniejacaFirma = pobierzFirmaByIdAction(id);
      if (this._isMounted) {
        this.setState(() => ({ firma: { ...istniejacaFirma } }));
      }
    }
    pobierzKierowcyAction().then(kierowcy => {
      if (this._isMounted) {
        this.setState({ kierowcy });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === 'checkbox') {
      const checked = e.target.checked;
      this.setState(prevState => ({
        ...prevState,
        firma: {
          ...prevState.firma,
          [name]: checked,
        },
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        firma: {
          ...prevState.firma,
          [name]: value,
        },
      }));
    }
  }

  dodajFirme() {
    // const { zapiszFirmeService } = this.props;
    const { firma } = this.state;
    if (firma.nazwa === '') {
      addAlert({
        title: 'Błąd!',
        message: 'Nazwa firmy jest wymagana',
        type: 'danger',
      });
    } else {
      // zapiszFirmeService(firma);
    }
  }

  zapiszFirme() {
    const { firma } = this.state;
    //  const { pobierzFirmy } = this.props;
    let err = '';
    try {
      if (firma.nazwa.length === 0) {
        err = 'Nazwa firmy jest wymagana';
        throw err;
      }
      if (firma.ulica.length === 0 || !!parseInt(firma.ulica, 10)) {
        err = 'Błędna nazwa ulicy';
        throw err;
      }
      if (!/\d{2}-\d{3}/g.test(firma.kodPocztowy)) {
        err = 'Błędny kod pocztowy';
        throw err;
      }
      if (firma.miasto.length === 0 || !!parseInt(firma.miasto, 10)) {
        err = 'Błąd w nazwie miasta';
        throw err;
      }
      // if (firma.kierowcaId === '') {
      //   err = 'Brak wybranego kierowcy';
      //   throw err;
      // }
      if (this._isMounted) {
        zapiszFirmeAction(firma).then(() => {
          //  pobierzFirmy();
        });
      }
    } catch (error) {
      addAlert({
        title: 'Błąd!',
        message: error,
        type: 'danger',
      });
    }
  }

  render() {
    const { firma } = this.state;
    const { nazwa, ulica, kodPocztowy, miasto, NIP } = firma;

    const { className } = this.props;

    return (
      <>
        <Table view="edytuj" scrolled className={className}>
          <Cell head>Nazwa</Cell>
          <Cell head thin>
            Ulica
          </Cell>
          <Cell center head>
            Kod pocztowy
          </Cell>
          <Cell center head thin>
            Miasto
          </Cell>
          <Cell center head thin>
            NIP
          </Cell>

          <Cell />
          <Cell />
          <Cell />
          <Cell />

          <Cell>
            <InputDania
              type="text"
              name="nazwa"
              value={nazwa}
              onChange={e => this.handleInputChange(e)}
            />
          </Cell>
          <Cell>
            <InputDania
              type="text"
              name="ulica"
              value={ulica}
              onChange={e => this.handleInputChange(e)}
            />
          </Cell>
          <Cell>
            <InputDania
              type="text"
              maxLength="6"
              minLength="6"
              name="kodPocztowy"
              value={kodPocztowy}
              onChange={e => this.handleInputChange(e)}
            />
          </Cell>
          <Cell>
            <InputDania
              type="text"
              name="miasto"
              value={miasto}
              onChange={e => this.handleInputChange(e)}
            />
          </Cell>
          <Cell>
            <InputDania
              type="text"
              name="NIP"
              value={NIP}
              onChange={e => this.handleInputChange(e)}
            />
          </Cell>
          {/* <Cell>
            {kierowcy && (
              <select
                name="kierowcaId"
                value={kierowcaId}
                onChange={e => this.handleInputChange(e)}
                style={{ width: '100%' }}
              >
                <option value="">Wybierz</option>
                {kierowcy.map(item => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.imieNazwisko}
                    </option>
                  );
                })}
              </select>
            )}
          </Cell> */}

          <Cell center thin>
            {firma.id === 0 && (
              <AddNew
                onClick={() => {
                  this.zapiszFirme();
                  // pobierzFirmy();
                }}
                style={{ marginLeft: '3rem' }}
              >
                <Obraz src={plus} alt="Dodaj firmę" />
              </AddNew>
            )}
            {firma.id > 0 && (
              <AddNew
                onClick={() => {
                  this.zapiszFirme();
                  // pobierzFirmy();
                }}
                style={{ marginLeft: '3rem' }}
              >
                <Obraz src={whiteTick} alt="Zapisz zmiany" />
              </AddNew>
            )}
          </Cell>
        </Table>
      </>
    );
  }
}

EdytujFirme.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  firma: PropTypes.shape({
    id: PropTypes.number,
    nazwa: PropTypes.string,
  }),
  uzytkownik: PropTypes.shape({
    imie: PropTypes.string,
    nazwisko: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    telefon: PropTypes.string,
    login: PropTypes.string,
  }),
  //  pobierzFirmy: PropTypes.func,
};

EdytujFirme.defaultProps = {
  id: '',
  className: 'className',
  firma: {
    id: 0,
    nazwa: '',
    ulica: '',
    kodPocztowy: '',
    miasto: '',
    godzinaWyjazdu: '',
    NIP: '',
    kierowca: '',
  },
  uzytkownik: {
    imie: '',
    nazwisko: '',
    email: '',
    telefon: '',
    login: '',
  },
  // pobierzFirmy: null,
};

export default EdytujFirme;
