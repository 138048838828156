import axios from 'axios';
import {
  pobierzZamowieniaPending,
  pobierzZamowieniaSuccess,
  pobierzZamowieniaError,
} from 'actions/zamowieniaActions';

export const pobierzZamowienia = () => dispatch => {
  dispatch(pobierzZamowieniaPending());
  return axios
    .get('/api/zamowienia/moje', {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(pobierzZamowieniaSuccess(res.data));
      return res.data.zamowienia;
    })
    .catch(error => {
      dispatch(pobierzZamowieniaError(error));
    });
};

export default pobierzZamowienia;
