import React from 'react';
import PropTypes from 'prop-types';
import { setFakturaAction } from 'services/admin/firmyServices';
import Obraz from 'components/atoms/Obraz/Obraz';
import plus from 'assets/svg/plusik.svg';
import whiteTick from 'assets/svg/whiteTick.svg';
import styled from 'styled-components';
import { addAlert } from 'actions/alertActions';
import { Table, Cell, Input, AddNew } from 'views/Admin/style';

const InputDania = styled(Input)`
  border: 1px solid rgba(128, 207, 38, 0.2);
  border-bottom-color: rgba(128, 207, 38, 1);
  &:focus {
    outline: none;
    border-color: #80cf26;
    background-color: rgba(128, 207, 38, 0.4);
  }
`;

class EdytujUzytkownika extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uzytkownik: {
        imie: '',
        nazwisko: '',
        email: '',
        telefon: '',
        login: '',
        id: 0,
        faktura: 0,
        status: 0,
        firmaId: 0,
      },
    };
  }

  componentDidMount() {
    const { uzytkownik, firmaId } = this.props;
    if (firmaId) {
      this.setState(prevState => ({ uzytkownik: { ...prevState.uzytkownik, firmaId } }));
    } else if (uzytkownik.id !== 0) {
      this.setState({ uzytkownik });
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.type === 'checkbox') {
      const checked = e.target.checked;
      this.setState(prevState => ({
        ...prevState,
        uzytkownik: {
          ...prevState.uzytkownik,
          [name]: checked,
        },
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        uzytkownik: {
          ...prevState.uzytkownik,
          [name]: value,
        },
      }));
    }
  }

  setFaktura() {
    const { uzytkownik } = this.state;
    const faktura = uzytkownik.faktura ? 1 : 0;
    setFakturaAction(uzytkownik.id, faktura).then(res => {
      this.setState(prevState => ({
        uzytkownik: {
          ...prevState.uzytkownik,
          faktura: res.faktura,
        },
      }));
    });
  }

  validationUser() {
    const { uzytkownik } = this.state;
    let err = '';
    try {
      if (uzytkownik.imie.length === 0) {
        err = 'Imie jest wymagane';
        throw err;
      }
      if (uzytkownik.nazwisko.length === 0) {
        err = 'Nazwisko jest wymagane';
        throw err;
      }
      if (uzytkownik.telefon.length === 0) {
        err = 'telefon jest wymagany';
        throw err;
      }
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gim.test(
          uzytkownik.email,
        )
      ) {
        err = 'błędny email';
        throw err;
      }
      if (uzytkownik.login.length === 0) {
        err = 'Błędny login';
        throw err;
      }
      return true;
    } catch (error) {
      addAlert({
        title: 'Błąd!',
        message: error,
        type: 'danger',
      });
      return false;
    }
  }

  zapisz() {
    const { zapiszUzytkownika } = this.props;
    const { uzytkownik } = this.state;
    if (this.validationUser()) {
      zapiszUzytkownika(uzytkownik);
    }
  }

  render() {
    const { uzytkownik } = this.state;
    const { edycja, zapiszUzytkownika } = this.props;
    return (
      <Table scrolled view="uzytkownik">
        <Cell head>Imię</Cell>
        <Cell head>Nazwisko</Cell>
        <Cell head>Telefon</Cell>
        <Cell head>Email</Cell>
        <Cell head>Login</Cell>
        <Cell head>Status</Cell>

        <Cell head center>
          Faktura
        </Cell>

        <Cell head />
        <Cell>
          <InputDania
            value={uzytkownik.imie}
            type="text"
            name="imie"
            onChange={e => this.handleInputChange(e)}
          />
        </Cell>
        <Cell>
          <InputDania
            value={uzytkownik.nazwisko}
            type="text"
            name="nazwisko"
            onChange={e => this.handleInputChange(e)}
          />
        </Cell>
        <Cell>
          <InputDania
            value={uzytkownik.telefon}
            type="tel"
            name="telefon"
            onChange={e => this.handleInputChange(e)}
          />
        </Cell>
        <Cell>
          <InputDania
            value={uzytkownik.email}
            type="email"
            name="email"
            onChange={e => this.handleInputChange(e)}
          />
        </Cell>
        <Cell>
          <InputDania
            value={uzytkownik.login}
            type="text"
            name="login"
            onChange={e => this.handleInputChange(e)}
          />
        </Cell>
        <Cell>
          <select name="status" value={uzytkownik.status} onChange={e => this.handleInputChange(e)}>
            <option value={0}>Nieaktywny</option>
            <option value={1}>Aktywny</option>
          </select>
        </Cell>
        {edycja ? (
          <Cell center centerH>
            <input
              type="checkbox"
              name="faktura"
              checked={uzytkownik.faktura}
              onChange={() => this.setFaktura()}
            />
          </Cell>
        ) : (
          <Cell center centerH>
            <input
              type="checkbox"
              name="faktura"
              checked={uzytkownik.faktura}
              onChange={e => this.handleInputChange(e)}
            />
          </Cell>
        )}

        <Cell center thin>
          {uzytkownik.id === 0 && (
            <AddNew onClick={() => this.zapisz()}>
              <Obraz src={plus} alt="Dodaj użytkownika" />
            </AddNew>
          )}
          {uzytkownik.id > 0 && (
            <AddNew onClick={() => zapiszUzytkownika(uzytkownik)}>
              <Obraz src={whiteTick} alt="Zapisz zmiany" />
            </AddNew>
          )}
        </Cell>
      </Table>
    );
  }
}

EdytujUzytkownika.propTypes = {
  uzytkownik: PropTypes.shape({
    imie: PropTypes.string,
    nazwisko: PropTypes.string,
    email: PropTypes.string,
    telefon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    login: PropTypes.string,
    id: PropTypes.number,
    faktura: PropTypes.number,
  }),
  edycja: PropTypes.bool,
  firmaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zapiszUzytkownika: PropTypes.func,
};

EdytujUzytkownika.defaultProps = {
  uzytkownik: {
    imie: '',
    nazwisko: '',
    email: '',
    telefon: '',
    login: '',
    id: 0,
    faktura: 0,
  },
  edycja: false,
  firmaId: 0,
  zapiszUzytkownika: null,
};

export default EdytujUzytkownika;
