import React, { useEffect, useState } from 'react';
import { fetchContractors, pobierzFirmaByIdAction } from 'services/admin/firmyServices';
import { fetchDania, fetchRevenues } from 'services/admin/daniaServices';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import Heading from 'components/atoms/Heading/Heading';
import styles from 'views/Admin/Users.module.scss';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10rem 5rem;
  gap: 1rem;
  align-content: start;
  @media (max-width: 1200px) {
    display: block;
    // height: 100%;
    padding: 12rem 0rem;
  }
  @media (max-width: 1500px) and (min-width: 1200px) {
    padding: 12rem 0rem;
  }
`;
const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding: 1rem 1rem 4rem 1rem;
`;
const StyledHeader = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`;
const StyledRevenues = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
`;
const cashFormatter = cell => {
  return `${cell} zł`;
};
const columnsDishes = [
  {
    dataField: 'id',
    text: 'Id',
    sort: true,
    headerStyle: { width: '50px' },
  },
  {
    dataField: 'nazwa',
    text: 'Nazwa',
    sort: true,
  },
  {
    dataField: 'ilosc',
    sort: true,
    text: 'Ilość dań',
  },
  {
    dataField: 'suma',
    sort: true,
    formatter: cashFormatter,
    text: 'Suma',
  },
];
const columnsContractor = [
  {
    dataField: 'id',
    text: 'Id',
    sort: true,
    headerStyle: { width: '50px' },
  },
  {
    dataField: 'nazwa',
    text: 'Nazwa',
    sort: true,
  },
  {
    dataField: 'ilosc',
    sort: true,
    text: 'Ilość dań',
  },
  {
    dataField: 'suma',
    sort: true,
    formatter: cashFormatter,
    text: 'Suma',
  },
];

const ControlPanel = () => {
  const [kontrahenci, setKontrahenci] = useState([]);
  const [kontrahent, setKontrahent] = useState(null);
  const [dania, setDania] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [prevType, setPrevType] = useState('');
  const [prevContractor, setPrevContractor] = useState(0);
  const history = useHistory();
  const { type, contractor } = useParams();

  const fetchAll = () => {
    if (prevType !== type) {
      setPrevType(type);
      fetchContractors(type).then(res => {
        setKontrahenci(res);
      });
      fetchDania(type).then(res => {
        setDania(res);
      });
      fetchRevenues(type).then(res => {
        setRevenues(res);
      });
    }
  };

  const fetchContractor = () => {
    if (prevContractor !== contractor) {
      setPrevContractor(contractor);
      pobierzFirmaByIdAction(contractor).then(res => {
        setKontrahent(res);
      });
      fetchDania(type, contractor).then(res => {
        setDania(res);
      });
      fetchRevenues(type, contractor).then(res => {
        setRevenues(res);
      });
    }
  };

  useEffect(() => {
    if (!contractor) {
      setKontrahent(null);
      fetchAll();
    } else {
      fetchContractor();
    }
  });

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/admin/${type}/${row.id}`);
    },
  };

  return (
    <>
      <StyledWrapper>
        {kontrahent && (
          <StyledHeader>
            <Heading rank={1}>{kontrahent.nazwa}</Heading>
          </StyledHeader>
        )}

        {!contractor && (
          <StyledSection>
            <Heading rank={2}>Kontrahenci</Heading>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={kontrahenci}
              columns={columnsContractor}
              striped
              hover
              condensed
              rowEvents={rowEvents}
              rowClasses={styles.row}
            />
          </StyledSection>
        )}

        <StyledSection>
          <Heading rank={2}>Dania</Heading>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={dania}
            columns={columnsDishes}
            striped
            hover
            condensed
          />
        </StyledSection>
        <StyledSection>
          <Heading rank={2}>Przychody</Heading>
          {revenues.length ? (
            <StyledRevenues>
              <div>
                {' '}
                <Heading rank={2}>Gotówka</Heading>
                {revenues
                  .filter(item => item.formaPlatnosci === 'gotowka')
                  .map(item => (
                    <Heading key={1} rank={2}>
                      {item.cena} zł
                    </Heading>
                  ))}
              </div>
              <div>
                {' '}
                <Heading rank={2}>Karta</Heading>
                {revenues
                  .filter(item => item.formaPlatnosci === 'karta')
                  .map(item => (
                    <Heading key={2} rank={2}>
                      {item.cena} zł
                    </Heading>
                  ))}
              </div>
              <div>
                {' '}
                <Heading rank={2}>Faktura</Heading>
                {revenues
                  .filter(item => item.formaPlatnosci === 'faktura')
                  .map(item => (
                    <Heading key={3} rank={2}>
                      {item.cena} zł
                    </Heading>
                  ))}
              </div>

              <div>
                {' '}
                <Heading rank={2}>Obiady</Heading>
                {revenues
                  .filter(item => item.formaPlatnosci === 'suma')
                  .map(item => (
                    <Heading key={4} rank={2}>
                      {`${item.ilosc}`}
                    </Heading>
                  ))}
              </div>
              <div>
                {' '}
                <Heading rank={2}>Suma</Heading>
                {revenues
                  .filter(item => item.formaPlatnosci === 'suma')
                  .map(item => {
                    return (
                      <Heading key={5} rank={2}>
                        {item.cena ? `${item.cena}  zł` : '0'}
                      </Heading>
                    );
                  })}
              </div>
            </StyledRevenues>
          ) : null}
        </StyledSection>
      </StyledWrapper>
    </>
  );
};

export default ControlPanel;
