import React, { useEffect } from 'react';
import KierowcaTemplate from 'templates/KierowcaTemplate';
import ZamowienieKontrahenci from 'components/molecules/ZamowienieKontrahenci/ZamowienieKontrahenci';
import { pobierzKierowcaFirmyAction } from 'services/kierowcaZamowieniaService';
import { useParams, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import { WybraniKontrahenciWrapper, Btn } from './style';

const StyledNavLink = styled(NavLink)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WybraniKontrahenci = ({ kontrahenci, pobierzFirmy }) => {
  const { id } = useParams();

  const StyledButton = styled(Button)`
    margin-top: 2rem;
  `;

  useEffect(() => {
    pobierzFirmy(id);
  }, []);

  return (
    <KierowcaTemplate>
      <StyledHeadWrapper>
        <StyledButton nopadding onClick={() => pobierzFirmy(id)}>
          Odśwież
        </StyledButton>
      </StyledHeadWrapper>

      <WybraniKontrahenciWrapper>
        {kontrahenci.length !== 0 &&
          kontrahenci.map(kontrahent => (
            <Btn
              as={StyledNavLink}
              to={`${id}/kontrahent/${kontrahent.idContractor}${
                kontrahent.oddzialId ? `/${kontrahent.oddzialId}` : ''
              }`}
              key={`${kontrahent.idContractor}_${kontrahent.oddzialId}`}
              type="button"
            >
              <ZamowienieKontrahenci
                key={kontrahent.idContractor}
                nazwa={`${kontrahent.contractorName} ${
                  kontrahent.miasto !== 'Bielsko-Biała' ? `(${kontrahent.miasto})` : ''
                }${kontrahent.oddzial ? `(${kontrahent.oddzial})` : ''}`}
                ilosc={kontrahent.dishNumber}
                zupy={kontrahent.zupyNumber}
                smoothie={kontrahent.smoothieNumber}
                salatki={kontrahent.salatkiNumber}
                deser={kontrahent.deserNumber}
                glowne={kontrahent.glowneNumber}
                doOplacenia={kontrahent.paymentCount}
              />
            </Btn>
          ))}
        <ZamowienieKontrahenci
          key={-1}
          nazwa="Suma"
          visibility
          ilosc={kontrahenci.reduce((partialSum, a) => partialSum + a.dishNumber, 0)}
          zupy={kontrahenci.reduce((partialSum, a) => partialSum + a.zupyNumber, 0)}
          smoothie={kontrahenci.reduce((partialSum, a) => partialSum + a.smoothieNumber, 0)}
          salatki={kontrahenci.reduce((partialSum, a) => partialSum + a.salatkiNumber, 0)}
          deser={kontrahenci.reduce((partialSum, a) => partialSum + a.deserNumber, 0)}
          glowne={kontrahenci.reduce((partialSum, a) => partialSum + a.glowneNumber, 0)}
          doOplacenia={kontrahenci.reduce((partialSum, a) => partialSum + a.paymentCount, 0)}
        />
      </WybraniKontrahenciWrapper>
    </KierowcaTemplate>
  );
};

WybraniKontrahenci.propTypes = {
  kontrahenci: PropTypes.arrayOf(PropTypes.shape({})),
  pobierzFirmy: PropTypes.func,
};

WybraniKontrahenci.defaultProps = {
  kontrahenci: [],
  pobierzFirmy: null,
};

const mapStateToProps = state => ({
  kontrahenci: state.kierowcaReducer.kontrahenci,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzFirmy: pobierzKierowcaFirmyAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(WybraniKontrahenci);
