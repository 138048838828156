import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavLinkStyled = styled(NavLink)`
  background: black;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.6rem;
  font-weight: 700;
  display: block;
  max-width: 320px;
  margin: 1rem auto;
  padding: 1rem;
  border: 0.4rem solid #000;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #000;
    background: #fff;
  }
`;

class NawigacjaLogin extends React.Component {
  state = {};

  render() {
    const { uprawnienia } = this.props;
    return (
      <nav>
        {uprawnienia && (
          <Ul>
            {(uprawnienia.klient || uprawnienia.super_user) && (
              <li>
                <NavLinkStyled exact to="/zamow-posilek">
                  Zamów posiłek
                </NavLinkStyled>
              </li>
            )}
            {(uprawnienia.kierowca || uprawnienia.super_user) && (
              <li>
                <NavLinkStyled to="/kierowca">Wyjazdy</NavLinkStyled>
              </li>
            )}
            {(uprawnienia.kuchnia || uprawnienia.super_user) && (
              <>
                <li>
                  <NavLinkStyled to="/kuchnia">Kuchnia (dowóz)</NavLinkStyled>
                </li>
              </>
            )}
            {uprawnienia.super_user && (
              <li>
                <NavLinkStyled to="/admin/dzien">Admin</NavLinkStyled>
              </li>
            )}
          </Ul>
        )}
      </nav>
    );
  }
}

NawigacjaLogin.defaultProps = {
  uprawnienia: null,
};
NawigacjaLogin.propTypes = {
  uprawnienia: PropTypes.shape({
    admin_firma: PropTypes.string,
    kierowca: PropTypes.string,
    klient: PropTypes.string,
    kuchnia: PropTypes.string,
    super_user: PropTypes.string,
  }),
};

export default NawigacjaLogin;
