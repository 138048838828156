import {
  FETCH_KIEROWCA_ZAMOWIENIA_ERROR,
  FETCH_KIEROWCA_ZAMOWIENIA_PENDING,
  FETCH_KIEROWCA_ZAMOWIENIA_SUCCESS,
  FETCH_KIEROWCA_FIRMY_ERROR,
  FETCH_KIEROWCA_FIRMY_PENDING,
  FETCH_KIEROWCA_FIRMY_SUCCESS,
  FETCH_KIEROWCA_GODZINY_ERROR,
  FETCH_KIEROWCA_GODZINY_PENDING,
  FETCH_KIEROWCA_GODZINY_SUCCESS,
  FETCH_KIEROWCA_STATUS_ERROR,
  FETCH_KIEROWCA_STATUS_PENDING,
  FETCH_KIEROWCA_STATUS_SUCCESS,
} from 'actions/kierowcaActions';

const initialState = {
  pending: false,
  error: null,
  zamowienia: [],
  godziny: [],
  kontrahenci: [],
};

const kierowcaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KIEROWCA_ZAMOWIENIA_PENDING:
    case FETCH_KIEROWCA_GODZINY_PENDING:
    case FETCH_KIEROWCA_FIRMY_PENDING:
    case FETCH_KIEROWCA_STATUS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_KIEROWCA_ZAMOWIENIA_ERROR:
    case FETCH_KIEROWCA_GODZINY_ERROR:
    case FETCH_KIEROWCA_FIRMY_ERROR:
    case FETCH_KIEROWCA_STATUS_ERROR:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case FETCH_KIEROWCA_ZAMOWIENIA_SUCCESS:
      return {
        ...state,
        pending: false,
        zamowienia: action.data,
      };
    case FETCH_KIEROWCA_GODZINY_SUCCESS:
      return {
        ...state,
        pending: false,
        godziny: action.data.hours,
      };
    case FETCH_KIEROWCA_FIRMY_SUCCESS:
      return {
        ...state,
        pending: false,
        kontrahenci: action.data.contractors,
      };
    case FETCH_KIEROWCA_STATUS_SUCCESS:
      return {
        ...state,
        pending: false,
        kontrahenci: action.data.status,
      };
    default:
      return state;
  }
};

export default kierowcaReducer;
