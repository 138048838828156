import {
  WYSLIJ_ZAMOWIENIA_PENDING,
  WYSLIJ_ZAMOWIENIA_SUCCESS,
  WYSLIJ_ZAMOWIENIA_ERROR,
  POBIERZ_ZAMOWIENIA_PENDING,
  POBIERZ_ZAMOWIENIA_SUCCESS,
  POBIERZ_ZAMOWIENIA_ERROR,
  WYCZYSC_ZAMOWIENIE_SUCCESS,
  PRZYPISZ_ZAMOWIENIE_PENDING,
  PRZYPISZ_ZAMOWIENIE_SUCCESS,
  PRZYPISZ_ZAMOWIENIE_ERROR,
} from 'actions/zamowieniaActions';

const initialState = {
  pending: false,
  error: null,
  numerZamowienia: 0,
  zamowienia: [],
};

const zamowieniaReducer = (state = initialState, action) => {
  switch (action.type) {
    case WYSLIJ_ZAMOWIENIA_PENDING:
      return {
        ...state,
        pendingWyslij: true,
      };
    case WYSLIJ_ZAMOWIENIA_SUCCESS:
      return {
        ...state,
        pendingWyslij: false,
        numerZamowienia: action.numerZamowienia,
      };
    case WYSLIJ_ZAMOWIENIA_ERROR:
      return {
        ...state,
        pendingWyslij: false,
        error: action.error,
      };
    case POBIERZ_ZAMOWIENIA_PENDING:
      return {
        ...state,
        pendingPobierz: true,
      };
    case POBIERZ_ZAMOWIENIA_SUCCESS:
      return {
        ...state,
        pendingPobierz: false,
        zamowienia: action.zamowienia,
      };
    case POBIERZ_ZAMOWIENIA_ERROR:
      return {
        ...state,
        pendingPobierz: false,
        error: action.error,
      };
    case WYCZYSC_ZAMOWIENIE_SUCCESS:
      return {
        ...state,
        numerZamowienia: -1,
      };
    case PRZYPISZ_ZAMOWIENIE_SUCCESS:
      return {
        ...state,
        pendingPrzypisz: false,
        zamowienia: action.zamowienia,
      };
    case PRZYPISZ_ZAMOWIENIE_ERROR:
      return {
        ...state,
        pendingPrzypisz: false,
        error: action.error,
      };
    case PRZYPISZ_ZAMOWIENIE_PENDING:
      return {
        ...state,
        numerZamowienia: -1,
      };
    /* case 'REMOVE':
      return {
        ...state,
        dania: [...state.dania.filter(item => item.id !== action.payload.id)],
      }; */
    default:
      return state;
  }
};

export default zamowieniaReducer;
