import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'components/atoms/Button/Button';
import styled from 'styled-components';
import styles from './TwojeZamowienie.module.scss';

const ButtonUwagi = styled(Button)`
  padding: 0.2rem 0.5rem;
  margin: 0.5rem;
`;

const Ol = styled.ol`
  padding-left: 20px;
  li input {
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    outline: none;
  }
`;

const TwojeZamowienie = ({
  dodaneId,
  dodaneTitle,
  dodaneCounter,
  dodaneCena,
  uwagi,
  handleChange,
}) => {
  const [aktywny, setAktywny] = useState(0);
  return (
    <Row className={styles.wrapper} klucz={dodaneId}>
      <Col md={8} xs={6} className={styles.produkt}>
        <ButtonUwagi type="button" onClick={() => setAktywny(!aktywny)}>
          uwagi
        </ButtonUwagi>{' '}
        {dodaneTitle}
        <Ol style={{ display: aktywny ? 'block' : 'none' }}>
          {uwagi.map(uwaga => {
            return (
              <li key={`uwaga_${uwaga.id}`}>
                <input
                  type="text"
                  value={uwaga.value}
                  onChange={e => handleChange(e, dodaneId, uwaga.id)}
                  placeholder="uwagi dotyczące tego dania"
                />
              </li>
            );
          })}
        </Ol>
      </Col>
      <Col md={2} xs={3} className={styles.ilosc}>
        {dodaneCounter}
      </Col>
      <Col md={2} xs={3} className={styles.cena}>
        {dodaneCena} zł
      </Col>
    </Row>
  );
};

TwojeZamowienie.propTypes = {
  dodaneId: PropTypes.number.isRequired,
  dodaneTitle: PropTypes.string.isRequired,
  dodaneCounter: PropTypes.number.isRequired,
  dodaneCena: PropTypes.number.isRequired,
  uwagi: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  handleChange: PropTypes.func,
};
TwojeZamowienie.defaultProps = {
  uwagi: [null],
  handleChange: null,
};

export default TwojeZamowienie;
