import {
  FETCH_DANIA_KUCHNIA_PENDING,
  FETCH_DANIA_KUCHNIA_SUCCESS,
  FETCH_DANIA_KUCHNIA_ERROR,
  POBIERZ_ZAMOWIENIA_KUCHNIA_SUCCESS,
} from 'actions/kuchniaActions';

const initialState = {
  pending: false,
  error: null,
  dania: [],
  zamowienia: null,
  menu: null,
};

const kuchniaReducer = (state = initialState, action) => {
  // console.log(state);
  let zamowienia = null;
  switch (action.type) {
    case FETCH_DANIA_KUCHNIA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_DANIA_KUCHNIA_SUCCESS:
      return {
        ...state,
        pending: false,
        dania: action.dania,
        menu: action.menu,
      };
    case FETCH_DANIA_KUCHNIA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POBIERZ_ZAMOWIENIA_KUCHNIA_SUCCESS:
      if (action.zamowienia) {
        zamowienia = action.zamowienia;
      }
      return {
        ...state,
        pending: false,
        zamowienia,
        aktualizacja: action.aktualizacja,
        podsumowanieDania: action.podsumowanieDania,
        // menu: action.menu,
      };
    default:
      return state;
  }
};

export default kuchniaReducer;
