import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fSize.h1};
  padding-left: 0.5rem;
  @media (max-width: 991px) {
    font-size: 5rem;
    margin: 1rem 0;
  }
`;

const H2 = styled.h2`
  font-size: ${({ theme }) => theme.fSize.h2};
`;

const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fSize.h3};
`;

const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fSize.h4};
`;

const H5 = styled.h5``;

const H6 = styled.h6``;

const Heading = ({ rank, children, className }) => {
  switch (rank) {
    case 1:
      return <H1 className={className}>{children}</H1>;
    case 2:
      return <H2 className={className}>{children}</H2>;
    case 3:
      return <H3 className={className}>{children}</H3>;
    case 4:
      return <H4 className={className}>{children}</H4>;
    case 5:
      return <H5 className={className}>{children}</H5>;
    case 6:
      return <H6 className={className}>{children}</H6>;
    default:
      return <H2 className={className}>{children}</H2>;
  }
};

Heading.propTypes = {
  rank: PropTypes.number,
  children: PropTypes.string,
  // żeby nadpisać style własnego styled-componentu trzeba przekazać prop className
  className: PropTypes.string,
};

Heading.defaultProps = {
  rank: 2,
  children: '',
  className: 'heading',
};

export default Heading;
