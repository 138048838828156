import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import obrazkiDan from 'components/atoms/Obraz/ObrazkiDan';
import obrazkiBadge from 'components/atoms/Obraz/ObrazkiBadge';

import tick from 'assets/svg/akcept.svg';
import Obraz from 'components/atoms/Obraz/Obraz';
import minus from 'assets/svg/minus.svg';
import Button from 'components/atoms/Button/Button';

import { zapiszDanieWMenu } from 'services/admin/daniaServices';
import { usunZMenu } from 'services/admin/menuServices';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  font-weight: ${({ head }) => (head ? 700 : 400)};
  position: relative;
  label {
    &:hover {
      cursor: pointer;
    }
    img {
      position: relative;
      z-index: 2;
    }
  }
`;

const NazwaDania = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
  width: 100%;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  &:hover {
    border-color: black;
  }
  &:focus {
    outline: none;
    border-color: black;
  }
`;
const Select = styled.select`
  width: 100%;
  border: 1px solid #80cf26;
  border-radius: 0.5rem;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

const Wege = styled(Obraz)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
`;

const AddNew = styled(Button)`
  border-width: 0;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
`;

const InputCheckbox = styled.input`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledLabel = styled.label`
  z-index: 0;
`;

class DaniaAdminMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      danieData: {
        danieId: props.danieId,
        index: props.index,
        nazwa: props.nazwa,
        opis: props.opis,
        kolejnosc: props.kolejnosc,
        rodzaj: props.rodzaj,
        dodatekId: props.dodatekId,
        dodatek2Id: props.dodatek2Id,
        typWMenu: props.typWMenu,
        danieDniaStatus: props.danieDniaStatus,
        cena: props.cena,
        wege: props.wege,
        dostepnaIlosc: props.dostepnaIlosc,
        kodDania: props.kodDania,
        menuId: props.menuId,
        nazwaKategorii: props.nazwaKategorii,
        changed: false,
      },
    };
  }

  handleDanieDnia() {
    const { menuId } = this.props;
    const { danieData } = this.state;
    const { danieDniaStatus } = danieData;
    this.setState(prevState => ({
      ...prevState,
      danieData: {
        ...prevState.danieData,
        danieDniaStatus: danieDniaStatus > 0 ? 0 : 1,
      },
    }));
    danieData.danieDniaStatus = danieDniaStatus > 0 ? 0 : 1;
    zapiszDanieWMenu(danieData, menuId);
  }

  handleBlur() {
    const { menuId } = this.props;
    const { danieData } = this.state;
    const { changed } = danieData;
    if (changed && danieData.danieId) {
      zapiszDanieWMenu(danieData, menuId);
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      danieData: {
        ...prevState.danieData,
        [name]: value,
        changed: true,
      },
    }));
  }

  render() {
    const { danieData } = this.state;
    const {
      danieId,
      // index,
      nazwa,
      opis,
      kolejnosc,
      dodatekId,
      dodatek2Id,
      cena,
      wege,
      kodDania,
      dostepnaIlosc,
      typWMenu,
      danieDniaStatus,
      menuId,
      nazwaKategorii,
    } = danieData;

    // console.log(danieData);

    const { usunZMenuService, dodatkiDan, dodatkiDan2, zamowienia } = this.props;
    // console.log(obrazkiDan['Napoje Zimne']);
    return (
      <>
        <Cell center>
          <Input
            type="text"
            name="kolejnosc"
            value={kolejnosc}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          />
        </Cell>
        <Cell center>
          <StyledLabel htmlFor={danieId}>
            {obrazkiBadge[typWMenu] && danieDniaStatus > 0 && (
              <img width="42px" src={obrazkiBadge[typWMenu]} alt={nazwa} title={typWMenu} />
            )}
            <InputCheckbox
              type="checkbox"
              name="danieDniaStatus"
              id={danieId}
              onChange={e => this.handleDanieDnia(e)}
              checked={danieDniaStatus > 0}
            />
          </StyledLabel>
        </Cell>
        <Cell>
          <NazwaDania>
            <span>
              <strong>{nazwa}</strong>
            </span>
            <span>{kodDania !== '' ? `(${kodDania})` : ''}</span>
          </NazwaDania>
        </Cell>
        <Cell>
          <Input
            type="text"
            name="opis"
            value={opis}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          />
        </Cell>
        <Cell>
          <Select
            name="dodatekId"
            value={dodatekId}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          >
            <option value={0}>wybierz</option>
            {dodatkiDan.map(dodatek => (
              <option key={dodatek.id} value={dodatek.id}>
                {dodatek.nazwa}
              </option>
            ))}
          </Select>
        </Cell>

        <Cell>
          <Select
            name="dodatek2Id"
            value={dodatek2Id}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          >
            <option value={0}>wybierz</option>
            {dodatkiDan2.map(dodatek => (
              <option key={dodatek.id} value={dodatek.id}>
                {dodatek.nazwa}
              </option>
            ))}
          </Select>
        </Cell>

        <Cell center>{wege !== 0 && <Wege src={tick} />}</Cell>

        <Cell center>
          <img
            width={obrazkiDan[nazwaKategorii] === obrazkiDan['Napoje Zimne'] ? '15px' : '26px'}
            src={obrazkiDan[nazwaKategorii] ? obrazkiDan[nazwaKategorii] : obrazkiDan.danie}
            alt={nazwa}
            title={nazwaKategorii}
          />
        </Cell>
        <Cell center>
          <Input
            center
            type="text"
            name="cena"
            value={cena}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
            /* value={`${this.state.cena.toFixed(2)} zł`} */
          />
        </Cell>
        <Cell center>
          <Input
            center
            type="text"
            name="dostepnaIlosc"
            value={dostepnaIlosc}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          />
        </Cell>

        <Cell center>
          {zamowienia ? (
            <AddNew onClick={() => usunZMenuService(menuId, danieId)}>
              <Obraz src={minus} alt="Usuń danie" />
            </AddNew>
          ) : (
            <AddNew onClick={() => usunZMenuService(menuId, danieId)}>
              <Obraz src={minus} alt="Usuń danie" />
            </AddNew>
          )}
        </Cell>
      </>
    );
  }
}

DaniaAdminMenu.propTypes = {
  index: PropTypes.number.isRequired,
  danieId: PropTypes.number.isRequired,
  nazwa: PropTypes.string.isRequired,
  opis: PropTypes.string.isRequired,
  rodzaj: PropTypes.string.isRequired,
  nazwaKategorii: PropTypes.string.isRequired,
  dodatekId: PropTypes.number,
  dodatek2Id: PropTypes.number,
  typWMenu: PropTypes.string.isRequired,
  danieDniaStatus: PropTypes.number.isRequired,
  cena: PropTypes.number.isRequired,
  kolejnosc: PropTypes.number.isRequired,
  dostepnaIlosc: PropTypes.number.isRequired,
  wege: PropTypes.number.isRequired,
  kodDania: PropTypes.string.isRequired,
  menuId: PropTypes.number.isRequired,
  usunZMenuService: PropTypes.func.isRequired,
  dodatkiDan: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rodzaj: PropTypes.string,
    }),
  ),
  dodatkiDan2: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rodzaj: PropTypes.string,
    }),
  ),
  zamowienia: PropTypes.objectOf(PropTypes.any),
};
DaniaAdminMenu.defaultProps = {
  dodatkiDan: [],
  dodatkiDan2: [],
  dodatekId: 0,
  dodatek2Id: 0,
  zamowienia: {},
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      usunZMenuService: usunZMenu,
    },
    dispatch,
  );
const mapStateToProps = state => ({
  dodatkiDan: state.daniaReducer.dodatkiDan,
  dodatkiDan2: state.daniaReducer.dodatkiDan2,
  zamowienia: state.kuchniaReducer.zamowienia,
});
export default connect(mapStateToProps, mapDispatchToProps)(DaniaAdminMenu);
