import axios from 'axios';
import { addAlert } from 'actions/alertActions';

import {
  fetchDaniaDoMenuPending,
  fetchDaniaDoMenuSuccess,
  fetchDaniaDoMenuError,
  pobierzDodatkiDanPending,
  pobierzDodatkiDanSuccess,
  pobierzDodatkiDan2Success,
  pobierzDodatkiDanError,
} from 'actions/daniaActions';

export const pobierzDaniaDoMenuByIdAction = menuId => dispatch => {
  dispatch(fetchDaniaDoMenuPending());
  return axios
    .get(`/api/admin/menu/by-id/${menuId || ''}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(fetchDaniaDoMenuSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(fetchDaniaDoMenuError(error));
    });
};

export const dodajDoMenu = (menuId, danieId) => dispatch => {
  // dispatch(authenticatePending());

  return axios
    .post('/api/admin/menu/przypisz-danie', { danieId, menuId })
    .then(() /* payload */ => {
      // console.log(payload);
      // weryfikować wchodzące dane
      addAlert({ title: 'Menu', message: 'Danie przypisane!', type: 'success' });
      dispatch(pobierzDaniaDoMenuByIdAction(menuId));
      // POBRAĆ JESZCZE RAZ MENU
      // dispatch(authenticateSuccess(payload));
      return true;
    })
    .catch(err => {
      addAlert({
        title: 'Menu',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      // dispatch(authenticateError());
    });
};

export const zapiszKomentarzMenu = (komentarz, menuId) => /* dispatch => */ {
  // dispatch(authenticatePending());

  return axios
    .post('/api/admin/menu/zapisz-komentarz', { komentarz, menuId })
    .then(() /* payload */ => {
      // console.log(payload);
      // weryfikować wchodzące dane
      addAlert({ title: 'Menu', message: 'Komentarz zapisany!', type: 'success' });
      // dispatch(pobierzDaniaDoMenuByIdAction(menuId));
      // POBRAĆ JESZCZE RAZ MENU
      // dispatch(authenticateSuccess(payload));
      return true;
    })
    .catch(err => {
      addAlert({
        title: 'Menu',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      // dispatch(authenticateError());
    });
};

export const usunZMenu = (menuId, danieId) => dispatch => {
  // dispatch(authenticatePending());

  return axios
    .post('/api/admin/menu/usun-danie', { danieId, menuId })
    .then((/* payload */) => {
      // /console.log(payload);
      // weryfikować wchodzące dane
      addAlert({ title: 'Menu', message: 'Danie zostało usunięte!', type: 'success' });

      dispatch(pobierzDaniaDoMenuByIdAction(menuId));
      // POBRAĆ JESZCZE RAZ MENU

      return true;
      // dispatch(authenticateSuccess(payload));
    })
    .catch(err => {
      addAlert({
        title: 'Menu',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      // dispatch(authenticateError());
    });
};

export const usunZZamowienia = (menuId, danieId) => dispatch => {
  // dispatch(authenticatePending());

  return axios
    .post('/api/admin/zamowienie/usun-danie', { danieId, menuId })
    .then((/* payload */) => {
      // /console.log(payload);
      // weryfikować wchodzące dane
      addAlert({ title: 'Menu', message: 'Danie zostało usunięte!', type: 'success' });

      dispatch(pobierzDaniaDoMenuByIdAction(menuId));
      // POBRAĆ JESZCZE RAZ MENU

      return true;
      // dispatch(authenticateSuccess(payload));
    })
    .catch(err => {
      addAlert({
        title: 'Menu',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      // dispatch(authenticateError());
    });
};

export const getDniMenu = miesiac => {
  return axios
    .get(`/api/admin/menu/miesiac/${miesiac}`)
    .then(payload => {
      return payload.data;
    })
    .catch(err => {
      addAlert({
        title: 'Kalendarz Menu',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const pobierzDodatkiDanAction = () => dispatch => {
  dispatch(pobierzDodatkiDanPending());
  return axios
    .get('/api/admin/dodatkiDan', {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(pobierzDodatkiDanSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(pobierzDodatkiDanError(error));
    });
};

export const pobierzDodatkiDan2Action = () => dispatch => {
  dispatch(pobierzDodatkiDanPending());
  return axios
    .get('/api/admin/dodatkiDan2', {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(pobierzDodatkiDan2Success(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(pobierzDodatkiDanError(error));
    });
};
