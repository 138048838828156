import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DaniaItem from 'components/molecules/Dania/DaniaItem';
import TwojeZamowienie from 'components/molecules/TwojeZamowienie/TwojeZamowienie';
import SumaZamowienia from 'components/molecules/TwojeZamowienie/SumaZamowienia';
import KomentarzZamowienie from 'components/molecules/TwojeZamowienie/KomentarzZamowienie';

// import { pobierzZamowieniaLokalAction } from 'services/barServices';

import Button from 'components/atoms/Button/Button';
import delivery from 'assets/svg/delivery.svg';
import Container from 'react-bootstrap/Container';
import gruszka1 from 'assets/images/gruszka2.png';
import gruszka2 from 'assets/images/gruszka3.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import KlientTemplate from 'templates/KlientTemplate';
import Payment from 'components/organisms/Payment/Payment';
import PaymentConfirmation from 'components/molecules/PaymentConfirmation/PaymentConfirmation';
import fetchDaniaAction from 'services/fetchDania';
import wyslijZamowienieAction from 'services/wyslijZamowienie';
import { checkUser as checkUserAction, pobierzMojaFirmaAction } from 'services/userServices';
// eslint-disable-next-line import/named
import {
  /* pobierzLokalDaniaAction */ pobierzKategorieDanAction,
} from 'services/admin/daniaServices';

import {
  pobierzListaFirmAction,
  pobierzFirmaByIdAction,
  pobierzUzytkownicyByFirmaIdAction,
} from 'services/admin/firmyServices';
import 'rc-time-picker/assets/index.css';

import moment from 'moment';

import {
  Flex,
  Section,
  H2,
  Wrapper,
  Summary,
  GruszkaPierwsza,
  GruszkaDruga,
} from 'views/Klient/style';

class Klient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zamowienie: [],
      submitButton: 'Zamawiam i płacę',
      formaPlatnosci: '',
      komentarz: '',
      rabatZupaZDaniem: 0,
      mojaFirma: { miasta: [] },
      oddzial: { id: 0, nazwa: '' },
      miasto: { id: 0, nazwa: '' },
      firmy: [],
      wybranaFirmaId: 0,
      godzinaWyjazduId: 0,
      wybranyUzytkownikId: 0,
      uzytkownicy: [],
      godzinaLokal: moment().format('HH:mm'),
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { fetchDania, pobierzKategorieDan, user, checkUser } = this.props;
    if (Object.entries(user).length === 0 && user.constructor === Object) {
      checkUser();
    }
    pobierzMojaFirmaAction().then(firma => {
      if (firma) {
        this.setState({ mojaFirma: firma, godzinaWyjazduId: firma.godzinaWyjazduId });
      }
    });
    fetchDania();
    // pobierzDania();
    pobierzKategorieDan();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { firmy, wybranaFirmaId } = this.state;
    /* eslint-disable */
    if (
      Object.keys(user).length === 0 &&
      user.constructor === Object &&
      prevState.constructor !== Object
    ) {
      this.setState({
        wybranaFirmaId: user.firmaId,
        wybranyUzytkownikId: user.userId,
      });
    }
    /* eslint-enable */
    if (user.uprawnienia && user.uprawnienia.super_user === 'super_user' && firmy.length === 0) {
      pobierzListaFirmAction().then(pobraneFirmy => {
        this.setState({ firmy: pobraneFirmy });
      });
    }

    if (prevState.wybranaFirmaId !== wybranaFirmaId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ zamowienie: [] });
    }
  }

  zlozZamowienie = () => {
    const { wyslijZamowienie, menu, user, /* pobierzZamowieniaLokal, */ fetchDania } = this.props;

    const {
      zamowienie,
      formaPlatnosci,
      komentarz,
      godzinaWyjazduId,
      godzinaLokal,
      wybranyUzytkownikId,
      oddzial,
    } = this.state;
    let { wybranaFirmaId } = this.state;
    if (wybranaFirmaId === 0) {
      wybranaFirmaId = user.firmaId;
    }

    wyslijZamowienie(
      {
        menuId: menu.id,
        wartosc: this.obliczWartoscZamowienia(),
        komentarz,
        formaPlatnosci,
        wybranaFirmaId,
        godzinaWyjazduId,
        godzinaLokal,
        wybranyUzytkownikId,
        oddzial,
      },
      zamowienie,
    ).then(() => {
      this.setState({ zamowienie: [], rabatZupaZDaniem: 0 });
      fetchDania();
    });
    this.setState({ zamowienie: [], rabatZupaZDaniem: 0 });
  };

  handleClickPlus = (props, count) => {
    const { zamowienie } = this.state;
    const dodawaneDanie = {
      key: props.id,
      dodaneId: props.id,
      dodatekId: props.dodatekId,
      dodatek2Id: props.dodatek2Id,
      dodaneTitle: props.nazwa,
      dodaneCounter: count + 1,
      dodaneCena: props.cena,
      dodaneRodzaj: props.rodzaj,
      uwagi: props.uwagi ? props.uwagi : [],
    };
    dodawaneDanie.uwagi.push({ id: 0, value: '' });

    if (zamowienie.some(el => el.dodaneId === props.id)) {
      this.setState(
        prevState => ({
          zamowienie: prevState.zamowienie.map(el => {
            if (el.dodaneId === props.id) {
              el.uwagi.push({ id: el.uwagi[el.uwagi.length - 1].id + 1, value: '' });
            }
            return el.dodaneId === props.id ? { ...el, dodaneCounter: el.dodaneCounter + 1 } : el;
          }),
        }),
        this.przyporzadkujZupy,
      );
    } else {
      this.setState(e => {
        const zam = [...e.zamowienie, dodawaneDanie];
        return {
          zamowienie: zam,
        };
      }, this.przyporzadkujZupy);
    }
  };

  handleClickMinus = props => {
    const { zamowienie } = this.state;

    if (zamowienie.some(el => el.dodaneId === props.id && el.dodaneCounter > 1)) {
      this.setState(
        prevState => ({
          zamowienie: prevState.zamowienie.map(el => {
            if (el.dodaneId === props.id) {
              el.uwagi.splice(el.uwagi.length - 1, 1);
            }
            return el.dodaneId === props.id ? { ...el, dodaneCounter: el.dodaneCounter - 1 } : el;
          }),
        }),
        this.przyporzadkujZupy,
      );
    } else {
      this.setState(e => {
        const zam = e.zamowienie.filter(item => item.dodaneId !== props.id);
        return {
          zamowienie: zam,
        };
      }, this.przyporzadkujZupy);
    }
    this.przyporzadkujZupy();
  };

  obliczWartoscZamowienia = () => {
    const { zamowienie, rabatZupaZDaniem } = this.state;
    let counter = rabatZupaZDaniem;

    return zamowienie.reduce((previousValue, currentValue) => {
      if (counter > 0 && currentValue.dodaneRodzaj === 'zupa') {
        let zupaSum = 0;
        let licznik = 0;
        for (let i = 0; i < currentValue.dodaneCounter; i += 1) {
          if (i < counter) {
            zupaSum += 3;
            licznik += 1;
          } else {
            zupaSum += currentValue.dodaneCena;
          }
        }
        counter -= licznik;
        return previousValue + zupaSum;
      }
      return previousValue + currentValue.dodaneCounter * currentValue.dodaneCena;
    }, 0);
  };

  aktualizujFormePltanosci = forma => {
    this.setState({ formaPlatnosci: forma });
  };

  updateKomentarz = e => {
    this.setState({
      komentarz: e.target.value,
    });
  };

  updateUwaga = (e, id, index) => {
    const nowaWartosc = e.target.value;
    this.setState(prevState => ({
      zamowienie: prevState.zamowienie.map(el =>
        el.dodaneId === id
          ? {
              ...el,
              uwagi: el.uwagi.map(uwaga =>
                uwaga.id === index ? { ...uwaga, value: nowaWartosc } : uwaga,
              ),
            }
          : el,
      ),
    }));
  };

  onMenuItemClicked = () => {
    this.setState({ wybranaFirmaId: 0 });
  };

  shouldComponentRender() {
    const { pendingWyslij } = this.props;
    if (pendingWyslij === false) return false;

    return true;
  }

  przyporzadkujZupy() {
    const { zamowienie } = this.state;
    const counts = {};
    zamowienie.map(item => {
      counts[item.dodaneRodzaj] = counts[item.dodaneRodzaj]
        ? counts[item.dodaneRodzaj] + item.dodaneCounter
        : item.dodaneCounter;
      return true;
    });
    if (!counts.danie) counts.danie = 0;
    if (!counts.salatka) counts.salatka = 0;
    if (!counts.zupa) counts.zupa = 0;
    let rabat = 0;
    if (counts.zupa <= counts.danie + counts.salatka) {
      rabat = counts.zupa;
    } else {
      rabat = counts.danie + counts.salatka;
    }
    this.setState({ rabatZupaZDaniem: rabat });
  }

  wybranyUzytkownik(e) {
    this.setState({ wybranyUzytkownikId: e.target.value });
  }

  wybranaFirma(e) {
    const { fetchDania } = this.props;

    this.setState({ wybranaFirmaId: e.target.value });

    if (e.target.value > 0) {
      fetchDania(e.target.value);
      pobierzFirmaByIdAction(e.target.value).then(firma => {
        if (firma.oddzialy) {
          if (firma.oddzialy.length < 2) {
            this.setState({ oddzial: { id: 0, nazwa: '' } });
          } else {
            this.setState({ oddzial: firma.oddzialy[0].id });
          }
        } else {
          this.setState({ oddzial: 0 });
        }

        this.setState({ mojaFirma: firma, godzinaWyjazduId: firma.godzinaWyjazduId });

        if (firma.lokal === 0) {
          pobierzUzytkownicyByFirmaIdAction(firma.id).then(uzytkownicy => {
            this.setState({
              uzytkownicy,
              wybranyUzytkownikId: uzytkownicy.length ? uzytkownicy[0].id : 0,
            });
          });
        } else {
          this.setState({
            uzytkownicy: [],
            wybranyUzytkownikId: 0,
          });
        }
      });
    }
  }

  wybranaGodzina(e) {
    this.setState({ godzinaWyjazduId: e.target.value });
  }

  wybranaoddzial(e) {
    this.setState({ oddzial: e.target.value });
  }

  wybraneMiasto(e) {
    this.setState({
      miasto: { nazwa: e.target.name, id: e.target.value },
      wybranaFirmaId: e.target.value,
    });

    pobierzUzytkownicyByFirmaIdAction(e.target.value).then(uzytkownicy => {
      this.setState({
        uzytkownicy,
        wybranyUzytkownikId: uzytkownicy.length ? uzytkownicy[0].id : 0,
      });
    });
  }

  wybranaGodzinaLokal(e) {
    const czas = e.target.value.split(':');

    if (czas[0] >= parseInt(moment().format('HH'), 10) && czas[0] < 21) {
      this.setState({ godzinaLokal: e.target.value });
    }
  }

  sprawdzUprawnieniaLokal() {
    const { user } = this.props;
    const { mojaFirma } = this.state;
    return (
      (mojaFirma && mojaFirma.lokal === 1) ||
      (user && user.uprawnienia && user.uprawnienia.lokal === 'lokal')
    );
  }

  render() {
    const {
      zamowienie,
      formaPlatnosci,
      submitButton,
      komentarz,
      rabatZupaZDaniem,
      firmy,
      wybranaFirmaId,
      godzinaWyjazduId,
      mojaFirma,
      uzytkownicy,
      wybranyUzytkownikId,
      oddzial,
      miasto,
    } = this.state;
    const { dania, menu, numerZamowienia, user } = this.props;

    return (
      <KlientTemplate
        dataMenu={menu && menu.dataMenu}
        title="Zamów posiłek"
        subtitle="Kliknij przycisk, aby dodać danie do Zamówienia"
      >
        <GruszkaPierwsza src={gruszka1} alt="" />
        <GruszkaDruga className="login_img" src={gruszka2} alt="" />

        {menu && dania && dania.length ? (
          <>
            <Section>
              <Container style={{ textAlign: 'center' }}>
                <Col>
                  {menu && menu.komentarz && <H2>{menu.komentarz}</H2>}

                  {/*  DODAĆ WARUNEK - TYLKO UPRAWNIENIA ADMINA  */}

                  {user.uprawnienia && user.uprawnienia.super_user === 'super_user' && (
                    <>
                      {firmy.length > 0 && (
                        <>
                          <H2>Wybierz klienta</H2>
                          <select
                            name="firmy"
                            value={wybranaFirmaId}
                            onChange={e => this.wybranaFirma(e)}
                            id="firmySelect"
                          >
                            <option value="0">wybierz firmę</option>
                            {firmy.map(item => (
                              <option key={`${item.id}`} value={item.id}>
                                {item.nazwa}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                      {mojaFirma && mojaFirma.oddzialy && mojaFirma.oddzialy.length > 1 && (
                        <>
                          <H2>Wybierz Placówkę</H2>
                          <select
                            name="oddzialy"
                            value={oddzial.nazwa}
                            onChange={e => this.wybranaoddzial(e)}
                            id="firmySelect"
                            placeholder="Wybierz"
                          >
                            <option key={0} value={0} disabled name="Wybierz placówkę">
                              Wybierz placówkę
                            </option>
                            {mojaFirma.oddzialy.map(item => (
                              <option key={item.id} value={item.id} name={item.nazwa}>
                                {item.nazwa}
                              </option>
                            ))}
                          </select>
                        </>
                      )}

                      {uzytkownicy.length > 0 && (
                        <>
                          <H2>Wybierz użytkownika</H2>
                          <select
                            name="uzytkownicy"
                            value={wybranyUzytkownikId}
                            onChange={e => this.wybranyUzytkownik(e)}
                            id="firmySelect"
                          >
                            {uzytkownicy.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.imie} {item.nazwisko}
                              </option>
                            ))}
                          </select>
                        </>
                      )}

                      {/* <StyledEdytujFirme /> */}
                    </>
                  )}
                  {/*  DODAĆ WYBÓR GODZINY DOSTAWY  */}
                  {mojaFirma && mojaFirma.miasta && mojaFirma.miasta.length > 1 && (
                    <>
                      <H2>Wybierz Placówkę</H2>
                      <select name="miasta" value={miasto.id} onChange={e => this.wybraneMiasto(e)}>
                        {mojaFirma.miasta.map(item => (
                          <option key={item.id} value={item.id} id={item.id} name={item.miasto}>
                            {item.miasto}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {mojaFirma && (
                    <>
                      <H2>Wybierz godzinę dostawy</H2>
                      {mojaFirma.godzinyWajazdu && mojaFirma.godzinyWajazdu.length > 0 ? (
                        <select
                          name="godzinaWyjazduId"
                          value={godzinaWyjazduId}
                          onChange={e => this.wybranaGodzina(e)}
                          id="godzinaWyjazduId"
                        >
                          {mojaFirma.godzinyWajazdu.map(item => {
                            if (item.firmaId === mojaFirma.id) {
                              return (
                                <option key={`${item.id}`} value={item.id}>
                                  {item.uKlienta !== ''
                                    ? `Godzina dostawy: ${item.uKlienta}`
                                    : `Godzina wyjazdu: ${item.godzinaWyjazdu}`}
                                </option>
                              );
                            }
                            return '';
                          })}
                        </select>
                      ) : (
                        <p>Brak dostępnych godzin dostawy</p>
                      )}
                    </>
                  )}
                </Col>
                <Flex>
                  <Col>
                    <Flex>
                      {/* eslint-disable react/jsx-props-no-spreading */ dania.map(item => (
                        <DaniaItem
                          key={`${item.id}_${menu.timestamp}`}
                          onClickPlus={this.handleClickPlus}
                          onClickMinus={this.handleClickMinus}
                          index={dania.indexOf(item)}
                          {...item}
                        />
                      ))}
                    </Flex>
                  </Col>
                </Flex>
              </Container>
            </Section>
            <Container>
              <Row>
                <Col xl={{ span: 10, offset: 1 }}>
                  <Wrapper>
                    <H2 rank={2}>Twoje zamówienie</H2>
                    <Summary>
                      <Container fluid>
                        {/* eslint-disable react/jsx-props-no-spreading */
                        zamowienie.map(item => (
                          <TwojeZamowienie
                            key={item.key}
                            {...item}
                            handleChange={this.updateUwaga}
                          />
                        ))}
                        <SumaZamowienia
                          rabatFirmy={mojaFirma.rabat ? mojaFirma.rabat : 0}
                          rabatZupaZDaniem={rabatZupaZDaniem}
                          razem={
                            zamowienie
                              ? zamowienie.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.dodaneCounter,
                                  0,
                                )
                              : 0
                          }
                          suma={zamowienie ? this.obliczWartoscZamowienia() : 0}
                        />
                        <KomentarzZamowienie handleChange={this.updateKomentarz} text={komentarz} />
                      </Container>
                    </Summary>

                    {wybranaFirmaId === 0 ? (
                      <Payment
                        klik={this.aktualizujFormePltanosci}
                        isFaktura={user.faktura}
                        isDisabled={
                          zamowienie.length === 0 ||
                          (mojaFirma.id === 0 &&
                            (wybranaFirmaId === 0 || wybranyUzytkownikId === 0)) ||
                          godzinaWyjazduId === 0
                        }
                      />
                    ) : (
                      <Payment klik={this.aktualizujFormePltanosci} isFaktura={user.faktura} />
                    )}
                    <Button
                      margin="0px 0px 0px auto"
                      disabled={
                        formaPlatnosci.length === 0 ||
                        zamowienie.length === 0 ||
                        (mojaFirma.id === 0 &&
                          (wybranaFirmaId === 0 || wybranyUzytkownikId === 0)) ||
                        godzinaWyjazduId === 0
                      }
                      active={zamowienie.length !== 0 && formaPlatnosci.length !== 0}
                      onClick={e => {
                        this.setState({
                          wybranyUzytkownikId: 0,
                          wybranaFirmaId: 0,
                          formaPlatnosci: '',
                          uzytkownicy: [],
                        });
                        if (user.uprawnienia && user.uprawnienia.super_user === 'super_user') {
                          this.setState({ mojaFirma: { miasta: [] } });
                        }

                        this.zlozZamowienie(e);
                      }}
                    >
                      {submitButton}
                    </Button>
                  </Wrapper>
                </Col>
              </Row>
            </Container>
            <PaymentConfirmation
              klik={numerZamowienia > 0}
              src={delivery}
              alt="Zamówienie zostało przyjęte"
            >
              Twoje zamówienie zostało przyjęte!
            </PaymentConfirmation>
          </>
        ) : (
          <Section>
            <Container style={{ textAlign: 'center' }}>
              <h2>Menu nie zostało na dzisiaj przygotowane!</h2>
            </Container>
          </Section>
        )}
      </KlientTemplate>
    );
  }
}

Klient.propTypes = {
  numerZamowienia: PropTypes.number,
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      nazwa: PropTypes.string.isRequired,
    }),
  ),
  daniaLokal: PropTypes.arrayOf(
    PropTypes.shape({
      nazwa: PropTypes.string,
    }),
  ),
  kategorieDan: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  menu: PropTypes.shape({
    dataMenu: PropTypes.string,
    id: PropTypes.number,
    timestamp: PropTypes.number,
    komentarz: PropTypes.string,
    dania: PropTypes.arrayOf(
      PropTypes.shape({
        nazwa: PropTypes.string,
      }),
    ),
  }),
  user: PropTypes.shape({
    userId: PropTypes.number,
    firmaId: PropTypes.number,
    uprawnienia: PropTypes.shape({
      super_user: PropTypes.string,
      lokal: PropTypes.string,
    }),
    faktura: PropTypes.number,
    widoki: PropTypes.shape({}),
  }),
  pendingWyslij: PropTypes.bool,
  error: PropTypes.string,
  fetchDania: PropTypes.func,
  wyslijZamowienie: PropTypes.func,
  pobierzDania: PropTypes.func,
  pobierzKategorieDan: PropTypes.func,
  checkUser: PropTypes.func.isRequired,
};

Klient.defaultProps = {
  dania: [],
  daniaLokal: [],
  numerZamowienia: 0,
  user: null,
  menu: null,
  pendingWyslij: false,
  error: null,
  fetchDania: null,
  wyslijZamowienie: null,
  kategorieDan: [],
  pobierzDania: null,
  pobierzKategorieDan: null,
};

const mapStateToProps = state => ({
  error: state.daniaReducer.error,
  dania: state.daniaReducer.daniaDoMenu,
  daniaLokal: state.daniaReducer.daniaLokal,
  menu: state.daniaReducer.menu,
  pendingWyslij: state.daniaReducer.pendingWyslij,
  numerZamowienia: state.zamowieniaReducer.numerZamowienia,
  user: state.userReducer.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDania: fetchDaniaAction,
      wyslijZamowienie: wyslijZamowienieAction,
      pobierzKategorieDan: pobierzKategorieDanAction,
      checkUser: checkUserAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Klient);
