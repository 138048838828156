import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  zapiszGodzinyFirmyAction,
  pobierzFirmaByIdAction,
  pobierzUzytkownicyByFirmaIdAction,
  pobierzGodzinyAction,
  usunUzytkownikaAction,
  zapiszUzytkownikaAction,
  usunKontrahentaAction,
  zapiszRabatAction,
} from 'services/admin/firmyServices';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { addAlert } from 'actions/alertActions';
import { connect } from 'react-redux';
import AdminTemplate from 'templates/AdminTemplate';
import SidebarFirmy from 'components/organisms/SidebarFirmy/SidebarFirmy';
import EdytujFirme from 'components/organisms/EdytujFirme/EdytujFirme';
import EdytujUzytkownika from 'components/organisms/EdytujUzytkownika/EdytujUzytkownika';
import UzytkownikAdmin from 'components/organisms/UzytkownikAdmin/UzytkownikAdmin';
import Button from 'components/atoms/Button/Button';
import {
  Wrapper,
  Content,
  Container,
  WybranaFirma,
  StyledLink,
  Cell,
  Table,
  StyledRow,
  Input,
} from 'views/Admin/style';
import Obraz from 'components/atoms/Obraz/Obraz';
import minus from 'assets/svg/minus.svg';
import Confirm from 'components/molecules/Confirm/Confirm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

const StyledCheckboxWrapper = styled.div`
  display: flex;
`;

const AddNew = styled(Button)`
  border-width: 0;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
`;

const StyledCheckbox = styled.input`
  width: 3rem;
  transform: scale(1.3);
`;

const StyledLabel = styled.label`
  margin-left: 1rem;
`;

const StyledWrapper = styled.div`
  padding-left: 3rem;
`;

const StyledContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'space-between'};

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

const StyledButton = styled(Button)`
  margin-left: 3rem;

  ${({ del }) =>
    del &&
    css`
      justify-self: end;
    `}
`;

const InputDania = styled(Input)`
  border: 1px solid rgba(128, 207, 38, 0.2);
  border-bottom-color: rgba(128, 207, 38, 1);
  &:focus {
    outline: none;
    border-color: #80cf26;
    background-color: rgba(128, 207, 38, 0.4);
  }
`;
const StyledHourWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCell = styled(Cell)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4px;
`;

class AdminKontrahent extends React.Component {
  constructor(props) {
    super(props);

    this.zapiszUzytkownika = this.zapiszUzytkownika.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.usunKontrahenta = this.usunKontrahenta.bind(this);

    this.state = {
      wybranaFirma: {
        nazwa: '',
        ulica: '',
        kodPocztowy: '',
        miasto: '',
        NIP: 0,
        status: 0,
        kierowcaId: 0,
        rabat: 0,
      },
      wybraneGodziny: [],
      godzinyDostawy: [null, '1', '2', '3'],
      wybraneFirmy: [],
      godziny: null,
      redirect: null,
      showConfirm: false,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    pobierzFirmaByIdAction(params.id).then(wybranaFirma => {
      const wybraneGodziny = [];
      const godzinyDostawy = [];
      if (wybranaFirma.godzinyWajazdu) {
        wybranaFirma.godzinyWajazdu.forEach(item => {
          if (item.firmaId === wybranaFirma.id) {
            wybraneGodziny[item.id] = true;
            if (wybranaFirma.godzinyWajazdu[item.id - 1]) {
              godzinyDostawy[item.id] = wybranaFirma.godzinyWajazdu[item.id - 1].uKlienta;
            }
          } else {
            wybraneGodziny[item.id] = false;
            godzinyDostawy[item.id] = '';
          }
        });
      }
      this.setState({ wybranaFirma, wybraneGodziny, godzinyDostawy });
    });
    pobierzUzytkownicyByFirmaIdAction(params.id).then(uzytkownicy => {
      this.setState({ uzytkownicy });
    });
    pobierzGodzinyAction().then(pobraneGodziny => {
      this.setState({ godziny: pobraneGodziny });
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: { params },
    } = this.props;
    if (nextProps.match.params.id !== params.id) {
      pobierzFirmaByIdAction(nextProps.match.params.id).then(wybranaFirma => {
        this.setState({ wybranaFirma });
      });
      pobierzUzytkownicyByFirmaIdAction(nextProps.match.params.id).then(uzytkownicy => {
        this.setState({ uzytkownicy });
      });
    }
  }

  handleGodzinyCheckbox(e) {
    const id = e.target.id;
    const value = e.target.checked;
    const { wybraneGodziny } = this.state;
    if (!value) {
      this.setState(prevState => ({
        ...prevState,
        godzinyDostawy: {
          ...prevState.godzinyDostawy,
          [id]: '',
        },
      }));
    }
    if (e.target.type === 'checkbox') {
      this.setState(prevState => ({
        ...prevState,
        wybraneGodziny: {
          ...prevState.wybraneGodziny,
          [id]: !wybraneGodziny[id],
        },
      }));
    }
  }

  handleGodzinyInput(e) {
    const id = e.target.id;
    const value = e.target.value;

    this.setState(prevState => ({
      ...prevState,
      godzinyDostawy: {
        ...prevState.godzinyDostawy,
        [id]: value,
      },
    }));
  }

  handleRabat(e) {
    const value = e.target.value;

    this.setState(prevState => ({
      // ...prevState,
      wybranaFirma: {
        ...prevState.wybranaFirma,
        rabat: parseInt(value, 10),
      },
    }));
  }

  zapiszRabat() {
    const { wybranaFirma } = this.state;

    zapiszRabatAction(wybranaFirma.id, wybranaFirma.rabat);
  }

  zapiszUzytkownika(uzytkownik) {
    zapiszUzytkownikaAction({ uzytkownik }).then(() => {
      addAlert({ message: `Użytkownik zapisany`, type: 'success' });
      pobierzUzytkownicyByFirmaIdAction(uzytkownik.firmaId).then(uzytkownicy => {
        this.setState({ uzytkownicy });
      });
    });
  }

  usunUzytkownika(id) {
    const {
      match: { params },
    } = this.props;
    usunUzytkownikaAction(id).then(() => {
      pobierzUzytkownicyByFirmaIdAction(params.id).then(uzytkownicy => {
        this.setState({ uzytkownicy });
      });
    });
  }

  usunKontrahenta() {
    const {
      match: { params },
    } = this.props;
    this.setState({ showConfirm: false });
    usunKontrahentaAction(params.id).then(() => {
      addAlert({ message: `Kontrahent usunięty`, type: 'success' });
      this.setState({ redirect: '/kontrahenci' });
    });
  }

  toggleModal() {
    const { showConfirm } = this.state;
    this.setState({ showConfirm: !showConfirm });
  }

  zapiszGodziny() {
    const { wybraneGodziny, wybranaFirma, godzinyDostawy } = this.state;

    zapiszGodzinyFirmyAction(wybraneGodziny, wybranaFirma.id, godzinyDostawy);
  }

  render() {
    const {
      wybranaFirma,
      wybraneFirmy,
      godziny,
      uzytkownicy,
      wybraneGodziny,
      redirect,
      showConfirm,
      godzinyDostawy,
    } = this.state;
    const { nazwa } = wybranaFirma;
    const {
      match: { params },
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <AdminTemplate>
        <Wrapper>
          <SidebarFirmy firmy={wybraneFirmy} godzinyWyjazdu={godziny} />
          <Content>
            {wybranaFirma.id > 0 && (
              <Container>
                <WybranaFirma>{nazwa}</WybranaFirma>
                <EdytujFirme edytuj firma={wybranaFirma} key={wybranaFirma.id} />
                <StyledWrapper>
                  <StyledContainer>
                    {godziny && (
                      <>
                        <StyledHourWrapper>
                          <div>
                            <h3>Godziny wyjazdu</h3>
                            <StyledCheckboxWrapper>
                              <Row>
                                {wybranaFirma.godzinyWajazdu &&
                                  wybranaFirma.godzinyWajazdu.map(item => (
                                    <Col key={item.id}>
                                      <StyledLabel htmlFor={`godziny[${item.id}]`}>
                                        <StyledCheckbox
                                          type="checkbox"
                                          name={`godziny[${item.id}]`}
                                          id={item.id}
                                          checked={wybraneGodziny[item.id]}
                                          onChange={e => this.handleGodzinyCheckbox(e)}
                                        />

                                        {item.godzinaWyjazdu}
                                      </StyledLabel>
                                      <InputGroup size="lg" className="mb-3">
                                        <InputGroup.Prepend>
                                          <InputGroup.Text id="inputGroup-sizing-lg">
                                            Dostawa
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                          aria-label="Large"
                                          id={item.id}
                                          disabled={!wybraneGodziny[item.id]}
                                          value={godzinyDostawy[item.id]}
                                          onChange={e => this.handleGodzinyInput(e)}
                                          aria-describedby="inputGroup-sizing-lg"
                                        />
                                      </InputGroup>
                                    </Col>
                                  ))}
                              </Row>
                            </StyledCheckboxWrapper>
                          </div>

                          <StyledButton small secondary onClick={() => this.zapiszGodziny()}>
                            Zapisz godziny firmy
                          </StyledButton>
                        </StyledHourWrapper>
                        <StyledButton del small onClick={() => this.toggleModal()}>
                          Usuń kontrahenta
                        </StyledButton>
                      </>
                    )}
                  </StyledContainer>
                  <StyledContainer justify="flex-start">
                    <div>
                      <h2>Rabat</h2>
                      <InputDania
                        type="number"
                        id="rabat"
                        name="rabat"
                        value={wybranaFirma.rabat}
                        onChange={e => this.handleRabat(e)}
                        min={0}
                      />
                    </div>
                    <div>
                      <StyledButton small secondary onClick={() => this.zapiszRabat()}>
                        Zmień rabat
                      </StyledButton>
                    </div>
                  </StyledContainer>
                  <StyledContainer column>
                    <h2>Dodaj użytkownika</h2>
                    <EdytujUzytkownika
                      firmaId={params.id}
                      zapiszUzytkownika={this.zapiszUzytkownika}
                    />
                  </StyledContainer>
                </StyledWrapper>
                <Table scrolled view="kontrahenci">
                  <Cell head>l.p.</Cell>
                  <Cell head>Imię</Cell>
                  <Cell head>Nazwisko</Cell>
                  <Cell head>Telefon</Cell>
                  <Cell head>Email</Cell>
                  <Cell head>Login</Cell>
                  <Cell head>Status</Cell>

                  <Cell head>Usuń</Cell>
                  {uzytkownicy &&
                    uzytkownicy.map((item, index) => (
                      <StyledRow key={item.id}>
                        <StyledLink
                          secondary="true"
                          to={`/kontrahent/${params.id}/uzytkownik/${item.id}`}
                        >
                          <UzytkownikAdmin {...item} index={index} />
                        </StyledLink>

                        <StyledCell>
                          <AddNew onClick={() => this.usunUzytkownika(item.id)}>
                            <Obraz src={minus} alt="Usuń danie" />
                          </AddNew>
                        </StyledCell>
                      </StyledRow>
                    ))}
                </Table>
              </Container>
            )}
          </Content>
        </Wrapper>
        <Confirm
          show={showConfirm}
          text="Czy na pewno chcesz usunąć kontrahenta?"
          toggle={() => this.toggleModal()}
          usun={() => this.usunKontrahenta()}
        />
      </AdminTemplate>
    );
  }
}

AdminKontrahent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

AdminKontrahent.defaultProps = {};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapDispatchToProps)(AdminKontrahent);
