import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2rem;

  cursor: pointer;
  font-size: 1.6rem;
  user-select: none;

  &:hover {
    ${({ CheckboxInput }) =>
      CheckboxInput &&
      css`
        & ~ ${({ Checkmark }) =>
          Checkmark &&
          css`
            background-color: #ccc;
          `}
      `}
  }

  ${({ Checkmark }) =>
    Checkmark &&
    css`
      &:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    `}
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid #000;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.25);

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const CheckBox = ({ name, children, id, value, className, handleChange }) => (
  <Label htmlFor={id} className={className}>
    <span>{children}</span>
    <CheckboxInput
      type="checkbox"
      name={name}
      value={value}
      aria-invalid="false"
      id={id}
      handleChange={e => handleChange(e)}
    />
    <Checkmark />
  </Label>
);

CheckBox.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
};

CheckBox.defaultProps = {
  value: 1,
  children: 'checkbox label',
  className: 'checkbox',
  handleChange: null,
};

export default CheckBox;
