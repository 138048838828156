import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  font-weight: 700;
  border: 2px solid #aebdc2;
  background-color: #fff;
  position: relative;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.6);
  line-height: 1em;
`;

const Potwierdzone = styled.div`
  margin-right: auto;
  line-height: 1.2em;
  width: 100%;
  text-align: center;
  line-height: 1em;
`;

const DostepnaIlosc = styled.div`
  margin-left: auto;
  line-height: 1em;
  font-size: 60%;
  height: 1.4em;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  border: 2px solid #aebdc2;
  position: absolute;
  right: -1px;
  bottom: -1px;
  padding: 2px 3px;
  line-height: 1em;
`;

const SumaBox = ({ id, zatwierdzone, dostepnaIlosc }) => (
  <Col data-id={id}>
    <Wrapper>
      <Potwierdzone>{zatwierdzone}</Potwierdzone>
      <DostepnaIlosc>{dostepnaIlosc}</DostepnaIlosc>
    </Wrapper>
  </Col>
);

SumaBox.propTypes = {
  id: PropTypes.number,
  zatwierdzone: PropTypes.number,
  dostepnaIlosc: PropTypes.number,
};

SumaBox.defaultProps = {
  id: null,
  zatwierdzone: 0,
  dostepnaIlosc: 0,
};

export default SumaBox;
