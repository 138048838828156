export const FETCH_DANIA_PENDING = 'FETCH_DANIA_PENDING';
export const FETCH_DANIA_SUCCESS = 'FETCH_DANIA_SUCCESS';
export const FETCH_DANIA_ERROR = 'FETCH_DANIA_ERROR';

export const FETCH_DANIA_LOKAL_SUCCESS = 'FETCH_DANIA_LOKAL_SUCCESS';

export const USUN_DANIE_PENDING = 'USUN_DANIE_PENDING';
export const USUN_DANIE_SUCCESS = 'USUN_DANIE_SUCCESS';
export const USUN_DANIE_ERROR = 'USUN_DANIE_ERROR';

export const ZAPISZ_DANIE_PENDING = 'ZAPISZ_DANIE_PENDING';
export const ZAPISZ_DANIE_SUCCESS = 'ZAPISZ_DANIE_SUCCESS';
export const ZAPISZ_DANIE_ERROR = 'ZAPISZ_DANIE_ERROR';

export const POBIERZ_KATEGORIE_PENDING = 'POBIERZ_KATEGORIE_PENDING';
export const POBIERZ_KATEGORIE_SUCCESS = 'POBIERZ_KATEGORIE_SUCCESS';
export const POBIERZ_KATEGORIE_ERROR = 'POBIERZ_KATEGORIE_ERROR';

export const POBIERZ_DODATKI_PENDING = 'POBIERZ_DODATKI_PENDING';
export const POBIERZ_DODATKI_SUCCESS = 'POBIERZ_DODATKI_SUCCESS';
export const POBIERZ_DODATKI2_SUCCESS = 'POBIERZ_DODATKI2_SUCCESS';
export const POBIERZ_DODATKI_ERROR = 'POBIERZ_DODATKI_ERROR';

export const FETCH_DANIA_DO_MENU_SUCCESS = 'FETCH_DANIA_DO_MENU_SUCCESS';

export function fetchDaniaLokalSuccess(data) {
  return {
    type: FETCH_DANIA_LOKAL_SUCCESS,
    daniaLokal: data.dania,
  };
}

export function fetchDaniaPending() {
  return {
    type: FETCH_DANIA_PENDING,
  };
}

export function fetchDaniaSuccess(data) {
  return {
    type: FETCH_DANIA_SUCCESS,
    dania: data.dania,
  };
}

export function fetchDaniaError(error) {
  return {
    type: FETCH_DANIA_ERROR,
    error,
  };
}

export function fetchDaniaDoMenuPending() {
  return {
    type: FETCH_DANIA_PENDING,
  };
}

export function fetchDaniaDoMenuSuccess(data) {
  return {
    type: FETCH_DANIA_DO_MENU_SUCCESS,
    daniaDoMenu: data.dania,
    menu: data.menu,
  };
}

export function fetchDaniaDoMenuError(error) {
  return {
    type: FETCH_DANIA_ERROR,
    error,
  };
}

export function usunDaniePending() {
  return {
    type: USUN_DANIE_PENDING,
  };
}

export function usunDanieSuccess(id) {
  return {
    type: USUN_DANIE_SUCCESS,
    usuniete_id: id,
  };
}

export function usunDanieError(error) {
  return {
    type: USUN_DANIE_ERROR,
    error,
  };
}

export function zapiszDaniePending() {
  return {
    type: ZAPISZ_DANIE_PENDING,
  };
}

export function zapiszDanieSuccess() {
  return {
    type: ZAPISZ_DANIE_SUCCESS,
  };
}

export function zapiszDanieError(error) {
  return {
    type: ZAPISZ_DANIE_ERROR,
    error,
  };
}

export function pobierzKategorieDanPending() {
  return {
    type: POBIERZ_KATEGORIE_PENDING,
  };
}

export function pobierzKategorieDanSuccess(kategorieDan) {
  return {
    type: POBIERZ_KATEGORIE_SUCCESS,
    kategorieDan,
  };
}

export function pobierzKategorieDanError(error) {
  return {
    type: POBIERZ_KATEGORIE_ERROR,
    error,
  };
}

export function pobierzDodatkiDanPending() {
  return {
    type: POBIERZ_DODATKI_PENDING,
  };
}

export function pobierzDodatkiDanSuccess(dodatkiDan) {
  return {
    type: POBIERZ_DODATKI_SUCCESS,
    dodatkiDan,
  };
}

export function pobierzDodatkiDan2Success(dodatkiDan2) {
  return {
    type: POBIERZ_DODATKI2_SUCCESS,
    dodatkiDan2,
  };
}

export function pobierzDodatkiDanError(error) {
  return {
    type: POBIERZ_DODATKI_ERROR,
    error,
  };
}
