import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button/Button';
import styled, { css } from 'styled-components';
import creditCard from 'assets/svg/credit_card.svg';
import Swipe from 'react-easy-swipe';
import przelew from 'assets/svg/przelew.svg';
import gotowka from 'assets/svg/dollar.svg';
import phone from 'assets/svg/phone.svg';
import no from 'assets/svg/whiteCross.svg';
import yes from 'assets/svg/whiteTick.svg';
import { setKierowcaStatusAction, setKierowcaPlatnosc } from 'services/kierowcaZamowieniaService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Wrapper = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 0 auto 0.5rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  font-size: 2rem;
  line-height: 1.3;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    width: calc(100% - 15px);
    height: 1px;
    background-color: rgba(11, 11, 11, 0.15);
    z-index: 2;
  }
`;

const StyledSwipe = styled(Swipe)`
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transition: all 0.3s ease;
  padding: 1rem 15px;
  min-height: 6rem;
  border: 3px solid transparent;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.7);
`;

const StyledPhone = styled.div`
  display: flex;
  align-items: center;
`;

const NumerIkona = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.2em;
  font-size: 24px;
  grid-column-start: 4;
  color: ${({ oplacone }) => (oplacone ? '#80CF26' : '#CE2055')};
  img {
    margin-left: 0.5rem;
  }
`;

const Nazwa = styled.div`
  line-height: 1.2em;
  color: #000;
  width: auto;
  font-size: 1.8rem;
  font-weight: 700;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const Danie = styled.div`
  line-height: 1.3em;
  text-align: left;
  flex-basis: 100%;
  display: flex;

  flex-direction: column;
  div {
    margin-bottom: 0.5rem;
  }
`;

const Action = styled(Button)`
  background-color: ${({ red }) => (red ? '#CE2055' : '#80CF26')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 700;
  border: none;
  height: 100%;
  border-radius: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  width: 30%;
  padding: 1rem 5px;
  left: ${({ red }) => (red ? '0' : 'auto')};
  right: ${({ red }) => (red ? 'auto' : '0')};

  ${({ back }) =>
    back &&
    css`
      left: 0%;
      right: auto;
      background-color: #ccc;
    `}

  img {
    width: 25px;
    height: 25px;
    display: block;
    margin-bottom: 0.6rem;
  }
`;

class ZamowienieSingle extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    position: 0,
    lock: false,
    paymentType: true,
  };

  onSwipeMove = pos => {
    const { position, lock } = this.state;
    if (!lock) {
      if (pos.x > 50) {
        this.setState({ lock: true });
        if (position !== 30) {
          this.setState({ position: position + 30 });
        }
      } else if (pos.x < -50) {
        this.setState({ lock: true });
        if (position !== -30) {
          this.setState({ position: position - 30 });
        }
      }
    }
  };

  onSwipeEnd = () => {
    this.setState({ lock: false });
  };

  ustalStatus = status => {
    const { id, setKierowcaStatus, fetchOrders } = this.props;

    setKierowcaStatus(status, id).then(() => {
      fetchOrders();
    });

    this.setState({ position: 0 });
  };

  changePaymentType = paymentType => {
    const { id, fetchOrders } = this.props;
    setKierowcaPlatnosc(paymentType, id).then(() => {
      fetchOrders();
      this.setState({ paymentType });
    });
  };

  render() {
    const { position, paymentType } = this.state;
    const {
      klient,
      nazwa,
      cena,
      formaPlatnosci,
      oplacone,
      received,
      telefon,
      oddzial,
      komentarz,
    } = this.props;
    let fp = '';
    switch (formaPlatnosci) {
      case 'karta':
        fp = creditCard;
        break;
      case 'gotowka':
        fp = gotowka;
        break;
      default:
        fp = przelew;
    }

    // console.log(paymentType);
    // console.log(formaPlatnosci);

    return (
      <Wrapper cena={cena}>
        {received === 0 ? (
          <Action red onClick={() => this.ustalStatus(-1)}>
            <img src={no} alt="Nie odebrano" />
            Nie odebrano
          </Action>
        ) : (
          <Action back onClick={() => this.ustalStatus(0)}>
            Cofnij
          </Action>
        )}
        {received === 1 && (
          <StyledSwipe
            onSwipeStart={this.onSwipeStart}
            onSwipeMove={this.onSwipeMove}
            onSwipeEnd={this.onSwipeEnd}
            style={{
              transform: `translate(${position}%, 0px)`,
              background: '#D4EDDA',
            }}
          >
            <StyledPhone>
              <a href={`tel:${telefon}`}>
                <img width="20" src={phone} alt="Forma płatności" />
              </a>
            </StyledPhone>
            <Nazwa>
              {klient}
              {oddzial && ` - ${oddzial}`}
            </Nazwa>

            <NumerIkona oplacone={oplacone}>
              {cena}zł <img width="20" src={fp} alt="Forma płatności" />
            </NumerIkona>
            <Danie>
              {nazwa.map((item, index) => (
                <div key={index}>
                  {index + 1}. {item}
                </div>
              ))}
            </Danie>
            {komentarz}
          </StyledSwipe>
        )}
        {received === 0 && (
          <StyledSwipe
            onSwipeStart={this.onSwipeStart}
            onSwipeMove={this.onSwipeMove}
            onSwipeEnd={this.onSwipeEnd}
            style={{
              transform: `translate(${position}%, 0px)`,
              background: '#fff',
            }}
          >
            <StyledPhone>
              <a href={`tel:${telefon}`}>
                <img width="20" src={phone} alt="Forma płatności" />
              </a>
            </StyledPhone>
            <Nazwa>
              {klient}
              {oddzial && ` - ${oddzial}`}
            </Nazwa>

            <NumerIkona oplacone={oplacone} onClick={() => this.changePaymentType(!paymentType)}>
              {cena} zł <img width="30" src={fp} alt="Forma płatności" />
            </NumerIkona>

            <Danie>
              {nazwa.map((item, index) => (
                <div key={index}>
                  {index + 1}. {item}
                </div>
              ))}
            </Danie>
            {komentarz}
          </StyledSwipe>
        )}
        {received === -1 && (
          <StyledSwipe
            onSwipeStart={this.onSwipeStart}
            onSwipeMove={this.onSwipeMove}
            onSwipeEnd={this.onSwipeEnd}
            style={{
              transform: `translate(${position}%, 0px)`,
              background: '#F8D7DA',
            }}
          >
            <StyledPhone>
              <a href={`tel:${telefon}`}>
                <img width="20" src={phone} alt="Forma płatności" />
              </a>
            </StyledPhone>
            <Nazwa>
              {klient}
              {oddzial && ` - ${oddzial}`}
            </Nazwa>

            <NumerIkona oplacone={oplacone}>
              {cena}zł <img width="20" src={fp} alt="Forma płatności" />
            </NumerIkona>
            <Danie>
              {nazwa.map((item, index) => (
                <div key={index}>
                  {index + 1}. {item}
                </div>
              ))}
            </Danie>
            {komentarz}
          </StyledSwipe>
        )}

        {received === 0 && (
          <Action onClick={() => this.ustalStatus(1)}>
            <img src={yes} alt="Odebrano" />
            Odebrano
          </Action>
        )}
      </Wrapper>
    );
  }
}

ZamowienieSingle.propTypes = {
  klient: PropTypes.string.isRequired,
  nazwa: PropTypes.arrayOf(PropTypes.string).isRequired,
  cena: PropTypes.number.isRequired,
  formaPlatnosci: PropTypes.string.isRequired,
  oplacone: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  setKierowcaStatus: PropTypes.func,
  fetchOrders: PropTypes.func,
  received: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  telefon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  oddzial: PropTypes.string,
  komentarz: PropTypes.string,
};

ZamowienieSingle.defaultProps = {
  setKierowcaStatus: null,
  fetchOrders: null,
  received: null,
  telefon: '',
  oddzial: '',
  komentarz: '',
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setKierowcaStatus: setKierowcaStatusAction,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ZamowienieSingle);
