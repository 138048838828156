import {
  FETCH_DANIA_PENDING,
  FETCH_DANIA_SUCCESS,
  FETCH_DANIA_ERROR,
  USUN_DANIE_PENDING,
  USUN_DANIE_SUCCESS,
  USUN_DANIE_ERROR,
  FETCH_DANIA_DO_MENU_SUCCESS,
  POBIERZ_KATEGORIE_SUCCESS,
  POBIERZ_DODATKI_SUCCESS,
  POBIERZ_DODATKI2_SUCCESS,
  FETCH_DANIA_LOKAL_SUCCESS,
} from 'actions/daniaActions';

const initialState = {
  pending: false,
  error: null,
  dania: [],
  kategorieDan: [],
  dodatkiDan: [],
};

const daniaReducer = (state = initialState, action) => {
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case FETCH_DANIA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_DANIA_SUCCESS:
      return {
        ...state,
        pending: false,
        dania: action.dania,
      };
    case FETCH_DANIA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case FETCH_DANIA_LOKAL_SUCCESS:
      return {
        ...state,
        pending: false,
        daniaLokal: action.daniaLokal,
      };
    case FETCH_DANIA_DO_MENU_SUCCESS:
      return {
        ...state,
        pending: false,
        daniaDoMenu: action.daniaDoMenu,
        menu: action.menu,
      };

    case POBIERZ_KATEGORIE_SUCCESS:
      return {
        ...state,
        pending: false,
        kategorieDan: action.kategorieDan,
      };

    case POBIERZ_DODATKI_SUCCESS:
      return {
        ...state,
        pending: false,
        dodatkiDan: action.dodatkiDan,
      };

    case POBIERZ_DODATKI2_SUCCESS:
      return {
        ...state,
        pending: false,
        dodatkiDan2: action.dodatkiDan2,
      };

    case USUN_DANIE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case USUN_DANIE_SUCCESS:
      return {
        ...state,
        dania: state.dania.filter(d => {
          return d.id !== action.usuniete_id;
        }),
        pending: false,
        error: action.error,
      };
    case USUN_DANIE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    /* case 'REMOVE':
      return {
        ...state,
        dania: [...state.dania.filter(item => item.id !== action.payload.id)],
      }; */
    default:
      return state;
  }
};

export default daniaReducer;
