import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900&display=swap&subset=latin-ext');

    *, *:before, *:after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
    }

    html,
    body {
        margin: 0;
        padding: 0;
       
    }

    html {
        font-size: 62.5%;
        font-weight: 400;
    }

    body {
        font-size: 1.6rem;
        margin: 0;
        font-family: 'Nunito', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
    }

    .rel {
        position: relative;
    }

    .front {
        z-index: 2;
    }

    
    h2 + .wybierzCzas {
        margin-bottom: 3rem;
        input {
            font-size: 2rem;
            line-height: 1;            
        }
    }

    #godzinaWyjazduId {
        margin-bottom: 3rem;
    }
    
    #firmySelect {
        margin-bottom: 3rem;
    }

    @keyframes preloaderAnimation {
        from {
        transform: scale(1);
        }
        to {
        transform: scale(.95);
        }
    } 
    .preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        z-index: 999999;
        &__container {
          position: relative;
        }
        a {
          width: 12.8rem;
          height: 11.8rem;          
          animation: preloaderAnimation .7s linear infinite alternate;          
        }
      }

    #tymczasowyHeader {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1000;
        padding:0;
        text-align: right;
        opacity: 1;
        background: #fff;
        box-shadow: 0px 4px 10px 2px rgba(204,204,204,1);
        padding: 0.2rem 0.5rem;

        nav {
            ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;
            li {
                display: inline-block;
                margin: 0 1rem;
                a {
                font-size: 16px;
                line-height: 1.2em;
                color: #000;
                }
            }
            }
        }
    }

    .react-datepicker {
        width: 100%;
        font-size: 1.6rem!important;
        font-family: Nunito,sans-serif!important;
        border-radius: 1rem!important;
        &__current {
            &-month {
                font-size: 1.6rem!important;
                @media screen and (min-width: 1440px) {
                    font-size: 1.8rem!important;
                }
            }
        }
        &__month-container {
            width: 100%;
        }
        &__day {
            line-height: 2.6rem!important;
            width: calc(100% / 8)!important;
            font-size: 1.2rem!important;
            @media screen and (min-width: 1440px) {
                font-size: 1.6rem!important;
            }
            &-name {
                width: calc(100% / 8)!important;
                font-size: 1.1rem!important;
                font-weight: 400!important;
                @media screen and (min-width: 1440px) {
                    font-size: 1.6rem!important;
                    font-weight: 700!important;
                }
            }
            &--selected {
                background-color: #000 !important;
            }
            &--outside-month {
                color: #707070!important;
            }
        }
        &__header {
            padding: 8px 5px 0 5px;
            border-top-left-radius: 1rem!important;
            border-top-right-radius: 1rem!important;
        }
        &__navigation {
            border-width: 0.7rem!important;
        }
    }

    .react-datepicker-wrapper {
        display: flex;
    }

    .Tab {
    &.isSelected {
        border-color: #0b0b0b;
        &:hover {
        border-color: #0b0b0b;
        cursor: default;
        }
    }
    }

`;

export default GlobalStyle;
