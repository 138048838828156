import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../atoms/Button/Button';

const StyledLi = styled.li`
  height: 5rem;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
`;

const StyledWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding: 0;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: black;
    text-decoration: none;
  }
`;

const EtykietyLista = ({ etykiety }) => {
  return (
    <StyledWrapper>
      {etykiety.map(item => (
        <StyledLi key={item.id}>
          <span> {item.time}</span>
          <span>{item.name}</span>
          <Button
            as={Link}
            href={`/api/kuchnia/archiwum/etykiety/pobierz/${item.etykieta}`}
            secondary="true"
            small
          >
            Pobierz
          </Button>
        </StyledLi>
      ))}
    </StyledWrapper>
  );
};

EtykietyLista.propTypes = {
  etykiety: PropTypes.arrayOf(PropTypes.any),
};

EtykietyLista.defaultProps = {
  etykiety: [],
};

const mapStateToProps = state => {
  return {
    etykiety: state.archiwumReducer.etykiety,
  };
};

export default connect(mapStateToProps)(EtykietyLista);
