import styled from 'styled-components';

export const Aktualizacje = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 0 15px;
  p {
    margin: 0 2rem 0 0;
    span {
      font-weight: 700;
    }
  }
`;
