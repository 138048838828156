import React, { useState, useEffect, useContext } from 'react';
import AdminTemplate from 'templates/AdminTemplate';
import ListGroup from 'react-bootstrap/ListGroup';
import Heading from 'components/atoms/Heading/Heading';
import { fetchDriver } from 'services/admin/usersServices';
import DriverPanel from 'components/organisms/DriverPanel/DriverPanel';
import { useParams, useHistory } from 'react-router-dom';
import './Driver.scss';
import { DriverContext } from 'driverContext';
import { StyledTabListA, ArrowA, ArrowContC } from './style';

const Driver = () => {
  const [driverList, setDriverList] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [navNone, setNavNone] = useState(false);

  const { whichPage, setWhichPage } = useContext(DriverContext);

  useEffect(() => {
    fetchDriver().then(res => {
      setDriverList(res);
    });
  }, []);

  const navigateDriver = driverId => {
    history.push(`/driver/${driverId}`);
  };

  return (
    <AdminTemplate>
      <StyledTabListA
        style={{
          transition: '1s',
          left: navNone ? 0 : '-300px',
        }}
        responsive
      >
        <Heading rank={1}>Kierowcy</Heading>

        <button
          className={whichPage === 1 ? 'buttonDriver wyjazdy active' : 'buttonDriver wyjazdy'}
          type="button"
          onClick={() => setWhichPage(1)}
        >
          Wyjazdy
        </button>
        <button
          className={whichPage === 2 ? 'buttonDriver historia active' : 'buttonDriver historia'}
          type="button"
          onClick={() => setWhichPage(2)}
        >
          Historia
        </button>

        {whichPage === 1 ? (
          <></>
        ) : (
          <>
            <ListGroup>
              {driverList !== undefined &&
                driverList.map(driver =>
                  driver.id === parseInt(id, 10) ? (
                    <ListGroup.Item
                      key={driver.id}
                      active
                      action
                      variant="dark"
                      onClick={() => navigateDriver(driver.id)}
                    >
                      {driver.imie} {driver.nazwisko}
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item
                      key={driver.id}
                      action
                      onClick={() => navigateDriver(driver.id)}
                    >
                      {driver.imie} {driver.nazwisko}
                    </ListGroup.Item>
                  ),
                )}
            </ListGroup>
          </>
        )}
      </StyledTabListA>

      <ArrowContC
        onClick={() => setNavNone(!navNone)}
        style={{
          transition: '1s',
          left: navNone ? '300px' : '0px',
        }}
      >
        <ArrowA
          style={{
            transition: '1s',
            transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
          }}
        />
      </ArrowContC>
      <DriverPanel />
    </AdminTemplate>
  );
};

export default Driver;
