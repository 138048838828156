// import React from 'react';
import styled, { css } from 'styled-components';

const Button = styled.button`
  background-color: ${({ background, theme }) => background || theme.black};
  color: ${({ color }) => color || '#fff'};
  font-size: ${({ fontSize }) => fontSize || '1.8rem'};
  border-style: solid;
  border-color: ${({ border, theme }) => border || theme.black};
  border-width: 4px;
  line-height: 1.2em;
  font-weight: 700;
  padding: ${({ nopadding }) => (nopadding ? '0.3em' : '0.7em 2.2em')};
  width: ${({ width }) => width || 'auto'};
  flex-grow: ${({ grow }) => (grow ? '1' : '0')};
  margin: ${({ margin }) => margin || '0'};
  border-radius: 6px;
  outline: none;

  
  transition: all 0.3s ease
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.4);
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ background, theme }) => background || theme.active};
      border-color: ${({ border, theme }) => border || theme.active};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ background }) => background || 'transparent'};
      border-color: ${({ border, theme }) => border || theme.disabled};
      color: ${({ color, theme }) => color || theme.disabled};
      &:hover {
        transform: none;
      }
    `}
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
    ${({ secondary }) =>
      secondary &&
      css`
        background: none;
        border: 2px solid ${({ background, theme }) => background || theme.black};
        padding: 0.4em;
        color: ${({ background, theme }) => background || theme.black};
      `}

      ${({ small }) =>
        small &&
        css`
          font-size: 1.6rem;
          min-width: 100px;
          font-weight: 500;
          height: 70%;
          padding: 1rem;
        `}

        
`;

export default Button;
