import React from 'react';
import PropTypes from 'prop-types';

// import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import finger from 'assets/svg/touch.svg';
import Obraz from 'components/atoms/Obraz/Obraz';
import Uwagi from 'components/organisms/Uwagi/Uwagi';
import { pobierzArchiwumEtykietAction } from 'services/archiwumEtykietService';
import { pobierzDaniaDoMenuAction, pobierzZamowieniaAction } from 'services/kuchniaServices';
import { pobierzGodzinyAction } from 'services/admin/firmyServices';

import styled from 'styled-components';
import { Aktualizacje } from 'views/Kuchnia/style';
import Button from 'components/atoms/Button/Button';
import Logout from 'components/molecules/Logout/Logout';
import TitleBox from './TitleBox';
import ZamowieniaRow from './ZamowieniaRow';
import ZamowieniaSatystyki from './ZamowieniaSatystyki';
import ZamowieniaSuma from './ZamowieniaSuma';
import EtykietyPopup from '../EtykietyPopup/EtykietyPopup';

const StyledButtonWrapper = styled.div`
  margin-left: 1rem;
`;

const Tip = styled(Col)`
  display: flex;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.2em;
  font-weight: 700;
  align-items: flex-end;
  text-align: center;
  justify-content: space-around;
  padding-bottom: 1rem;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
`;

const StyledZamowieniaWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 1rem;
`;

function firstFunc(el) {
  let first = 'wrapper';
  if (el === 0) {
    first = 'wrapper__active';
  }
  return first;
}

const Head = styled(Row)`
  min-height: 9rem;
`;

class KuchniaZamowienia extends React.Component {
  _isMounted = false;

  state = {
    activeRow: '',
    modalShow: false,
  };

  interval = null;

  componentDidMount() {
    this._isMounted = true;
    const { pobierzDaniaDoMenu, pobierzZamowienia } = this.props;
    pobierzDaniaDoMenu();

    this.interval = setInterval(pobierzZamowienia, 30000); // pyta API co 60 sekund
    this.currentTime = setInterval(this.getCurrentHour, 15000);
    pobierzZamowienia();
    pobierzGodzinyAction().then(pobraneGodziny => {
      if (this._isMounted) {
        this.setState({ godziny: pobraneGodziny });
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this._isMounted = false;
  }

  setActiveRow(activeRow) {
    this.setState({ activeRow });
  }

  setModalShow(flag) {
    const { pobierzArchiwumEtykiet } = this.props;
    if (flag) {
      pobierzArchiwumEtykiet();
    }

    this.setState({ modalShow: flag });
  }

  render() {
    const {
      dania,
      zamowienia,
      aktualizacja,
      podsumowanieDania,
      pobierzZamowienia,
      menu,
    } = this.props;
    const { activeRow, godziny, modalShow } = this.state;

    return (
      <StyledWrapper>
        <Logout absolute />
        <Head>
          <Tip>
            <span>
              Wybierz <br />
              godzinę:
            </span>
            <Obraz src={finger} alt="Wybierz godzinę" />
          </Tip>
          {dania.map(item => (
            <TitleBox key={item.danieId} {...item} />
          ))}
        </Head>

        {godziny &&
          menu &&
          zamowienia &&
          Object.keys(zamowienia).map(godzina => {
            const item = zamowienia[godzina];
            return (
              <>
                {item.zamowione && (
                  <StyledZamowieniaWrapper key={`gw_${godzina}`}>
                    <ZamowieniaRow
                      key={`cw_${godzina}`}
                      first={firstFunc(godziny.indexOf(item))}
                      rowKey={`gw_${godzina}`}
                      {...item}
                      menuId={menu.id}
                      dania={dania}
                      title={godzina}
                      active={activeRow === `gw_${godzina}`}
                      setActiveRow={() => this.setActiveRow(`gw_${godzina}`)}
                    />
                    <Uwagi
                      key={`gw_${godzina}`}
                      active={activeRow === `gw_${godzina}`}
                      dania={dania}
                    >
                      {item.uwagi}
                    </Uwagi>
                  </StyledZamowieniaWrapper>
                )}
              </>
            );
          })}
        {podsumowanieDania && <ZamowieniaSuma podsumowanieDania={podsumowanieDania} />}
        <ZamowieniaSatystyki dania={dania} />
        <Aktualizacje>
          <p>
            Ostatnia aktualizacja: <br />
            <span>{aktualizacja}</span>
          </p>
          <Button
            active
            nopadding
            onClick={() => {
              pobierzZamowienia();
            }}
          >
            Aktualizuj
          </Button>
          <StyledButtonWrapper>
            <Button active nopadding secondary="true" onClick={() => this.setModalShow(true)}>
              Archiwum etykiet
            </Button>
          </StyledButtonWrapper>
          {/* Sprawdzać czy są jakieś do akceptacji i przekazywać propsa active/disabled */}
        </Aktualizacje>
        <EtykietyPopup
          show={modalShow}
          zamowienia={zamowienia}
          dania={dania}
          onHide={() => this.setModalShow(false)}
        />
      </StyledWrapper>
    );
  }
}

KuchniaZamowienia.propTypes = {
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  podsumowanieDania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  menu: PropTypes.shape({
    id: PropTypes.number,
  }),
  zamowienia: PropTypes.shape({}),
  aktualizacja: PropTypes.string,
  pobierzDaniaDoMenu: PropTypes.func,
  pobierzZamowienia: PropTypes.func,
  pobierzArchiwumEtykiet: PropTypes.func,
  active: PropTypes.bool,
};

KuchniaZamowienia.defaultProps = {
  dania: [],
  zamowienia: null,
  aktualizacja: 'brak danych',
  podsumowanieDania: [],
  pobierzDaniaDoMenu: null,
  pobierzZamowienia: null,
  pobierzArchiwumEtykiet: null,
  active: false,
  menu: null,
};

const mapStateToProps = state => {
  return {
    dania: state.kuchniaReducer.dania,
    zamowienia: state.kuchniaReducer.zamowienia,
    aktualizacja: state.kuchniaReducer.aktualizacja,
    podsumowanieDania: state.kuchniaReducer.podsumowanieDania,
    menu: state.kuchniaReducer.menu,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzDaniaDoMenu: pobierzDaniaDoMenuAction,
      pobierzZamowienia: pobierzZamowieniaAction,
      pobierzArchiwumEtykiet: pobierzArchiwumEtykietAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KuchniaZamowienia);
