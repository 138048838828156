import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  position: relative;
  backface-visibility: hidden;
  overflow: hidden;
  width: ${({ w }) => w || 'auto'};
  height: ${({ h }) => h || 'auto'};
  & svg {
    width: 100%;
    height: auto;
  }
`;

const Img = styled.img`
  display: block;
  width: 100%;
  max-height: 100%;
  height: auto;
`;

const Obraz = ({ className, src, alt, w, h }) => (
  <Wrapper w={w} className={className} h={h}>
    <Img src={src} alt={alt} />
  </Wrapper>
);

Obraz.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  w: PropTypes.string,
  h: PropTypes.string,
};

Obraz.defaultProps = {
  alt: '',
  className: 'imageWrapper',
  w: '',
  h: '',
};

export default Obraz;
