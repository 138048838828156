import {
  FETCH_USERS_PENDING,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  FETCH_USER_ERROR,
  FETCH_USER_PENDING,
  FETCH_USER_SUCCESS,
  SET_USER_ERROR,
  SET_USER_PENDING,
  SET_USER_SUCCESS,
} from 'actions/userActions';

const initialState = {
  user: {},
  users: [],
  pending: null,
  error: null,
  isEdited: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_PENDING:
    case FETCH_USER_PENDING:
    case SET_USER_PENDING:
      // console.log(action);
      return {
        ...state,
        pending: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.data.users,
      };
    case FETCH_USERS_ERROR:
    case FETCH_USER_ERROR:
    case SET_USER_ERROR:
      // console.log(action);
      return {
        ...state,
        pending: false,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.data.user,
      };
    case SET_USER_SUCCESS:
      return {
        ...state,
        isEdited: action.data,
      };
    default:
      return state;
  }
};

export default usersReducer;
