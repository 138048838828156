import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavLinkStyled = styled(NavLink)`
  color: #000;
  text-transform: uppercase;
  font-size: 2.6rem;
  font-weight: 700;
  margin: 1rem auto;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #000;
  }
`;

class NawigacjaUser extends React.Component {
  state = {};

  render() {
    const { uprawnienia } = this.props;
    return (
      <nav>
        {uprawnienia && (
          <Ul>
            {(uprawnienia.klient || uprawnienia.super_user) && (
              <li>
                <NavLinkStyled exact to="/zamow-posilek">
                  Zamów posiłek
                </NavLinkStyled>
              </li>
            )}
            {(uprawnienia.kierowca || uprawnienia.super_user) && (
              <li>
                <NavLinkStyled to="/kierowca">Wyjazdy</NavLinkStyled>
              </li>
            )}
            {(uprawnienia.kuchnia || uprawnienia.super_user) && (
              <>
                <li>
                  <NavLinkStyled to="/kuchnia">Kuchnia (dowóz)</NavLinkStyled>
                </li>
              </>
            )}

            {uprawnienia.super_user && (
              <li>
                <NavLinkStyled to="/admin/dzien">Admin</NavLinkStyled>
              </li>
            )}
          </Ul>
        )}
      </nav>
    );
  }
}

NawigacjaUser.defaultProps = {
  uprawnienia: null,
};
NawigacjaUser.propTypes = {
  uprawnienia: PropTypes.shape({
    admin_firma: PropTypes.string,
    kierowca: PropTypes.string,
    klient: PropTypes.string,
    kuchnia: PropTypes.string,
    super_user: PropTypes.string,
  }),
};

export default NawigacjaUser;
