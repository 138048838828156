import axios from 'axios';
import {
  wyslijZamowieniePending,
  wyslijZamowienieSuccess,
  wyslijZamowienieError,
  wyczyscZamowienieSuccess,
} from 'actions/zamowieniaActions';
import { addAlert } from 'actions/alertActions';

export const wyslijZamowienie = (menuInfo, zamowienie) => dispatch => {
  dispatch(wyslijZamowieniePending());
  return axios
    .post('/api/zamowienia/zloz', { dania: zamowienie, info: menuInfo })
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(wyslijZamowienieSuccess(res.data.numerZamowienia));
      addAlert({
        message: `Zamówienie powiodło się! Twój numer zamówienia: ${res.data.numerZamowienia}`,
        type: 'success',
      });
      return res.data.numerZamowienia;
    })
    .catch(error => {
      addAlert({
        message: `Wystąpił błąd: ${error.error}`,
        type: 'danger',
      });
      dispatch(wyslijZamowienieError(error));
    });
};

export const wyczyscZamowienieAction = () => dispatch => {
  dispatch(wyczyscZamowienieSuccess());
};

export default wyslijZamowienie;
