import React from 'react';
import 'theme/custom.scss';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { routes } from 'routes';
import { Provider } from 'react-redux';
import MainTemplate from 'templates/MainTemplate';
import store from 'store';
import Admin from 'views/Admin/Admin';
import Zamowienia from 'views/Admin/Zamowienia';
import Kontrahenci from 'views/Admin/Kontrahenci';
import Kontrahent from 'views/Admin/Kontrahent';
import Uzytkownik from 'views/Admin/Uzytkownik';
import Users from 'views/Admin/Users';
import User from 'views/Admin/User';
import Dania from 'views/Admin/Dania';
import Menu from 'views/Admin/Menu';
import Login from 'views/Login/Login';
import Rejestracja from 'views/Login/Rejestracja';
import Klient from 'views/Klient/Klient';
import KlientHistoria from 'views/Klient/Historia';
import KlientKonto from 'views/Klient/Konto';
import Driver from 'views/Admin/Driver';
import Kuchnia from 'views/Kuchnia/Kuchnia';
import Kierowca from 'views/Kierowca/Kierowca';
import PojedynczyKontrahent from 'views/Kierowca/PojedynczyKontrahent';
import WybraniKontahenci from 'views/Kierowca/WybraniKontrahenci';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import history from 'history.js';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

const Root = () => (
  <Provider store={store}>
    <Router history={history}>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <AlertProvider template={AlertTemplate} {...options}>
        <MainTemplate alert={{ type: '', message: '' }}>
          <Switch>
            <Route exact path={routes.home} render={() => <Redirect to="/login" />} />
            <Route exact path={routes.login} component={Login} />
            <Route exact path={routes.admin} component={Admin} />
            <Route exact path={routes.adminContractor} component={Admin} />
            <Route exact path={routes.zamowienia} component={Zamowienia} />
            <Route exact path={routes.kontrahenci} component={Kontrahenci} />
            <Route exact path={routes.kontrahent} component={Kontrahent} />
            <Route exact path={routes.uzytkownikFirmy} component={Uzytkownik} />
            <Route exact path={routes.dania} component={Dania} />
            <Route exact path={routes.menu} component={Menu} />
            <Route exact path={routes.users} component={Users} />
            <Route exact path={routes.user} component={User} />
            <Route exact path={routes.klient} component={Klient} />
            <Route exact path={routes.konto} component={KlientKonto} />
            <Route exact path={routes.historia} component={KlientHistoria} />
            <Route exact path={routes.kuchnia} component={Kuchnia} />
            <Route exact path={routes.kierowca} component={Kierowca} />
            <Route exact path={routes.kierowcaGodzina} component={WybraniKontahenci} />
            <Route exact path={routes.kierowcaKontrahent} component={PojedynczyKontrahent} />
            <Route exact path={routes.kierowcaKontrahentOddzial} component={PojedynczyKontrahent} />
            <Route exact path={routes.drivers} component={Driver} />
            <Route exact path={routes.currentDriver} component={Driver} />
            <Route exact path={routes.rejestracja} component={Rejestracja} />
          </Switch>
        </MainTemplate>
      </AlertProvider>
    </Router>
  </Provider>
);

export default Root;
