import axios from 'axios';
import {
  fetchKierowcaZamowieniaPending,
  fetchKierowcaZamowieniaError,
  fetchKierowcaZamowieniaSuccess,
  fetchKierowcaFirmyError,
  fetchKierowcaFirmyPending,
  fetchKierowcaFirmySuccess,
  fetchKierowcaGodzinyError,
  fetchKierowcaGodzinyPending,
  fetchKierowcaGodzinySuccess,
  fetchKierowcaStatusError,
  fetchKierowcaStatusPending,
  fetchKierowcaStatusSuccess,
} from 'actions/kierowcaActions';

export const pobierzKierowcaZamowieniaAction = (idGodziny, idFirmy, oddzialId) => dispatch => {
  dispatch(fetchKierowcaZamowieniaPending());
  return axios
    .post('/api/kierowca/zamowienia', { idGodziny, idFirmy, oddzialId })
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(fetchKierowcaZamowieniaSuccess(res.data));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchKierowcaZamowieniaError(error));
    });
};

export const pobierzKierowcaGodzinyAction = () => dispatch => {
  dispatch(fetchKierowcaGodzinyPending());
  return axios
    .get('/api/kierowca/godziny', {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(fetchKierowcaGodzinySuccess(res.data));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchKierowcaGodzinyError(error));
    });
};

export const pobierzKierowcaFirmyAction = idGodziny => dispatch => {
  dispatch(fetchKierowcaFirmyPending());
  return axios
    .get(`/api/kierowca/firmy/${idGodziny}`, {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(fetchKierowcaFirmySuccess(res.data));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchKierowcaFirmyError(error));
    });
};

export const setKierowcaStatusAction = (status, idZamowienia) => dispatch => {
  dispatch(fetchKierowcaStatusPending());
  return axios
    .post(`/api/kierowca/zamowienia/status`, { status, idZamowienia })
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      dispatch(fetchKierowcaStatusSuccess(res.data));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchKierowcaStatusError(error));
    });
};

export const setKierowcaPlatnosc = (status, id) =>
  axios
    .post(`/api/kierowca/platnosc`, { status, id })
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data;
    })
    .catch(() => {});
