import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button/Button';
import styled from 'styled-components';

const Wrapper = styled(Button)`
  height: 35vw;
  width: 35vw;
  max-height: 24rem;
  max-width: 24rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  &:nth-child(6n + 1) {
    background-color: #c1cf26;
    border-color: #c1cf26;
  }
  &:nth-child(6n + 2) {
    background-color: #6d5cce;
    border-color: #6d5cce;
  }
  &:nth-child(6n + 3) {
    background-color: #ce2055;
    border-color: #ce2055;
  }
  &:nth-child(6n + 4) {
    background-color: #00ff5d;
    border-color: #00ff5d;
  }
  &:nth-child(6n + 5) {
    background-color: #00ffff;
    border-color: #00ffff;
  }
  &:nth-child(6n + 6) {
    background-color: #750f0f;
    border-color: #750f0f;
  }
`;

const ZamowienieGodzina = ({ godzina }) => <Wrapper type="button">{godzina}</Wrapper>;

ZamowienieGodzina.propTypes = {
  godzina: PropTypes.string.isRequired,
};

export default ZamowienieGodzina;
