import React from 'react';
import PropTypes from 'prop-types';
import lunchbox from 'assets/svg/danie_dnia_2.svg';
import zupa from 'assets/svg/zupa.svg';
import salatka from 'assets/svg/salatka.svg';
import napoje from 'assets/svg/napoje_zimne.svg';
import deserimg from 'assets/svg/Desser2.svg';
import paymentMethod from 'assets/svg/payment-method.svg';
import styled from 'styled-components';

import './ZamowienieKontrahenci.scss';
// import { zapiszUzytkownikaAction } from 'services/admin/firmyServices';

const Wrapper = styled.div`
  width: 95%;
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid grey;
  margin-bottom: 1rem;
  padding: 0.6rem 0;
`;

const Name = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 40%;
  color: #000;
`;

const NumerIkona = styled.div`
  display: flex;
  align-items: center;
  min-width: 0rem;

  justify-content: flex-end;
  margin-left: 1rem;
  color: #000;
  img {
    margin-left: 0.5rem;
  }
`;

const ZamowienieKontrahenci = ({
  nazwa,
  doOplacenia,
  glowne,
  zupy,
  smoothie,
  deser,
  salatki,
  visibility,
}) => (
  <Wrapper>
    <Name>{nazwa}</Name>
    <NumerIkona className="ikony">
      <div className="divik">
        <div
          className="divikmini"
          style={{ visibility: glowne > 0 || visibility ? 'block' : 'hidden' }}
        >
          <div className="numerek">{glowne}</div>
          <img src={lunchbox} alt="Ilość zamówień" />
        </div>
        <div
          className="divikmini"
          style={{ visibility: salatki > 0 || visibility ? 'block' : 'hidden' }}
        >
          <div className="numerek">{salatki}</div>
          <img src={salatka} alt="Ilość zamówień" />
        </div>
        <div
          className="divikmini"
          style={{ visibility: zupy > 0 || visibility ? 'block' : 'hidden' }}
        >
          <div className="numerek">{zupy}</div>
          <img src={zupa} alt="Ilość zamówień" />
        </div>
        <div
          className="divikmini"
          style={{ visibility: smoothie > 0 || visibility ? 'block' : 'hidden' }}
        >
          <div className="numerek">{smoothie}</div>
          <img className="napoje" src={napoje} alt="Ilość zamówień" />
        </div>
        <div
          className="divikmini"
          style={{ visibility: deser > 0 || visibility ? 'block' : 'hidden' }}
        >
          <div className="numerek">{deser}</div>
          <img src={deserimg} alt="Ilość zamówień" />
        </div>
      </div>
    </NumerIkona>
    <NumerIkona>
      {doOplacenia}
      <img src={paymentMethod} alt="Do opłacenia" />
    </NumerIkona>
  </Wrapper>
);

ZamowienieKontrahenci.propTypes = {
  nazwa: PropTypes.string.isRequired,
  // ilosc: PropTypes.number,
  zupy: PropTypes.number,
  glowne: PropTypes.number,
  smoothie: PropTypes.number,
  deser: PropTypes.number,
  salatki: PropTypes.number,
  doOplacenia: PropTypes.number,
  visibility: PropTypes.bool,
};

ZamowienieKontrahenci.defaultProps = {
  // ilosc: 10,
  doOplacenia: 1,
  zupy: 1,
  glowne: 1,
  smoothie: 1,
  salatki: 1,
  deser: 1,
  visibility: false,
};

export default ZamowienieKontrahenci;
