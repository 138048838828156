import axios from 'axios';
import { addAlert } from 'actions/alertActions';

import {
  pobierzDanePending,
  pobierzDaneSuccess,
  pobierzDaneError,
  authenticatePending,
  authenticateSuccess,
  authenticateError,
  zapiszDanePending,
  zapiszDaneSuccess,
  zapiszDaneError,
} from 'actions/userActions';

export const authenticate = (login, password) => dispatch => {
  dispatch(authenticatePending());

  return axios
    .post('/api/authenticate', { login, password })
    .then(payload => {
      // console.log(payload);
      // weryfikować wchodzące dane
      addAlert({ title: 'Logowanie', message: 'Zalogowałeś się poprawnie', type: 'success' });
      dispatch(authenticateSuccess(payload));
      return payload;
    })
    .catch(err => {
      addAlert({
        title: 'Logowanie',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      dispatch(authenticateError());
    });
};

export const register = values => dispatch => {
  dispatch(authenticatePending());

  return axios
    .post('/api/register', { values })
    .then(payload => {
      // console.log(payload);
      // weryfikować wchodzące dane
      addAlert({
        title: 'Rejestracja',
        message: 'Konto zostało utworzone, poczekaj na akceptację administratora',
        type: 'success',
      });
      dispatch(authenticateSuccess(payload));
    })
    .catch(err => {
      addAlert({
        title: 'Rejestracja',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      dispatch(authenticateError());
    });
};

export const checkUser = () => dispatch => {
  dispatch(authenticatePending());
  return axios
    .post('/api/checkUser', {})
    .then(payload => {
      // console.log(payload);
      // weryfikować wchodzące dane
      // addAlert({ title: 'Logowanie', message: 'Zalogowałeś się poprawnie', type: 'success' });
      dispatch(authenticateSuccess(payload));
    })
    .catch(err => {
      addAlert({
        title: 'Logowanie',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      dispatch(authenticateError());
    });
};

export const logOut = () =>
  axios
    .get('/api/logout', {})
    .then(
      () =>
        // console.log(payload);
        true,
    )
    .catch(err => {
      addAlert({
        title: 'Logowanie',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });

export const pobierzMojeDaneAction = () => dispatch => {
  dispatch(pobierzDanePending());
  return (
    axios
      .get('/api/uzytkownicy/me', {})
      // .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error;
        }

        dispatch(pobierzDaneSuccess(res.data.data));
        return res.data.data;
      })
      .catch(error => {
        dispatch(pobierzDaneError(error));
      })
  );
};

export const pobierzMojaFirmaAction = () =>
  axios
    .get(`/api/uzytkownicy/moja-firma`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Moja firm',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });

export const zapiszMojeDaneAction = dane => dispatch => {
  dispatch(zapiszDanePending());

  return axios
    .post('/api/uzytkownicy/me/zapisz', { dane })
    .then(payload => {
      // console.log(payload);

      addAlert({
        title: 'Moje konto',
        message: 'Dane zostały zapisane poprawnie',
        type: 'success',
      });
      dispatch(zapiszDaneSuccess(payload));
    })
    .catch(err => {
      addAlert({
        title: 'Moje konto',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
      dispatch(zapiszDaneError());
    });
};
