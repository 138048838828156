import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import { logOut } from 'services/userServices';
import { Redirect } from 'react-router-dom';

const LogoutButton = styled(Button)`
  font-size: 1.4rem;
  line-height: 1;
  position: ${({ absolute }) => (absolute ? 'absolute' : 'static')};
  z-index: 1;
`;

const Logout = props => {
  const [redirect, setRedirect] = useState('Test');
  const { absolute } = props;
  return (
    <>
      {redirect && redirect === 'login' ? (
        <Redirect to="/login" />
      ) : (
        <LogoutButton
          absolute={absolute}
          nopadding
          responsive
          onClick={() => {
            logOut().then(() => {
              setRedirect('login');
            });
          }}
        >
          Wyloguj się
        </LogoutButton>
      )}
    </>
  );
};

Logout.propTypes = {
  absolute: PropTypes.bool,
};

Logout.defaultProps = {
  absolute: false,
};

export default Logout;
