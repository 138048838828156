import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import KlientTemplate from 'templates/KlientTemplate';
import Button from 'components/atoms/Button/Button';
import gruszka1 from 'assets/images/gruszka2.png';
import gruszka2 from 'assets/images/gruszka3.png';
import { pobierzMojeDaneAction, zapiszMojeDaneAction } from 'services/userServices';
import * as Yup from 'yup';
import styled from 'styled-components';

import {
  GruszkaPierwsza,
  GruszkaDruga,
  Formularz,
  KontoWrapper,
  Section as KontoSection,
  H2 as KontoH2,
  Input,
  Alert,
  Label,
} from './style';

export const Section = styled(KontoSection)`
  width: 600px;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const H2 = styled(KontoH2)`
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
`;

const KontoSchema = Yup.object().shape({
  imie: Yup.string().required('Pole jest wymagane!'),
  nazwisko: Yup.string().required('Pole jest wymagane!'),
  login: Yup.string()
    .min(2, 'Login jest za krótki!')
    .max(50, 'Login jest za długi!')
    .required('Pole jest wymagane!'),
  password: Yup.string()
    .min(5, 'Hasło jest za krótkie!')
    .max(50, 'Hasło jest za długie!'),
  // .required('Pole jest wymagane!'),
  passwordConfirm: Yup.string()
    // .oneOf([Yup.ref('password'), null], 'Potwierdzenie hasła musi być takie samo!')
    .test('passwordConfirm', 'Potwierdzenie hasła jest wymagane!', function test(value) {
      const passValue = this.resolve(Yup.ref('password'));
      if (passValue) {
        return passValue === value;
      }
      return true;
    }),
  // .required('Potwierdzenie hasła jest wymagane!'),
});

class KlientKonto extends React.Component {
  state = {
    submitButton: 'Zatwierdź zmiany',
    mojeDane: {
      imie: '',
      nazwisko: '',
      telefon: '',
      email: '',
      zgodaMEmail: true,
      zgodaMSms: true,
      login: '',
    },
  };

  componentDidMount() {
    const { pobierzMojeDane } = this.props;
    pobierzMojeDane().then(dane => {
      this.setState({ mojeDane: dane });
    });
  }

  handleSubmit = ({ imie, nazwisko, telefon, email, zgodaMEmail, zgodaMSms, login, password }) => {
    const { zapiszMojeDane } = this.props;
    zapiszMojeDane({ imie, nazwisko, telefon, email, zgodaMEmail, zgodaMSms, login, password });
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      mojeDane: {
        ...prevState.mojeDane,
        [name]: value,
        changed: true,
      },
    }));
  }

  render() {
    const { submitButton, mojeDane } = this.state;

    return (
      <KlientTemplate title="Moje konto" subtitle="Możesz edytować dane swojego konta">
        <GruszkaPierwsza src={gruszka1} alt="" />
        <GruszkaDruga className="login_img" src={gruszka2} alt="" />
        <KontoWrapper>
          <Section>
            <H2>Dane kontaktowe</H2>
            {mojeDane && mojeDane.imie && (
              <Formik
                initialValues={{
                  imie: mojeDane.imie,
                  nazwisko: mojeDane.nazwisko,
                  telefon: mojeDane.telefon,
                  email: mojeDane.email,
                  zgodaMEmail: mojeDane.zgodaMEmail,
                  zgodaMSms: mojeDane.zgodaMSms,
                  login: mojeDane.login,
                  password: '',
                  passwordConfirm: '',
                }}
                onSubmit={this.handleSubmit}
                validationSchema={KontoSchema}
              >
                {({ errors, touched }) => (
                  <Formularz>
                    <Label htmlFor="imie">
                      <span>imię</span>
                      <Input name="imie" type="text" placeholder="Twóje imię" autoComplete="name" />
                      <Alert>
                        {errors.imie && touched.imie ? <span>{errors.imie}</span> : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="nazwisko">
                      <span>nazwisko</span>
                      <Input
                        name="nazwisko"
                        type="text"
                        placeholder="Twóje nazwisko"
                        autoComplete="surname"
                      />
                      <Alert>
                        {errors.nazwisko && touched.nazwisko ? (
                          <span>{errors.nazwisko}</span>
                        ) : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="telefon">
                      <span>telefon</span>
                      <Input
                        name="telefon"
                        type="text"
                        placeholder="Twój numer telefonu"
                        autoComplete="phone"
                      />
                      <Alert>
                        {errors.telefon && touched.telefon ? <span>{errors.telefon}</span> : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="email">
                      <span>e-mail</span>
                      <Input
                        name="email"
                        type="email"
                        placeholder="Twój adres e-mail"
                        autoComplete="e-mail"
                      />
                      <Alert>
                        {errors.email && touched.email ? <span>{errors.email}</span> : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="login">
                      <span>login</span>
                      <Input
                        name="login"
                        type="text"
                        placeholder="Twój login"
                        autoComplete="login"
                      />
                      <Alert>
                        {errors.login && touched.login ? <span>{errors.login}</span> : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="password">
                      <span>hasło</span>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Twoje hasło"
                        autoComplete="password"
                      />
                      <Alert>
                        {errors.password && touched.password ? (
                          <span>{errors.password}</span>
                        ) : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="passwordConfirm">
                      <Input
                        name="passwordConfirm"
                        type="password"
                        placeholder="Potwierdź hasło"
                        autoComplete="password"
                      />
                      <Alert>
                        {errors.passwordConfirm && touched.passwordConfirm ? (
                          <span>{errors.passwordConfirm}</span>
                        ) : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="zgodaMEmail">
                      <Input
                        render={({ field }) => {
                          return (
                            <Input
                              type="checkbox"
                              checked={field.value}
                              {...field}
                              id="zgodaMEmail"
                            />
                          );
                        }}
                        name="zgodaMEmail"
                        checked={mojeDane.zgodaMEmail}
                      />

                      <span>
                        Zgadzam się na przesyłanie informacji marketingowych przez cotygodniowy
                        newsletter.
                      </span>
                      <Alert>
                        {errors.zgodaMEmail && touched.zgodaMEmail ? (
                          <span>{errors.zgodaMEmail}</span>
                        ) : null}
                      </Alert>
                    </Label>
                    <Label htmlFor="zgodaMSms">
                      <Input
                        render={({ field }) => {
                          return (
                            <Input
                              type="checkbox"
                              checked={field.value}
                              {...field}
                              id="zgodaMSms"
                            />
                          );
                        }}
                        name="zgodaMSms"
                        checked={mojeDane.zgodaMSms}
                      />
                      <span>Zgadzam się na przesyłanie informacji marketingowych poprzez SMS.</span>
                      <Alert>
                        {errors.zgodaMSms && touched.zgodaMSms ? (
                          <span>{errors.zgodaMSms}</span>
                        ) : null}
                      </Alert>
                    </Label>
                    <Button type="submit">{submitButton}</Button>
                  </Formularz>
                )}
              </Formik>
            )}
          </Section>
        </KontoWrapper>
      </KlientTemplate>
    );
  }
}

KlientKonto.propTypes = {
  mojeDane: PropTypes.shape({
    imie: PropTypes.string.isRequired,
    nazwisko: PropTypes.string.isRequired,
    telefon: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    zgodaMEmail: PropTypes.bool.isRequired,
    zgodaMSms: PropTypes.bool.isRequired,
    login: PropTypes.string.isRequired,
  }),

  pobierzMojeDane: PropTypes.func,
  zapiszMojeDane: PropTypes.func,
};

KlientKonto.defaultProps = {
  mojeDane: null,
  pobierzMojeDane: null,
  zapiszMojeDane: null,
};

const mapStateToProps = state => {
  return {
    // error: state.zamowieniaReducer.error,
    mojeDane: state.userReducer.mojeDane,
    // pendingPobierz: state.zamowieniaReducer.pendingWyslij,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzMojeDane: pobierzMojeDaneAction,
      zapiszMojeDane: zapiszMojeDaneAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KlientKonto);
