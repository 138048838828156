import React, { useState } from 'react';
import { Tabs } from 'react-tabs';
import AdminTemplate from 'templates/AdminTemplate';
import styled, { css } from 'styled-components';
import { theme } from 'theme/adminTheme';
import { NavLink } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';

import './Admin.css';

import ControlPanel from 'components/organisms/ControlPanel/ControlPanel';
import { Wrapper } from './style';

const StyledTabList = styled.div`
  padding: 10rem 2.8rem 0 2.8rem;
  width: 300px;

  min-height: calc(100vh);
  background: #f5e8df;
  margin: 0;
  list-style: none;
  z-index: 2;

  @media screen and (max-width: 1365px) {
    position: fixed;
  }

  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: ${theme.size.laptop}) {
        // display: none;
      }
      @media screen and (max-width: 1365px) {
        position: fixed;
        left: 0px;
        top: 80px;
        width: 300px;
        opacity: 1;

        padding: 5rem 2.8rem 5rem 2.8rem;
        background: #f5e8df;
        margin: 0;
        list-style: none;
        z-index: 1;
        display: flex;
        flex-direction: column;
        border-bottom-right-radius: 5px;
      }
    `}
`;

const StyledTab = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.25em;
  color: #fff;
  padding: 1.2rem 2rem;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease;
  background: #000;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}

  &.react-tabs__tab--selected {
    background: #000;
    border-color: #000;
    color: #fff;
    border-radius: 6px;
  }
  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.3);
  }
  & + & {
    margin-top: 0.4rem;
  }
`;

const AdminWrapper = styled(Wrapper)`
  min-height: 100vh;
  position: relative;
`;

const Arrow = styled.div`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const ArrowCont = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 300px;
  background-color: #f5e8df;
  width: 4rem;
  height: 4rem;
  border-bottom-right-radius: 5px;

  opacity: 2;
`;

const Admin = () => {
  const [navNone, setNavNone] = useState(false);

  return (
    <>
      <AdminTemplate>
        <Tabs defaultIndex={0}>
          <AdminWrapper>
            {/* <StyledTab2 className="dsad" onClick={() => setNavNone(!navNone)}>
              asdasdasdasd
            </StyledTab2> */}

            <StyledTabList
              style={{
                transition: '1s',
                left: navNone ? 0 : '-300px',
              }}
              responsive
            >
              <ArrowCont onClick={() => setNavNone(!navNone)}>
                <Arrow
                  style={{
                    transition: '1s',
                    transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
                  }}
                />
              </ArrowCont>

              <NavLink to="/admin/dzien">
                <StyledTab>Dzień</StyledTab>
              </NavLink>
              <NavLink to="/admin/tydzien">
                <StyledTab>Tydzień</StyledTab>
              </NavLink>
              <NavLink to="/admin/miesiac">
                <StyledTab>Miesiąc</StyledTab>
              </NavLink>
            </StyledTabList>
            <ControlPanel />
          </AdminWrapper>
        </Tabs>
      </AdminTemplate>
    </>
  );
};

export default Admin;
