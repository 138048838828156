import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  border: 1px solid #aebdc2;
  text-transform: uppercase;
  background-color: #fff;
`;

export default function RowTitleBox(props) {
  const { handleClick, title } = props;
  return (
    <Col
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      <Wrapper>{title}</Wrapper>
    </Col>
  );
}

RowTitleBox.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func,
};

RowTitleBox.defaultProps = {
  title: 'Target',
  handleClick: null,
};

// RowTitleBox;
