export const ZAPISZ_DANE_PENDING = 'ZAPISZ_DANE_PENDING';
export const ZAPISZ_DANE_SUCCESS = 'ZAPISZ_DANE_SUCCESS';
export const ZAPISZ_DANE_ERROR = 'ZAPISZ_DANE_ERROR';
export const POBIERZ_DANE_PENDING = 'POBIERZ_DANE_PENDING';
export const POBIERZ_DANE_SUCCESS = 'POBIERZ_DANE_SUCCESS';
export const POBIERZ_DANE_ERROR = 'POBIERZ_DANE_ERROR';
export const AUTHENTICATE_REQUEST = 'AUTHENTICATE_REQUEST';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE';
export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const SET_USER_PENDING = 'SET_USER_PENDING';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_ERROR = 'SET_USER_ERROR';

export function authenticatePending() {
  return {
    type: AUTHENTICATE_REQUEST,
  };
}

export function authenticateSuccess(payload) {
  return {
    type: AUTHENTICATE_SUCCESS,
    payload,
  };
}

export function authenticateError(error) {
  return {
    type: AUTHENTICATE_FAILURE,
    error,
  };
}

export function zapiszDanePending() {
  return {
    type: ZAPISZ_DANE_PENDING,
  };
}

export function zapiszDaneSuccess() {
  return {
    type: ZAPISZ_DANE_SUCCESS,
  };
}

export function zapiszDaneError(error) {
  return {
    type: ZAPISZ_DANE_ERROR,
    error,
  };
}

export function pobierzDanePending() {
  return {
    type: POBIERZ_DANE_PENDING,
  };
}

export function pobierzDaneSuccess(data) {
  return {
    type: POBIERZ_DANE_SUCCESS,
    dane: data,
  };
}

export function pobierzDaneError(error) {
  return {
    type: POBIERZ_DANE_ERROR,
    error,
  };
}

export function fetchUsersPending() {
  return {
    type: FETCH_USERS_PENDING,
  };
}

export function fetchUsersSuccess(data) {
  return {
    type: FETCH_USERS_SUCCESS,
    data,
  };
}

export function fetchUsersError(error) {
  return {
    type: FETCH_USERS_ERROR,
    error,
  };
}

export function fetchUserPending() {
  return {
    type: FETCH_USER_PENDING,
  };
}

export function fetchUserSuccess(data) {
  return {
    type: FETCH_USER_SUCCESS,
    data,
  };
}

export function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    error,
  };
}

export function setUserPending() {
  return {
    type: SET_USER_PENDING,
  };
}

export function setUserSuccess(data) {
  return {
    type: SET_USER_SUCCESS,
    data,
  };
}

export function setUserError(error) {
  return {
    type: SET_USER_ERROR,
    error,
  };
}
