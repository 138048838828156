import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import KierowcaTemplate from 'templates/KierowcaTemplate';
import { pobierzKierowcaGodzinyAction } from 'services/kierowcaZamowieniaService';
import ZamowienieGodzina from 'components/molecules/ZamowienieGodziny/ZamowienieGodziny';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from 'components/atoms/Button/Button';
import { InitialStateWrapper, H2, GodzinyWrapper } from './style';

const StyledDiv = styled.div`
  margin-bottom: 2rem;
`;

const StyledNavLink = styled(NavLink)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class KierowcaLayout extends React.Component {
  componentDidMount() {
    const { pobierzGodziny } = this.props;
    pobierzGodziny();
  }

  render() {
    const { godziny, pobierzGodziny } = this.props;

    return (
      <KierowcaTemplate>
        <InitialStateWrapper>
          <StyledHeadWrapper>
            <H2 rank={2}>Wyjazdy</H2>
            <Button nopadding onClick={() => pobierzGodziny()}>
              Odśwież
            </Button>
          </StyledHeadWrapper>

          <GodzinyWrapper>
            {godziny.map(item => (
              <StyledNavLink
                key={item.godzinaWyjazduId}
                to={`/kierowca/godzina/${item.godzinaWyjazduId}`}
              >
                <StyledDiv>
                  <ZamowienieGodzina godzina={item.godzinaWyjazdu} />
                </StyledDiv>
              </StyledNavLink>
            ))}
          </GodzinyWrapper>
        </InitialStateWrapper>
      </KierowcaTemplate>
    );
  }
}

KierowcaLayout.propTypes = {
  pobierzGodziny: PropTypes.func,
  godziny: PropTypes.arrayOf(
    PropTypes.shape({ godzinaWyjazdu: PropTypes.string, godzinaWyjazduId: PropTypes.number }),
  ),
};

KierowcaLayout.defaultProps = {
  pobierzGodziny: null,
  godziny: [],
};

const mapStateToProps = state => ({
  godziny: state.kierowcaReducer.godziny,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzGodziny: pobierzKierowcaGodzinyAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KierowcaLayout);
