import React from 'react';
import PropTypes from 'prop-types';
// import { pobierzFirmaByIdAction } from 'services/admin/firmyServices';
// import styled from 'styled-components';
import { pobierzFirmyAction, pobierzGodzinyAction } from 'services/admin/firmyServices';
import {
  SidebarHeader,
  DropdownWrapper,
  Tab,
  TabLink,
  StyledTabListA,
  ArrowContC,
  ArrowA,
} from 'views/Admin/style';
import Dropdown from 'components/molecules/Dropdown/Dropdown';

class SidebarFirmy extends React.Component {
  _isMounted = false;

  state = {
    firmy: [],
    godziny: [],
    navNone: false,
  };

  componentDidMount() {
    this._isMounted = true;
    const { firmy, godziny } = this.props;
    if (firmy.length) {
      this.setState({ firmy });
    } else {
      pobierzFirmyAction().then(pobraneFirmy => {
        if (this._isMounted) {
          this.setState({ firmy: pobraneFirmy });
        }
      });
    }
    if (godziny.length) {
      this.setState({ godziny });
    } else {
      pobierzGodzinyAction().then(pobraneGodziny => {
        if (this._isMounted) {
          this.setState({ godziny: pobraneGodziny });
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onMenuItemClicked = () => {
    this.setState(prevState => ({ navNone: !prevState.navNone }));
  };

  render() {
    const { firmy, godziny, navNone } = this.state;
    return (
      <StyledTabListA
        style={{
          transition: '1s',
          left: navNone ? '0px' : '-300px',
        }}
        responsive
      >
        <SidebarHeader>
          <TabLink to="/kontrahenci">Kontrahenci</TabLink>
        </SidebarHeader>
        <DropdownWrapper>
          {godziny &&
            firmy.length > 0 &&
            godziny.map(item => (
              <Dropdown key={item.id} godzina={item.godzinaWyjazdu}>
                {firmy &&
                  firmy.map(c => {
                    return c.godzinyWyjazdu.map(currentValue => {
                      if (currentValue.id === item.id) {
                        return (
                          <Tab key={c.id}>
                            <TabLink to={`/kontrahent/${c.id}`}>{c.nazwa}</TabLink>
                          </Tab>
                        );
                      }
                      return null;
                    });
                  })}
              </Dropdown>
            ))}
        </DropdownWrapper>
        <ArrowContC
          onClick={this.onMenuItemClicked}
          style={{
            transition: '1s',
            left: navNone ? '300px' : '0px',
          }}
        >
          <ArrowA
            style={{
              transition: '1s',
              transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
            }}
          />
        </ArrowContC>
      </StyledTabListA>
    );
  }
}

SidebarFirmy.propTypes = {
  // className: PropTypes.string,
  firmy: PropTypes.arrayOf(PropTypes.object),
  godziny: PropTypes.arrayOf(PropTypes.object),
};

SidebarFirmy.defaultProps = {
  // className: 'className',
  firmy: [],
  godziny: [],
};

export default SidebarFirmy;
