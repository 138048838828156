import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  color: black;
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: 400;
  align-items: flex-start;
  @media (max-width: 991px) {
    font-size: 1.5rem;
    padding: 0rem;
    flex-grow: 1;
  }
`;

const Sztuki = styled.div`
  min-width: 55px !important;
  text-align: left;
  @media (max-width: 991px) {
    font-size: 1.5rem;
    flex-grow: 0;
  }
`;

const Nazwa = styled.div`
  margin-left: 3rem;
  text-align: left;
  @media (max-width: 991px) {
    font-size: 1.5rem;
    flex-grow: 0;
    margin-left: 1rem;
  }
`;

const ZamowienieDetails = ({ nazwa, cena, ilosc }) => (
  <Wrapper cena={cena}>
    <Sztuki>{ilosc} szt.</Sztuki>
    <Nazwa>{nazwa}</Nazwa>
  </Wrapper>
);

ZamowienieDetails.propTypes = {
  ilosc: PropTypes.number.isRequired,
  cena: PropTypes.number.isRequired,
  nazwa: PropTypes.string.isRequired,
};

export default ZamowienieDetails;
