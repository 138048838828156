import axios from 'axios';

import pobierzZamowieniaAction from 'services/admin/pobierzZamowienia';

import {
  fetchDaniaPending,
  fetchDaniaSuccess,
  fetchDaniaError,
  // eslint-disable-next-line no-unused-vars
  fetchDaniaLokalSuccess,
  fetchDaniaDoMenuPending,
  fetchDaniaDoMenuSuccess,
  fetchDaniaDoMenuError,
  pobierzKategorieDanPending,
  pobierzKategorieDanSuccess,
  pobierzKategorieDanError,
  usunDaniePending,
  /* usunDanieSuccess, */ usunDanieError,
  zapiszDaniePending,
  zapiszDanieSuccess,
  zapiszDanieError,
} from 'actions/daniaActions';

import {
  usunZamowieniePending,
  // fetchZamowieniaPending,
  // fetchZamowieniaSuccess,
  // fetchZamowieniaError,
  //  usunZamowienieSuccess,
  usunZamowienieError,
} from 'actions/zamowieniaActions';

import { addAlert } from 'actions/alertActions';

export const pobierzWszytkieDaniaAction = bezDodatkow => dispatch => {
  dispatch(fetchDaniaPending());
  return axios
    .get(bezDodatkow ? '/api/admin/dania/wszystkie/bez-dodatkow' : '/api/admin/dania/wszystkie')
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(fetchDaniaSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(fetchDaniaError(error));
    });
};

// export const pobierzWszystkieZamowieniaAction = () => dispatch => {
//   dispatch(fetchZamowieniaPending());
//   return axios
//     .get('/api/admin/zamowienia')
//     .then(res => {
//       if (res.error) {
//         throw res.error;
//       }
//       dispatch(fetchZamowieniaSuccess(res.data.data));
//       return res.data.data;
//     })
//     .catch(error => {
//       dispatch(fetchZamowieniaError(error));
//     });
// };

// export const pobierzLokalDaniaAction = () => dispatch => {
//   dispatch(fetchDaniaPending());
//   return axios
//     .get('/api/admin/dania/lokal')
//     .then(res => {
//       if (res.error) {
//         throw res.error;
//       }
//       dispatch(fetchDaniaLokalSuccess(res.data.data));
//       return res.data.data;
//     })
//     .catch(error => {
//       dispatch(fetchDaniaError(error));
//     });
// };

export const pobierzDaniaDoMenuAction = menuData => dispatch => {
  dispatch(fetchDaniaDoMenuPending());
  return axios
    .get(`/api/admin/menu/${menuData || ''}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(fetchDaniaDoMenuSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(fetchDaniaDoMenuError(error));
    });
};

export const usunDanie = id => dispatch => {
  dispatch(usunDaniePending());
  return axios
    .post('/api/admin/danie/usun', { id })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      if (res.data.result === 1) {
        dispatch(pobierzWszytkieDaniaAction());
        addAlert({ message: `Danie zostało usunięte!`, type: 'success' });
      }
      return res.data.result;
    })
    .catch(error => {
      dispatch(usunDanieError(error));
    });
};

export const usunZamowienie = (id, dataMenu) => dispatch => {
  dispatch(usunZamowieniePending());
  return axios
    .post('/api/admin/zamowienia/usun', { id })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      // console.log(res);
      if (res.data.result === true) {
        dispatch(
          pobierzZamowieniaAction(
            `${dataMenu.getFullYear()}-${dataMenu.getMonth() + 1}-${dataMenu.getDate()}`,
          ),
        );
        addAlert({ message: `Zamówienie zostało usunięte!`, type: 'success' });
      }
      return res.data.result;
    })
    .catch(error => {
      dispatch(usunZamowienieError(error));
    });
};

export const pobierzZamowienieDrag = (kierowcaId, godzinaWyjazduId) => {
  return axios
    .get(`/api/admin/zamowienia/kierowca/${kierowcaId}/godzina/${godzinaWyjazduId}`)
    .then(payload => {
      return payload.data.data;
    })
    .catch(err => {
      addAlert({
        title: 'Drag przypis',
        message: err.response.data.error ? 'Wystąpił nieznany błąd!' : err.response.data.error,
        type: 'danger',
      });
    });
};

export const odbierzZamowienie = () => dispatch => {
  dispatch(pobierzKategorieDanPending());
  return axios
    .get('/api/admin/zamowienia/przypisz', {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(pobierzKategorieDanSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(pobierzKategorieDanError(error));
    });
};

export const zapiszDanie = danie => dispatch => {
  dispatch(zapiszDaniePending());
  return axios
    .post('/api/admin/danie/zapisz', { danie })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(zapiszDanieSuccess());

      addAlert({ message: `Danie zostało zapisane!`, type: 'success' });
      dispatch(pobierzWszytkieDaniaAction());
      return res.data.data;
    })
    .catch(error => {
      // console.log(error);
      addAlert({ title: 'Błąd!', message: error, type: 'error' });
      dispatch(zapiszDanieError(error));
    });
};

export const zapiszDanieWMenu = (danie, menuId) => {
  return axios
    .post('/api/admin/menu/zapisz-danie', { danie, menuId })
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      // dispatch(zapiszDanieSuccess());

      addAlert({ message: `Danie zostało zapisane!`, type: 'success' });
      // dispatch(pobierzWszytkieDaniaAction());
      return res.data.data;
    })
    .catch(error => {
      // console.log(error);
      addAlert({ title: 'Błąd!', message: error, type: 'error' });
      // dispatch(zapiszDanieError(error));
    });
};

export const pobierzKategorieDanAction = () => dispatch => {
  dispatch(pobierzKategorieDanPending());
  return axios
    .get('/api/admin/kategorieDan', {})
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      dispatch(pobierzKategorieDanSuccess(res.data.data));
      return res.data.data;
    })
    .catch(error => {
      dispatch(pobierzKategorieDanError(error));
    });
};

export const fetchDania = (type, id) => {
  if (id) {
    return axios
      .get(`/api/admin/dishes/${type}/${id}`)
      .then(res => {
        if (res.error) {
          throw res.error;
        }

        return res.data;
      })
      .catch(() => {});
  }
  return axios
    .get(`/api/admin/dishes/${type}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data;
    })
    .catch(() => {});
};

export const fetchRevenues = (type, id) => {
  if (id) {
    return axios
      .get(`/api/admin/revenues/${type}/${id}`)
      .then(res => {
        if (res.error) {
          throw res.error;
        }

        return res.data;
      })
      .catch(() => {});
  }
  return axios
    .get(`/api/admin/revenues/${type}`)
    .then(res => {
      if (res.error) {
        throw res.error;
      }

      return res.data;
    })
    .catch(() => {});
};
