import Heading from 'components/atoms/Heading/Heading';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';

export const InitialStateWrapper = styled.div`
  padding-top: 1rem;
`;

export const H2 = styled(Heading)`
  text-align: center;

  margin: 1rem 0 2rem;
  font-size: 1.8rem;
  font-weight: 700;
`;

export const Nazwa = styled.div`
  line-height: 1.2em;
  color: #000;
  width: 35%;
`;

export const Danie = styled.div`
  margin-left: 2rem;
  line-height: 1.2em;
  text-align: left;
  flex-grow: 1;
`;

export const NumerIkona = styled.div`
  display: flex;
  align-items: center;
  min-width: 5.4rem;
  margin-left: 1.6rem;
  line-height: 1.2em;
`;

export const GodzinyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 75vw;
  max-width: 54rem;
  margin: 2rem auto 0;
`;

export const WszyscyKontrahenciWrapper = styled.div`
  padding-top: 2rem;
`;

export const PojedynczyKontrahentWrapper = styled.div`
  padding-top: 1rem;
`;

export const WybraniKontrahenciWrapper = styled.div`
  padding-top: 2rem;
`;

export const Btn = styled(Button)`
  padding: 0;
  border: none;
  background: transparent;
  margin: ${({ godzinowy }) => (godzinowy ? '0 0 5vw;' : '0')};
  width: ${({ godzinowy }) => (godzinowy ? 'auto' : '100%')};
`;

export const KontrahentHeader = styled.div`
  background: #fff;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  padding: 1rem 15px;
  min-height: 4rem;
  border-bottom: 1px solid #000;
  font-weight: 700;
`;
