import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tick from 'assets/svg/akcept.svg';
import Obraz from 'components/atoms/Obraz/Obraz';
import minus from 'assets/svg/minus.svg';
import Button from 'components/atoms/Button/Button';

import obrazkiDan from 'components/atoms/Obraz/ObrazkiDan';
import Confirm from 'components/molecules/Confirm/Confirm';
import { zapiszDanie, usunDanie } from 'services/admin/daniaServices';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  font-weight: ${({ head }) => (head ? 700 : 400)};
  max-width: ${({ thin }) => (thin ? '12rem' : '100%')};
  position: relative;
  label {
    &:hover {
      cursor: pointer;
    }
    img {
      position: relative;
      z-index: 2;
    }
  }
`;

const Select = styled.select`
  width: 100%;
  border: 1px solid #80cf26;
  border-radius: 0.5rem;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  border: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  width: ${({ center }) => (center ? 'auto' : '100%')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  &[name='cena'] {
    width: 7rem;
  }
  &:hover {
    border-bottom-color: #80cf26;
  }
  &:focus {
    outline: none;
    border-color: #80cf26;
    background-color: rgba(128, 207, 38, 0.4);
  }
`;

const Wege = styled(Obraz)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: block;
`;

const AddNew = styled(Button)`
  border-width: 0;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
`;

const InputCheckbox = styled.input`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

class DaniaAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      danieData: {
        id: props.id,
        index: props.index,
        nazwa: props.nazwa,
        kategoriaId: props.kategoriaId,
        rodzaj: props.rodzaj,
        nazwaKategorii: props.nazwaKategorii,
        cena: props.cena,
        wege: props.wege,
        status: props.status,
        kodDania: props.kodDania,
        changed: false,
      },
      showConfirm: false,
    };
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { danieData } = this.state;
    const { kategorieDan } = this.props;
    if (e.target.type === 'checkbox') {
      this.setState(prevState => ({
        ...prevState,
        danieData: {
          ...prevState.danieData,
          [name]: danieData[name] === 0 ? 1 : 0,
          changed: true,
        },
      }));
    } else if (name === 'kategoriaId') {
      this.setState(prevState => ({
        ...prevState,
        danieData: {
          ...prevState.danieData,
          [name]: value,
          rodzaj: kategorieDan.find(kat => {
            return kat.id === parseInt(value, 10);
          }).rodzaj,
          changed: true,
        },
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        danieData: {
          ...prevState.danieData,
          [name]: value,
          changed: true,
        },
      }));
    }
  }

  handleBlur() {
    const { zapiszDanieService } = this.props;
    const { danieData } = this.state;
    const { changed } = danieData;

    if (changed && danieData.id) {
      zapiszDanieService(danieData).then(() => {
        this.setState(prevState => ({
          ...prevState,
          danieData: {
            ...prevState.danieData,
            changed: false,
          },
        }));
      });
    }
  }

  usunDanie(id) {
    const { usunDanieService } = this.props;
    this.toggleConfirm();
    usunDanieService(id);
  }

  toggleConfirm() {
    const { showConfirm } = this.state;
    this.setState({ showConfirm: !showConfirm });
  }

  render() {
    const { danieData, showConfirm } = this.state;
    const {
      id,
      index,
      nazwa,
      kategoriaId,
      rodzaj,
      cena,
      wege,
      kodDania,
      status,
      nazwaKategorii,
    } = danieData;
    const { kategorieDan } = this.props;

    return (
      <>
        <Cell center thin>
          {index}.
        </Cell>
        <Cell>
          <Input
            type="text"
            name="nazwa"
            value={nazwa}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          />
        </Cell>
        <Cell center thin>
          {obrazkiDan[nazwaKategorii] && (
            <img
              width={nazwaKategorii === 'Napoje Zimne' ? '16px' : '26px'}
              src={obrazkiDan[nazwaKategorii]}
              alt={nazwaKategorii}
              title={rodzaj}
            />
          )}
        </Cell>
        <Cell>
          <Select
            name="kategoriaId"
            value={kategoriaId}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          >
            {kategorieDan.map(kategoria => {
              return (
                <option key={kategoria.id} value={kategoria.id}>
                  {kategoria.nazwa}
                </option>
              );
            })}
          </Select>
        </Cell>
        <Cell center thin>
          <Input
            center
            type="text"
            name="cena"
            value={cena}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
            /* value={`${this.state.cena.toFixed(2)} zł`} */
          />
        </Cell>
        <Cell>
          <Input
            type="text"
            name="kodDania"
            value={kodDania}
            onChange={e => this.handleInputChange(e)}
            onBlur={e => this.handleBlur(e)}
          />
        </Cell>
        <Cell center thin>
          <label htmlFor={`Wege_${id}`}>
            {wege !== 0 && <Wege src={tick} />}
            <InputCheckbox
              type="checkbox"
              name="wege"
              id={`Wege_${id}`}
              onChange={e => this.handleInputChange(e)}
              onBlur={e => this.handleBlur(e)}
              checked={wege !== 0}
            />
          </label>
        </Cell>
        <Cell center thin>
          <label htmlFor={`Status_${id}`}>
            {status !== 0 && <Wege src={tick} />}
            <InputCheckbox
              type="checkbox"
              name="status"
              id={`Status_${id}`}
              onChange={e => this.handleInputChange(e)}
              onBlur={e => this.handleBlur(e)}
              checked={status !== 0}
            />
          </label>
        </Cell>
        <Cell center thin>
          <AddNew onClick={() => this.toggleConfirm()}>
            <Obraz src={minus} alt="Usuń danie" />
          </AddNew>
        </Cell>
        <Confirm
          show={showConfirm}
          text="czy na pewno chcesz usunąć danie?"
          toggle={() => this.toggleConfirm()}
          usun={() => this.usunDanie(id)}
        />
      </>
    );
  }
}

DaniaAdmin.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  nazwa: PropTypes.string.isRequired,
  kategoriaId: PropTypes.number.isRequired,
  rodzaj: PropTypes.string.isRequired,
  nazwaKategorii: PropTypes.string.isRequired,
  cena: PropTypes.number.isRequired,
  wege: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  kodDania: PropTypes.string.isRequired,
  zapiszDanieService: PropTypes.func.isRequired,
  usunDanieService: PropTypes.func.isRequired,
  kategorieDan: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rodzaj: PropTypes.string,
    }),
  ),
};
DaniaAdmin.defaultProps = {
  kategorieDan: [],
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      zapiszDanieService: zapiszDanie,
      usunDanieService: usunDanie,
    },
    dispatch,
  );
const mapStateToProps = state => {
  return {
    kategorieDan: state.daniaReducer.kategorieDan,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DaniaAdmin);
