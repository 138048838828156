export const FETCH_DANIA_KUCHNIA_PENDING = 'FETCH_DANIA_KUCHNIA_PENDING';
export const FETCH_DANIA_KUCHNIA_SUCCESS = 'FETCH_DANIA_KUCHNIA_SUCCESS';
export const FETCH_DANIA_KUCHNIA_ERROR = 'FETCH_DANIA_KUCHNIA_ERROR';

export const POBIERZ_ZAMOWIENIA_KUCHNIA_SUCCESS = 'POBIERZ_ZAMOWIENIA_KUCHNIA_SUCCESS';

export const POBIERZ_ZAMOWIENIA_LOKAL_KUCHNIA_SUCCESS = 'POBIERZ_ZAMOWIENIA_LOKAL_KUCHNIA_SUCCESS';
export const POBIERZ_ZAMOWIENIA_GOTOWE_LOKAL_KUCHNIA_SUCCESS =
  'POBIERZ_ZAMOWIENIA_GOTOWE_LOKAL_KUCHNIA_SUCCESS';

export function fetchDaniaKuchniaPending() {
  return {
    type: FETCH_DANIA_KUCHNIA_PENDING,
  };
}

export function fetchDaniaKuchniaSuccess(data) {
  return {
    type: FETCH_DANIA_KUCHNIA_SUCCESS,
    dania: data.dania,
    menu: data.menu,
  };
}

export function fetchDaniaKuchniaError(error) {
  return {
    type: FETCH_DANIA_KUCHNIA_ERROR,
    error,
  };
}

export function pobierzZamowieniaKuchniaSuccess(data) {
  return {
    type: POBIERZ_ZAMOWIENIA_KUCHNIA_SUCCESS,
    zamowienia: data.zamowienia,
    aktualizacja: data.aktualizacja,
    podsumowanieDania: data.podsumowanieDania,
  };
}
