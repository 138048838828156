export const theme = {
  black: '#0b0b0b',
  active: '#6d5cce',
  disabled: '#707070',
  valid: '#80CF26',
  green: '#23BF39',
  fSize: {
    h1: '4rem',
    h2: '2.4rem',
    h3: '2rem',
  },

  size: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1366px',
    laptopL: '1440px',
    desktop: '2560px',
  },
};
