import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import pl from 'date-fns/locale/pl';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ustawDateMenuAction } from 'services/admin/filterServices';
import { getDniMenu } from 'services/admin/menuServices';

registerLocale('pl', pl);
setDefaultLocale('pl');

class Kalendarz extends React.Component {
  _isMounted = false;

  state = {
    dniMenu: [],
  };

  componentDidMount() {
    this._isMounted = true;
    getDniMenu('').then(data => {
      if (this._isMounted) {
        this.setState({
          dniMenu: data.data,
        });
      }
    }, this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = date => {
    const { ustawDate } = this.props;
    getDniMenu(`${date.getFullYear()}-${date.getMonth() + 1}`).then(data => {
      this.setState({
        dniMenu: data.data,
      });
    }, this);
    ustawDate(date);
  };

  handleMonthChange = date => {
    getDniMenu(`${date.getFullYear()}-${date.getMonth() + 1}`).then(data => {
      this.setState({
        dniMenu: data.data,
      });
    }, this);
  };

  render() {
    const { dataMenu } = this.props;
    const { dniMenu } = this.state;

    return (
      <DatePicker
        dateFormat="dd.mm.yyyy"
        locale="pl"
        selected={dataMenu}
        onChange={this.handleChange}
        onMonthChange={this.handleMonthChange}
        highlightDates={dniMenu.map(data => {
          return new Date(data);
        })}
        inline
      />
    );
  }
}

Kalendarz.propTypes = {
  dataMenu: PropTypes.instanceOf(Date),
  ustawDate: PropTypes.func,
};
Kalendarz.defaultProps = {
  dataMenu: new Date(),
  ustawDate: null,
};

const mapStateToProps = state => {
  return {
    // error: state.zamowieniaReducer.error,
    dataMenu: state.filterReducer.dataMenu,
    // pendingPobierz: state.zamowieniaReducer.pendingWyslij,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ustawDate: ustawDateMenuAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Kalendarz);
